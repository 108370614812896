import React from "react";

const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    switch (day % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};


const UseYearMonthDateShort = (passedDate) => {
    const date = new Date(passedDate);  // Create a JavaScript Date object


    console.log(date.getUTCDate());

    const day = String(date.getUTCDate()).padStart(1, '0');  // Extract the day in UTC time and pad with zero if needed
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');  // Extract the month in UTC time, pad with zero if needed. getUTCMonth() returns month index starting from 0.
    const year = date.getUTCFullYear(); // Extract the year


    console.log(date)
    console.log(day);
    console.log(month);
    console.log(year);

    return `${year}-${month}-${day}`;
}


export default UseYearMonthDateShort;