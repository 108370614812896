import { createSlice } from "@reduxjs/toolkit";
import { Children } from "react";
import { clearDeviceLogs } from "../Api Methods/Api";

const filterCatagoeries = ["key", "tag", 'uuid'];
const InputsPins = [21, 20, 16, 12, 4, 3, 2, 1];
const OutPutPins = [5, 6, 13, 19];

const initialState = {

    // Stats   ------------
    isLoadingStats: false,
    deviceStats: {},

    // Search ------------
    filterCatagoery: "key",
    SearchData: [],
    filteredData: [],

    // SingleDevice ------
    singleDeviceHeaderData: {},
    singleDevicePins: [],
    singleDeviceID: null,
    singleDeviceUUID: null,
    singleDeviceCompanyName: null,
    singleDeviceEvents: null,

    DeviceUsers: {},
    DeviceUUID: null,


    isSocketConnected: false,

    DeviceDetails: null,
    DeviceUUID: null,
    DeviceDetailsExtras: null,

    CamectIntegationType: null, 
}


const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {

        updateSocketConnectionStatus: (state, action) => {
            console.log(action.payload);
            state.isSocketConnected = action.payload;
        },
        // Stats   --------------------------------
        updateStats: (state, action) => {
            console.log("updatingStatsReducer")
            state.deviceStats = action.payload.data
        },
        loadingStats: (state, action) => {
            // console.log("loadingStatsReducer")

            if (action.payload === true) {
                state.isLoadingStats = true;
            } else {
                state.isLoadingStats = false;
            }
        },
        // Search --------------------------------
        setfilterCategory: (state, action) => {
            console.log("Setting Filter Catagoery")
            state.filterCatagoery = filterCatagoeries[action.payload]
        },
        setSearchData: (state, action) => {
            console.log("Stored fetched search data")
            state.SearchData = action.payload.data;
        },
        filterData: (state, action) => {
            const fc = state.filterCatagoery;
            state.filteredData = state.SearchData.filter((item) => item[fc].includes(action.payload));

        },
        setDeviceUsers: (state, action) => {
            const DeviceUsers = action.payload.data;

            DeviceUsers.map((user) => {
                if (user.invitedBy == "") {
                    user.invitedBy = "Added device using UIPIOID";
                }
            })
            // console.log(DeviceUsers);
            const filteredData = DeviceUsers.map(item => {
                // Create a copy of the object without the 'email' property
                const { portalAccess, ...rest } = item;
                return rest; // Return the new object without 'email'
            });
            // console.log(filteredData);   



            const filteredData2 = filteredData.map(item => {
                const DeviceID = state.singleDeviceID;
                const uuid = state.singleDeviceUUID;
                return { ...item, DeviceID, uuid };
            });


            state.DeviceUsers = filteredData2;

        },
        // Single Device --------------------------------
        updatingSingleDeviceData: (state, action) => {

            console.log(action.payload);

            const { id, name, mac, key, ip, host, uuid, online, created, lastLoggedIn, status, tag, networkType, firmware, type, numInput, numOutput, latestFirmware, camectIntegrationType, deviceName } = action.payload;
            state.singleDeviceID = id;
            state.singleDeviceUUID = uuid;
            state.singleDeviceCompanyName = name;


            state.singleDeviceHeaderData = { id, name, mac, key, ip, host, uuid, online, created, lastLoggedIn, status, tag, networkType, firmware, type, numInput, numOutput, latestFirmware, camectIntegrationType, deviceName };
        },
        setSingleDeviceEvents: (state, action) => {
            console.log(action.payload.data);
            const Events = action.payload.data;
            const desiredMonth = 10;

            // Geting all values for specified month
            const filteredDates = Events.filter(event => {
                const date = new Date(event.eventDate);
                return date.getUTCMonth() + 1 === desiredMonth; // Months are zero-based, so add 1
            })

            const getDaySuffix = (day) => {
                if (day >= 11 && day <= 13) {
                    return 'th';
                }
                switch (day % 10) {
                    case 1:
                        return 'st';
                    case 2:
                        return 'nd';
                    case 3:
                        return 'rd';
                    default:
                        return 'th';
                }
            };

            const useDateFormat = (passedDate) => {
                const date = new Date(passedDate);  // Create a javascript data object
                const day = date.getUTCDate();  //extracts the day in UTC time, returns a int
                const month = date.toLocaleString('en-US', { month: 'long' });  // toLocaleString formats the month component within the date object to human readable format. en-US is used to set it to US English, the second argument, is set to long as we want the full month. returns a string.
                const year = date.getUTCFullYear(); //returns a 4 digit int reprsenting the year
                const suffix = getDaySuffix(day);
                return `${day}${suffix} ${month} ${year}`;
            }





            console.log(filteredDates);

            //Formating the eventDate field
            const EventsForMonth = filteredDates.map((event) => {
                const { eventDate, ...rest } = event;
                const formattedDate = useDateFormat(eventDate)
                return {
                    eventDate: formattedDate,
                    ...rest
                }
            })

            console.log(EventsForMonth);

            // Creating a array that that hold all the days in the month that had events and the number of the events
            const resultArray = EventsForMonth.reduce((accumulator, currentValue) => {
                const existingDateObject = accumulator.find(
                    (item) => item.date === currentValue.eventDate
                );

                if (existingDateObject) {
                    existingDateObject.events += 1;
                } else {
                    accumulator.push({
                        date: currentValue.eventDate,
                        events: 1,
                    });
                }

                return accumulator;
            }, []);

            console.log(resultArray);

            const newArray = resultArray.map((item) => ({
                ...item,
                events: item.events > 30 ? 30 : item.events,
            }));

            console.log(newArray);
            state.singleDeviceEvents = newArray;

        },
        setDeviceUUID: (state, action) => {
            state.DeviceUUID = action.payload;
        },
        setDeviceDetails: (state, action) => {
            console.log(action.payload);
            const [DeviceDetailsParsed, id, DeviceExtraDetailsParsed] = action.payload;

            state.DeviceDetails = DeviceDetailsParsed;
            state.DeviceID = id;
            state.DeviceDetailsExtras = DeviceExtraDetailsParsed;

        },

        setCamectIntegationType: (state, action) => {
        state.CamectIntegationType = action.payload;

        },
    }
})

export const serviceActions = serviceSlice.actions;

export default serviceSlice.reducer;


