import React, { useEffect, useState } from 'react'

import ComponentCard from "../../Cards/ComponentCard/ComponentCard";

import PinInputList from "../PinInput/InputPinV2/InputList/PinInputList";
import PinOutputList from "../PinOut/OutputV2/OutputList/PinOutputList";
import classes from "./PinsView.module.css";
import EditViewIcon from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/EditView.svg";
import { SingleDeviceDataLoader } from '../../../Site/Device/SiteView';
import { getSingleDeviceData } from '../../../../Api Methods/Api';
import { useDispatch, useSelector } from 'react-redux';
import { serviceActions } from '../../../../store/service-Slice';


const PinsView = ({ singleDevicePins, sokectPinStates }) => {
  
    const token = sessionStorage.getItem('token');
    const client = sessionStorage.getItem('client');
    const DeviceUUID = sessionStorage.getItem('DeviceUUID');
    const dispatch = useDispatch();

    const apiData = {
        token,
        client
    }

    const [DeviceData, setDeviceData] = useState(singleDevicePins)

    //if DeviceChanged Socket has been triggered, update DeviceDate to reflect new device details.
    useEffect(() => {
        setDeviceData(singleDevicePins)
    }, [singleDevicePins])

    let isSocketConnected = useSelector(state => state.service.isSocketConnected);

    //------------------------------- responsible for updating the SiteView Page data every 1 mintue ------------------------------------------------------

    useEffect(() => {
        if (!isSocketConnected) {
            const HandleDeviceData = async () => {
                const data = await getSingleDeviceData(apiData, DeviceUUID, dispatch);
                const siteData = HandleResponseData(data);
                // const deviceId = siteData[1].id;
                // dispatch(serviceActions.setDeviceDetails(siteData));
                // dispatch(serviceActions.setDeviceID(deviceId));
                setDeviceData(siteData[0])
            }
            // Set up a timer to fetch data every 1 minute
            const timer1 = setInterval(() => {

                HandleDeviceData();
            }, 3000);

            return () => clearInterval(timer1);
        }
    }, [isSocketConnected]);

    //---------------------------------------------------------------------------------------------------------------------

    let { OutputPins, InputPins, singleDeviceSettingData } = DeviceData;

    const online = singleDeviceSettingData.online;
    const numOfInputs = singleDeviceSettingData.numInput;


    console.log(numOfInputs);

    console.log(OutputPins);
    console.log(InputPins);
    console.log(sokectPinStates);


    // Property to overwrite and its new value  
    const propertyToOverwrite = 'pinState';
    const newValue = 40;

    // let updatedOutputs;

    if (isSocketConnected && sokectPinStates !== undefined) {
        OutputPins = OutputPins.map(output => {
            console.log(output);
            const pinState = sokectPinStates[0].find(Outputpin => Outputpin.pin === output.Pin);
            console.log(pinState);
            if (pinState) {
                return { ...output, pinState: pinState.pinState };
            } else {
                return output; // No matching pin state found, return the original output object
            }
        });


        InputPins = InputPins.map(output => {
            console.log(output);
            const pinState = sokectPinStates[1].find(Outputpin => Outputpin.pin === output.Pin);
            console.log(pinState);
            if (pinState) {
                return { ...output, pinState: pinState.pinState };
            } else {
                return output; // No matching pin state found, return the original output object
            }
        });
    } else { }

    // console.log(updatedOutputs);

    return (
        <div className={classes["PinView-container"]}>

            {/* if the device is offline then render the overlay */}
            {online === "NO" && (
                <div className={classes["overlay"]}>
                    <div className={classes["text-container1"]}>
                        <div style={{ display: "flex" }}>
                            <h2 style={{ fontWeight: "400" }}>Appview:</h2>
                            <h2 style={{ color: "red", marginLeft: "10px", fontWeight: "400" }}>Unavailable</h2>
                        </div>
                        <div style={{ display: "flex", fontWeight: "400" }}>
                            <h2 style={{ fontWeight: "400" }}>Device:</h2>
                            <h2 style={{ color: "red", marginLeft: "10px", fontWeight: "400" }}>OFFLINE</h2>
                        </div>
                    </div>
                </div>
            )}
            <PinOutputList OutputPins={OutputPins} polarity={singleDeviceSettingData.polarity} SmartLocks={singleDevicePins.singleDeviceSettingData.iglooLocks}/>


            {numOfInputs === 0 ? "" : <PinInputList InputPins={InputPins} polarity={singleDeviceSettingData.polarity} />}

    
        </div>
    )
}

export default PinsView

const HandleResponseData = (result) => {
    const OutPutPins = [5, 6, 13, 19];
    
    const {
        id,
        name,
        mac,
        key,
        ip,
        host,
        uuid,
        online,
        created,
        lastLoggedIn,
        status,
        tag,
        networkType,
        deviceDetails,
        firmware,
        type,
        numInput,
        numOutput,
        subGroup,
        deviceExtraDetails,
        latestFirmware,
        deviceName,
        iglooLocks } = result.data[0];

    // Passing Single Device Details to redux store to make these details availabe to other components. e.g. header
    const DeviceDetailsParsed = JSON.parse(deviceDetails);

    // const dispatch = useDispatch();

    const DeviceExtraDetailsParsed = JSON.parse(deviceExtraDetails);

    // dispatch(serviceActions.setDeviceDetails([DeviceDetailsParsed, id, DeviceExtraDetailsParsed]));

    const allPinsNotificationActive = DeviceDetailsParsed.allPinsNotificationActive;
    const allPinsNotificationSound = DeviceDetailsParsed.allPinsNotificationSound;
    const camectIntegrationType = DeviceDetailsParsed.camectIntegrationType;
    const singleDeviceName = DeviceDetailsParsed.name;
    const offlineNotificationActive = DeviceDetailsParsed.offlineNotificationActive;
    const offlineNotificationSound = DeviceDetailsParsed.offlineNotificationSound;
    const onlineNotificationActive = DeviceDetailsParsed.onlineNotificationActive;
    const onlineNotificationSound = DeviceDetailsParsed.onlineNotificationSound;
    const polarity = DeviceDetailsParsed.polarity;
    const polarityReason = DeviceDetailsParsed.polarityReason;

    const singleDeviceSettingData = { online, numOutput, numInput, allPinsNotificationActive, allPinsNotificationSound, camectIntegrationType, singleDeviceName, offlineNotificationActive, onlineNotificationActive, offlineNotificationSound, onlineNotificationSound, polarity, polarityReason, iglooLocks }

    // Parsing the Pins out into OutPut Pins / Input Pins - also added the pin number as a property to the OutPut Object.
    const DeviceOutputPins = OutPutPins.map((output) => { const value = DeviceDetailsParsed[output]; return { ...value, Pin: output }; }).filter((obj) => obj !== undefined);
    let InputPins;
    console.log(numInput);
    if (numInput != 0) {
        console.log("here");
        let DeviceInputPins;
        if (numInput == 4) {
            const InputsPins = [21, 20, 16, 12];
            DeviceInputPins = InputsPins.map((input) => { const value = DeviceDetailsParsed[input]; return { ...value, Pin: input }; }).filter((obj) => obj !== undefined);
        } else if (numInput == 8) {
            const InputsPins = [21, 20, 16, 12, 4, 3, 2, 1];
            DeviceInputPins = InputsPins.map((input) => { const value = DeviceDetailsParsed[input]; return { ...value, Pin: input }; }).filter((obj) => obj !== undefined);
        }

        //Input Device Icons
        let InputDeviceIcons;
        if (numInput == 4) {
            const InputsPins = [21, 20, 16, 12];
            //Input Device Icons
            InputDeviceIcons = InputsPins.map((input) => JSON.parse(deviceExtraDetails)[input]).filter((value) => value !== undefined);
        } else if (numInput == 8) {
            const InputsPins = [21, 20, 16, 12, 4, 3, 2, 1];
            InputDeviceIcons = InputsPins.map((input) => JSON.parse(deviceExtraDetails)[input]).filter((value) => value !== undefined);
        }
        const arrayOfInputDeviceIcons = Object.values(InputDeviceIcons);
        const arrayOfInputDeviceIconsValues = arrayOfInputDeviceIcons.map(obj => obj.icon);

        // debugger;
        InputPins = DeviceInputPins.map((item, index) => {
            let icon = arrayOfInputDeviceIconsValues[index];
            if (icon.includes('.png')) {
                // //slice off .png 
                icon = icon.slice(0, -4) /*+ '.svg';*/
            } else {
                icon = icon /*+ '.svg';*/
            }

            return { ...item, icon };
        });

    }
    //Output Device Icons
    const OutputDeviceIcons = OutPutPins.map((output) => JSON.parse(deviceExtraDetails)[output]).filter((value) => value !== undefined);
    const arrayOfOutputDeviceIcons = Object.values(OutputDeviceIcons);
    const arrayOfOutputDeviceIconsValues = arrayOfOutputDeviceIcons.map(obj => obj.icon);

    const OutputPins = DeviceOutputPins.map((item, index) => {
        let icon = arrayOfOutputDeviceIconsValues[index];
        let DeviceUUID = uuid;
        let DeviceType = type;
        if (icon.includes('.png')) {
            icon = icon.slice(0, -4)
        } else {
            icon = icon
        }
        return { ...item, icon, DeviceUUID, DeviceType };
    });


    console.log(iglooLocks);

    const ResponseData = [
        { OutputPins, InputPins, singleDeviceSettingData },
        { id, name, mac, key, ip, host, uuid, online, created, lastLoggedIn, status, tag, networkType, firmware, type, subGroup, numInput, numOutput, latestFirmware, deviceName }
    ]
    return ResponseData;
}