import { redirect } from "react-router-dom";

export function loader() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('client');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('userID',);
    sessionStorage.removeItem('companyName');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('DeviceID');
    sessionStorage.removeItem('DeviceUUID');
    sessionStorage.removeItem('ClickedUserFullName');
    sessionStorage.removeItem('FirstName');
    sessionStorage.removeItem('DeviceName');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('ClickedUserID');
    sessionStorage.removeItem('expiration');
    sessionStorage.removeItem('expiration');
    sessionStorage.removeItem('SavedSearchFilters');
    sessionStorage.removeItem('SavedSearchQuery');
    sessionStorage.removeItem("getAllDevices");
    sessionStorage.removeItem("SavedSearchSortOrder");
    sessionStorage.removeItem("groups");
    sessionStorage.removeItem("IsGetAllDevicesPressed");
    
    return redirect('/');
}