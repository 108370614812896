import React, { Component, useContext, useState } from "react";
import classes from './ControlsBarV2.module.css'
import ComponentCard from "../../Cards/ComponentCard/ComponentCard";
import userIcon from '../../../../assets/portalAssets/usericonW.png';
import resfreshIconw from '../../../../assets/portalAssets/refreshIconW.png';
import resfreshIconb from '../../../../assets/portalAssets/refreshIconB.png';

import UsersIcon from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Control Buttons Icons/__User.svg"
import LogsIcon from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Control Buttons Icons/__Logs.svg"
import AutomationsIcon from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Control Buttons Icons/__Automations.svg"
import EventsIcon from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Control Buttons Icons/__Events.svg"
import GroupsIcon from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Control Buttons Icons/__Groups.svg"
import RefreshTableIcon from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Control Buttons Icons/__Refresh.svg"


import VirtualSources from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Virtual Sources.svg"
import SmartLock from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Locked.svg"


import ControlsBarButton from "../ControlsBarButton/ControlsBarButton";
import ControlsBarRefreshButton from "../ControlsBarButton/ControlsBarRefreshButton";

const ContorlsBarV2 = (props) => {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {

        setIsHovered(true);
        console.log(isHovered);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const dummyButtons = [
        {
            title: "Events",
            path: LogsIcon
        },
        {
            title: "Users",
            path: UsersIcon,
        },
        // {
        //     title: "Automations",
        //     path: AutomationsIcon

        // },
        {
            title: "Sources",
            path: VirtualSources

        },
        // {
        //     title: "Groups",
        //     path: GroupsIcon
        // },
    ]

    const table = null;

    const [activeButton, setActiveButton] = useState("Events")
    console.log(props.activeTable);
    console.log(activeButton);
    return (
        <div className={classes["ControlButtons-container"]}>
            <ul className={classes["tableButtons-list"]}>
                {dummyButtons.map((button, index) => (
                    <li key={index}>
                        <ControlsBarButton title={button.title} imagePath={button.path} onClick={props.tableNameHandler} activeButton={activeButton} setActiveButton={setActiveButton}/>
                    </li>
                ))}
                {/* <ControlsBarRefreshButton imagePath={RefreshTableIcon} handleMouseLeave={handleMouseLeave} handleMouseEnter={handleMouseEnter} HandleRefreshTable={props.HandleRefreshTable} /> */}

            </ul>
        </div>

    );
}

export default ContorlsBarV2;