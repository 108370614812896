import React, { useEffect, useState } from 'react'

import classes from "./AutomationsGrid.module.css";
import Card from './Card';
import LockContentLayout from '../UI/Cards/Automations/VirutalSources/SmartLocks/LockContentLayout';
import CreateAutomation from '../UI/Cards/Automations/EditAutomations/CreateAutomation';
import CreateVirtualSource from '../UI/Cards/Automations/VirutalSources/CreateVirtualSource';
import CametContentLayout from '../UI/Cards/Automations/VirutalSources/Camect/CametContentLayout';
import SigFoxContentLayout from '../UI/Cards/Automations/VirutalSources/Sigfox/SigFoxContentLayout';



const VirtualSourcesGrid = ({ Camects, OutPutsPinNames, InputsPinNames, iglooLocks, setRefreshVirtualSources, HandleSelectCamectModalForm, setShowVirtualSource, firmware, refreshCamects, sigfoxSensors, camectIntegrationType }) => {
    
    let camectLength = 0;
    let iglooLocksLength = 0;
    let sigfoxSensorsLength = 0;

    const Firmware = firmware.split('.')
    const firmwareMajor = Firmware[0]
    const firmwareMinor = Firmware[1]
    const numInput = InputsPinNames.length

    if (Camects.camect != null) {
        camectLength = Camects.camect.length
    }

    if (iglooLocks !== null) {
        iglooLocksLength = iglooLocks.length
    }

    if (sigfoxSensors !== null) {
        sigfoxSensorsLength = sigfoxSensors.length
    }

    const NumberOfItemsInVirutalSourcesArray = camectLength + iglooLocksLength + sigfoxSensorsLength;
    const gridStyleConditional = NumberOfItemsInVirutalSourcesArray < 4 && "flex"

    return (
        // <ul className={classes["grid-ul"]} style={{ display: gridStyleConditional && "flex", gap: gridStyleConditional && "30px" }}>
        <ul className={classes["grid-ul"]} >
            {iglooLocks && iglooLocks.map((lock, index) => {
                const outputPinName = OutPutsPinNames.filter(Pin => Pin.Pin == lock.outputPin);

                return (
                    <Card key={index} className={classes.card} index={index} color={null}>
                        <LockContentLayout outputPinName={outputPinName} iglooLocks={lock} setRefreshVirtualSources={setRefreshVirtualSources} />
                    </Card>
                )
            })}

            {Camects.camects && Camects.camects.map((camect, index) => {

                // filter out Pulse Outputs
                const Outputs = OutPutsPinNames.filter((OutPut, index) => OutPut.type == 'LATCH' || OutPut.type == 'VIRTUAL_LATCH');

                // Filter out output pins with assigned smartlocks
                const LatchOutputs = Outputs.filter(output => {
                    return iglooLocks.every(lock => !lock || lock.outputPin != output.Pin);
                });

                

                console.log(firmwareMajor);
                console.log(firmwareMinor);

                let allPins;

                if (firmwareMajor == '1' && firmwareMinor >= '9' || firmwareMajor > 1) {
                    // adding outputs and inputs into one array
                    allPins = [...LatchOutputs, ...InputsPinNames];
                } else {
                    allPins = LatchOutputs;
                }

                const relationshipKeys = Object.keys(Camects.relationships);
                console.log(relationshipKeys);
                let LinkedPins = [];

                relationshipKeys.forEach((key) => {
                    if (Camects.relationships[key].startsWith(camect.id)) {
                        console.log(Camects.relationships[key]);
                        const outputMatch = allPins.filter(output => output.Pin == key);
                        // Assuming rest is already defined as an array and camect is an object with an id property

                        LinkedPins = [...LinkedPins, ...outputMatch];
                    }
                });


                // Filter out Linked pins
                const availablePins = allPins.filter(Pin => {
                    console.log(camectIntegrationType)
                    if (camectIntegrationType != 2){
                        return LinkedPins.every(LinkedPin => LinkedPin != Pin);
                    } else {
                        //Dont remove already linked pins, as you need to be able to deselct cams
                        return LinkedPins
                    }
                    
                });

                return (
                    <Card key={index} className={classes.card} index={index} color={null}>
                        <CametContentLayout camect={camect} LinkedPins={LinkedPins} setRefreshVirtualSources={setRefreshVirtualSources} HandleSelectCamectModalForm={HandleSelectCamectModalForm} Outputs={availablePins} refreshCamects={refreshCamects} />
                    </Card>
                )
            })}

            {sigfoxSensors && sigfoxSensors.map((sensor, index) => {
                console.log(parseInt(sensor.virtualInput));
                console.log(InputsPinNames);
                const pinName = InputsPinNames.find(Pin => Pin.Pin == parseInt(sensor.virtualInput))
                console.log(pinName);
                return (
                    <Card key={index} className={classes.card} index={index} color={null}>
                        <SigFoxContentLayout sensor={sensor} pinName={pinName} setRefreshVirtualSources={setRefreshVirtualSources} />
                    </Card>
                )
            })}

            <Card >
                <CreateVirtualSource HandleSelectCamectModalForm={HandleSelectCamectModalForm} numInput={numInput} firmwareMajor={firmwareMajor} firmwareMinor={firmwareMinor} />
            </Card>
        </ul>
    )
}

export default VirtualSourcesGrid