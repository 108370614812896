import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import Card from "../../components/UI/Cards/Card";
import classes from './SearchView.module.css'
import SearchFilter from "../../components/SearchBar/SearchFilter";
import StatsList from "../../components/UI/Stats/StatsList";


import UsersTableLayout from "../../components/Table/UsersTableLayout"

import { ApiEndpoints } from "../../store/type/API-Endpoints";
import SearchFilter2 from "./SearchFilter2";
import { defer, useLoaderData } from "react-router-dom";
import SearchBar from "./Search/SearchBar";
import ComponentCard from "../../components/UI/Cards/ComponentCard/ComponentCard";
import { getDevices, getPagedDevices, getStats, PagedDevices, PowerSearchAPI } from "../../Api Methods/Api";
import Button1 from "../../components/UI/Button/Button1";
import SocketManager from "../../Socket/useSocketManager";
import HandleResponseData from "../../hooks/HandleResponseData";


const SearchView = (props) => {

    // ----------------------------loader data---------------------------------
    // const { Sites } = useLoaderData();
    // useEffect(() => {
    //     if (Sites.isError) {
    //         return Sites.message;
    //     }
    // }, [Sites])

    // ---------------------------------------------------------------------------

    const [pagedDevices, setPagedDevices] = useState([])

    // function extractDevices(arrayOfObjects) {
    //     const allDevices = [];
    //     // Iterate over each object in the array
    //     arrayOfObjects.forEach(obj => {
    //         // Check if the object has a 'devices' property and it's an array
    //         if (obj.devices && Array.isArray(obj.devices)) {
    //             // Concatenate the devices array of the current object to the 'allDevices' array
    //             allDevices.push(...obj.devices);
    //         }
    //     });
    //     return allDevices;
    // }


    // useEffect(() => {
    //     const HandlePagedDevices = async () => {
    //         function extractDevices(arrayOfObjects) {
    //             const allDevices = [];
    //             // Iterate over each object in the array
    //             arrayOfObjects.forEach(obj => {
    //                 // Check if the object has a 'devices' property and it's an array
    //                 if (obj.devices && Array.isArray(obj.devices)) {
    //                     // Concatenate the devices array of the current object to the 'allDevices' array
    //                     allDevices.push(...obj.devices);
    //                 }

    //                 setPagedDevices(pagedDevices)
    //             });
    //             return allDevices;
    //         }
    //         HandlePagedDevices();
    //     }



    // }, [Sites])




    console.count("HomeSearch RUNNING")
    const client = useSelector(state => state.auth.client);
    const companyName = useSelector(state => state.auth.companyName);
    const token = useSelector(state => state.auth.token);

    const clientMemoized = useMemo(() => client, [client])
    const companyNameMemoized = useMemo(() => companyName, [companyName])
    const tokenMemoized = useMemo(() => token, [token])

    const [searchQuery, setSearchQuery] = useState("");
    const [searchedDevices, setSearchedDevices] = useState();
    const [searchedUsers, setSearchedUsers] = useState([]);

    const [isloading, setIsLoading] = useState(false)


    const ApiData = {
        token,
        client
    }

    useEffect(() => {
        const HandlePowerSearch = async (ApiData, searchQuery) => {
            setIsLoading(true);
            const responseData = await PowerSearchAPI(ApiData, searchQuery);

            setSearchedDevices(responseData[0].devices)
            setSearchedUsers(responseData[0].users)

            if (responseData != null) {
                setIsLoading(false);
            }
        }

        const delayDebounceFn = setTimeout(() => {
            //   Perform fetch call with the input value
            if (searchQuery === "") {
                setSearchedDevices([]);
            } else if (searchQuery !== "") {
                HandlePowerSearch(ApiData, searchQuery)
            }
        }, 250);


        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery]);


    const userID = useSelector(state => state.auth.userID);

    return (
        <Card className={classes['Dashboard-container']}>
            <StatsList client={clientMemoized} companyName={companyNameMemoized} token={tokenMemoized} />
            {/* <SearchFilter client={clientMemoized} companyName={companyNameMemoized} token={tokenMemoized} showCatagories={true} /> */}
            {/* <SearchFilter2 client={clientMemoized} companyName={companyNameMemoized} token={tokenMemoized} /> */}
            {/* {"User ID: "  + userID} */}


            <SearchBar
                text="text"
                placeholder={"Power Search..."}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            {isloading && searchQuery !== "" && "Loading..."}
            {searchedDevices && searchedDevices.length !== 0 && searchQuery !== "" && <UsersTableLayout devices={searchedDevices} filtering={searchQuery} apiData={ApiData} />}
            {/* {pagedDevices && < UsersTableLayout devices={pagedDevices} filtering={searchQuery} apiData={ApiData} />} */}

            
        </Card>
    )
}

export default SearchView;


export async function loader() {
    const token = sessionStorage.getItem('token');
    const client = sessionStorage.getItem('client');
    const companyName = sessionStorage.getItem('companyName');

    const apiData = {
        token,
        client
    }

    const pageSize = 100;
    const pageNumber = 1;

    return defer({
        Stats: getStats(apiData, companyName),
        // Sites: getDevices(apiData, companyName),

        // Sites: getPagedDevices(apiData, pageSize, pageNumber)
    })
}
