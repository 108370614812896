import React from "react";

const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    switch (day % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};


const UseParseDateMonthYearTime = (passedDate) => {
    const date = new Date(passedDate);  // Create a JavaScript Date object
    const day = date.getUTCDate();  // Extract the day in UTC time
    const month = date.toLocaleString('en-US', { month: 'short' });  // Format the month to abbreviated format
    const year = date.getUTCFullYear(); // Extract the year
    const hours = String(date.getUTCHours()).padStart(2, '0'); // Extract hours and pad with zero if needed
    const minutes = String(date.getUTCMinutes()).padStart(2, '0'); // Extract minutes and pad with zero if needed
    const suffix = getDaySuffix(day);

    return `${day}${suffix} ${month}, ${year} ${hours}:${minutes}`;
}


export default UseParseDateMonthYearTime;