import React, { useEffect, useState } from 'react'
import Button1 from '../../../Button/Button1';
import Button2 from '../../../Button/Button2';
import ListOption from '../EditAutomations/ListOption';

import classes from "./SelectOutputs.module.css";

const SelectOutputs = ({ body, setBody, listItems, outputList, nextPage, inputTypeText, CreateRule }) => {

    //pass back selected outputs
    const [selectedOutputsInt, setSelectedOutputsInt] = useState([]);
    const [selectedOutputsString, setSelectedOutputsString] = useState([]);

    const HandeOnClick = () => {

        const newArray = selectedOutputsInt
        //add input to start of outputs
        newArray.unshift(body.input);

        //if user selects a output then the input will have been set to not null
        if (body.inputRule != 'null') {
            //convert input to string
            const inputAsString = body.input.toString();

            let Body = body;

            const selectedOutputsAsString = newArray.join(',');
            console.log(selectedOutputsAsString);

            Body = { ...Body, input: inputAsString, outputs: selectedOutputsAsString }

            CreateRule(Body);
        } else {
            setBody(prevBody => ({ ...prevBody, outputs: newArray }));
            nextPage(3);
        }

    }

    const HandleOnItemClicked = (item) => {
        const doesItemAlreadyExitInArray = selectedOutputsInt.find(Pin => Pin == item.Pin);

        if (doesItemAlreadyExitInArray) {
            const outputPin = outputList.find(Pin => Pin.Pin == doesItemAlreadyExitInArray);
            removeItem(outputPin)
        } else {
            const outputPin = outputList.find(Pin => Pin.pinName == item.pinName);
            addItem(outputPin)
        }
    }


    const addItem = (item) => {
        // Create a copy of the current array
        const newArray = [...selectedOutputsInt];
        // Push the new item to the copy
        newArray.push(item.Pin);

        // Set the state with the new array
        setSelectedOutputsInt(newArray);

        // Create a copy of the current array
        const newArray2 = [...selectedOutputsString];
        // Push the new item to the copy
        newArray2.push(item.pinName);

        // Set the state with the new array
        setSelectedOutputsString(newArray2);
    };

    const removeItem = (item) => {
        console.log(item);
        // Create a copy of the current array
        const newArray = [...selectedOutputsInt];

        const filterArray = newArray.filter(Pin => Pin !== item.Pin)

        // Set the state with the new array
        setSelectedOutputsInt(filterArray);


        // Create a copy of the current array
        const newArray2 = [...selectedOutputsString];

        const filterArray2 = newArray2.filter(pinName => pinName !== item.pinName)

        // Set the state with the new array
        setSelectedOutputsString(filterArray2);
    }

    //updates the list option text when the user clicks on an element
    useEffect(() => {
        HandleText()
    }, [selectedOutputsString])

    const HandleText = () => {

        const inputDetails = listItems.find(item => item.Pin == body.input);
        const InputTypeText = inputTypeText != null ? inputTypeText : inputDetails.pinStatusOn;
        const constantText = "When [ " + inputDetails.pinName + " ] is " + InputTypeText;

        //if the user has select an output - then show default input rule as it already been set in selectInput
        const InputRuleText = body.inputRule != 'null' ? "will match the state" : ""

        // this true conditon will only return true after the user clicks the first inputRule - re called be the useEffect above.
        const text = selectedOutputsString && selectedOutputsString.length > 0 ? constantText + " [" + selectedOutputsString + "] " + InputRuleText : constantText
        return text;
    }

    //filter out unwanted outputs
    const FilterArray = () => {
        return outputList.filter(item => item.Pin != body.input);
        // return listItems.filter(item => item.pinName !== body.input && item.type !== "PULSE");
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "15px" }}>


            <ListOption optionText={HandleText()} propsClassName={classes.infoBox} />

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "20px", width: "100%" }}>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "20px", width: "100%" }}>
                    <ul style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", gap: "10px" }}>
                        {FilterArray().map((item, index) => {
                            console.log(item);
                            return (
                                <Button2 key={index} onClick={() => HandleOnItemClicked(item)} style={{ backgroundColor: selectedOutputsInt.find(Pin => Pin == item.Pin) && "var(--Primary-Color)", color: selectedOutputsInt.find(Pin => Pin == item.Pin) && "white", border: selectedOutputsInt.find(Pin => Pin == item.Pin) && "2px solid lightblue", minWidth: "150px" }}>
                                    {item.pinName}
                                </Button2>
                            )
                        })}
                    </ul >
                    {body.inputRule != 'null' ?
                        <button className={classes.SaveButton} disabled={selectedOutputsInt.length == 0} onClick={HandeOnClick} >{"Save"}</button> :
                        <button className={classes.nextButton} disabled={selectedOutputsInt.length == 0} onClick={HandeOnClick} >{">"}</button>
                    }
                </div>
            </div>
        </div>
    )
}

export default SelectOutputs