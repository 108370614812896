import React, { Suspense, useCallback, useEffect, useState } from 'react'
import AutomationsGrid from '../../../../GridList/AutomationsGrid'
import CreateAutomation from '../EditAutomations/CreateAutomation';
import Card from "../../../../GridList/Card";
import CreateRule from '../CreateRule/CreateRule';
import ScheduledAction from '../EditAutomations/ScheduledAction';
import CreateSchedule from '../EditAutomations/CreateSchedule';
import { getCamectsAPI, getInputRules, getSchedules, getSingleDeviceData } from '../../../../../Api Methods/Api';
import { useSelector } from 'react-redux';
import { Await } from 'react-router-dom';
import Oval from 'react-loading-icons/dist/esm/components/oval';

import classes from "../Automations.module.css"
import VirtualSourcesGrid from '../../../../GridList/VirtualSourcesGrid';
import { useSocketHook } from '../../../../../Socket/useSocketHook';
import CamectModalForm from './Camect/CamectModalForm';
import Button1 from '../../../Button/Button1';
import { checkAuthLoader } from '../../../../../auth';
import SigfoxModalForm from './Sigfox/SigfoxModalForm';


const VirtualSources = ({ loadedSiteCamects, sigfoxSensors, iglooLocks, OutPutsPinNames, InputsPinNames, deviceData, setHasDeviceChanged, DeviceDetails }) => {
    const [refreshVirtualSources, setRefreshVirtualSources] = useState(false)

    // useEffect(() => {
    //     setHasDeviceChanged(true);
    // }, [refreshVirtualSources]);

    let token = useSelector(state => state.auth.token);
    let client = useSelector(state => state.auth.client);
    let DeviceUUID = useSelector(state => state.auth.DeviceUUID);
    let isSocketConnected = useSelector(state => state.service.isSocketConnected);
    let camectIntegrationType = DeviceDetails.camectIntegrationType;
    console.log(camectIntegrationType)

    const apiData = {
        token,
        client
    }

    const [IglooLocks, setIglooLocks] = useState(iglooLocks);

    const virtualInputsArray = [1, 2, 3, 4];
    const virtualInputs = InputsPinNames.filter((input, index) => input.pin == virtualInputsArray[index]);

    // // fetchAutomations is called when an automation is Created, Edited or Deleted.
    // const fetchAutomations = () => {
    //     const getIFTTTRules = async () => {
    //         // debugger;
    //         const responseData = await getSingleDeviceData(apiData, DeviceUUID);

    //         const siteData = HandleResponseData(responseData);
    //         console.log(siteData);
    //         const iglooLocks = siteData[0].singleDeviceSettingData.iglooLocks;
    //         console.log(iglooLocks);
    //         setIglooLocks(iglooLocks);
    //         setRefreshVirtualSources(false);
    //     }
    //     getIFTTTRules();
    // }

    // // Fetch data on mount
    // const [socketCamectRelationships, setSocketCamectRelationships] = useState()
    // console.log(loadedSiteCamects);
    // const CamectRelationShipss = (hasDeviceChanged) => {
    //     if (DeviceUUID === hasDeviceChanged.uuid) {
    //         console.log(hasDeviceChanged);
    //         setSocketCamectRelationships(hasDeviceChanged)
    //     }
    // }
    // const socketListeners = [{ socketlistener: "appCamectRelationshipss", callback: CamectRelationShipss }];

    // useSocketHook(isSocketConnected, socketListeners);

    const [showVirtualSource, setShowVirtualSource] = useState(null);
    const [camectDetails, setcamectDetails] = useState(null);
    const [camects, setCamects] = useState(loadedSiteCamects)


    const refreshCamects = async () => {
        console.log("here");
        const responseData = await getCamectsAPI(apiData, DeviceUUID);
        setCamects(responseData);
    }

    // useEffect(() => {
    //     // Set up a timer to fetch data every 1 minute
    //     const timer1 = setInterval(() => {

    //         // HandleDeviceUserList();
    //         refreshCamects();
    //     }, 30000);

    //     return () => clearInterval(timer1);
    // }, []);


    //handle which camect form to show 
    const HandleSelectCamectModalForm = (AutomationID, camectDetails) => {
        if (camectDetails != null) {
            setShowVirtualSource(AutomationID);
            setcamectDetails(camectDetails)
        } else {
            setShowVirtualSource(AutomationID);
        }
    }


    return (
        <div>

            <VirtualSourcesGrid Camects={camects} OutPutsPinNames={OutPutsPinNames} InputsPinNames={InputsPinNames} iglooLocks={IglooLocks} setRefreshVirtualSources={setRefreshVirtualSources} HandleSelectCamectModalForm={HandleSelectCamectModalForm} setShowVirtualSource={setShowVirtualSource} firmware={DeviceDetails.firmware} refreshCamects={refreshCamects} sigfoxSensors={sigfoxSensors} camectIntegrationType={DeviceDetails.camectIntegrationType} />

            {showVirtualSource == 0 && <CamectModalForm setShowVirtualSource={setShowVirtualSource} title={"Add"} subTitle={"What are the camect Details"} placeholders={{ placeholder1: "IP/Domain Name...", placeholder2: "Enter Password" }} refreshCamects={refreshCamects} />}
            {showVirtualSource == 3 && <CamectModalForm setShowVirtualSource={setShowVirtualSource} title={"Edit"} subTitle={"What are the camect Details"} placeholders={{ placeholder1: "IP/Domain Name...", placeholder2: "Enter Password" }} refreshCamects={refreshCamects} camect={camectDetails} />}

            {showVirtualSource == 1 && <SigfoxModalForm setShowVirtualSource={setShowVirtualSource} inputs={virtualInputs} virtualType={"SIGFOX RE:SURE"} />}
            {showVirtualSource == 4 && <SigfoxModalForm setShowVirtualSource={setShowVirtualSource} inputs={virtualInputs} virtualType={"IOTA"} />}
            
            {/* {showVirtualSource == 2 && <LockModalForm setShowVirtualSource={setShowVirtualSource}} */}

        </div>
    )
}

export default VirtualSources;
