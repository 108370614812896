import React, { useState, useRef, useEffect } from 'react';
import classes from './PowerSearch.module.css'

import SearchBar from './SearchBar';
import { useDispatch, useSelector } from 'react-redux';
import { PowerSearchAPI } from '../../Api Methods/Api';


const PowerSearch = ({ placeholder, searchQuery, categoryFilter, setSearchQuery, setIsLoading, setFilteredResults, handleFilterLogic, isGetAllDevicesPressed, setIsGetAllDevicesPressed, filteredResults, sortBy }) => {
    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);



    const ApiData = {
        token,
        client
    }

    const formRef = useRef();

    // create a ref to hold trigger time of search
    const powerSearchTimer = useRef();
    // Define a ref in the parent component
    const GetAllDevicesTimer = useRef();

    // Callback to set the ref in the parent component
    const setGetAllDevicesTimer = (ref) => {
        console.log(ref);
        GetAllDevicesTimer.current = ref;
    };


    useEffect(() => {
        const HandlePowerSearch = async (ApiData, query, powerSearchTimer) => {
            setIsLoading(true);
         
            powerSearchTimer.current = Date.now();

            // store seach trigger time
            const triggerTime = powerSearchTimer.current;

            const responseData = await PowerSearchAPI(ApiData, query, dispatch);
            const Sites = responseData[0].devices;

            // if trigger time is equal to current time.current
            if (powerSearchTimer.current == triggerTime) {
                console.log(Sites)
                console.log(categoryFilter)
                handleFilterLogic(Sites, searchQuery, categoryFilter, sortBy);
                if (responseData != null) {
                    setIsLoading(false);
                }
            }
        }

        const delayDebounceFn = setTimeout(() => {
            //   Perform fetch call with the input value
            if (searchQuery === "" && !isGetAllDevicesPressed) {
                setFilteredResults([]);
            } else if (searchQuery !== "") {
                setFilteredResults([]);
                setIsGetAllDevicesPressed(false);
                HandlePowerSearch(ApiData, searchQuery, powerSearchTimer)
            }
        }, 1700);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, categoryFilter, sortBy]);

    console.log(filteredResults.length);
    return (
        <form className={classes.form} ref={formRef}>
            <SearchBar
                text="text"
                placeholder={placeholder}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ width: "500px" }}
                onClear={() => setSearchQuery("")}
                
            />

        </form>
    );
};

export default PowerSearch;
