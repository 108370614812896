const UseParseTime = (passedTime) => {
    console.log(passedTime);
    const date = new Date(passedTime);  // Create a JavaScript Date object
    const hours = String(date.getUTCHours()).padStart(2, '0'); // Extract hours and pad with zero if needed
    const minutes = String(date.getUTCMinutes()).padStart(2, '0'); // Extract minutes and pad with zero if needed
        console.log(`${hours}:${minutes}`);
    return `${hours}:${minutes}`;
}


export default UseParseTime;