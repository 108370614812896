import React from 'react'
const InputsPins = [21, 20, 16, 12, 4, 3, 2, 1];
const OutPutPins = [5, 6, 13, 19];


const parsePinStates = (data) => {
    console.log("here");
    const { uuid, ...pinStates } = data;
    console.log(pinStates); // Output: { '1': 1, '21': 1 }


    // Find keys and their values that match
    const OutputpinStates = Object.entries(data)
        .filter(([key]) => OutPutPins.includes(Number(key)))
        .map(([key, value]) => ({ "pin": Number(key), "pinState": value }));
    console.log(OutputpinStates);

    // Find keys and their values that match
    const InputPinStates = Object.entries(data)
        .filter(([key]) => InputsPins.includes(Number(key)))
        .map(([key, value]) => ({ "pin": Number(key), "pinState": value }));
    console.log(InputPinStates);

    return [ OutputpinStates, InputPinStates];
}

export default parsePinStates