import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { defer } from 'react-router-dom';
import { getActivityLog } from '../../Api Methods/Api';
import SearchBar from '../../components/PowerSearch/SearchBar';
import ActivityTableLayout from '../../components/Table/ActivityTableLayout';
import SearchbarSmall from '../../components/UI/Inputs/SearchbarSmall';
import classes from "./PortalActivityPage.module.css"

const PortalActivityPage = () => {

  const client = useSelector(state => state.auth.client);
  const companyName = useSelector(state => state.auth.companyName);
  const token = useSelector(state => state.auth.token);

  const [filtering, setFiltering] = useState('');


  const apiData = {
    token,
    client
  }

  const HandleTableFiltering = (e) => {
    console.log("here");
    setFiltering(e.target.value)

    // PowerSearch(ApiData, e.target.value);
  }

  return (
    <div className={classes['activtyPage_container']}>
      <SearchBar
        text="text"
        placeholder={"Search Events..."}
        value={filtering}
        style={{ width: "500px" }}
        onChange={HandleTableFiltering}
      />
      <ActivityTableLayout filtering={filtering} HandleTableFiltering={HandleTableFiltering} apiData={apiData} companyName={companyName} />
    </div>
  )
}

export default PortalActivityPage

export async function loader() {
  const token = sessionStorage.getItem('token');
  const client = sessionStorage.getItem('client');
  const companyName = sessionStorage.getItem('companyName');

  const apiData = {
    token,
    client
  }

  return defer({
    portalEvents: getActivityLog(apiData, companyName)
  })
}
