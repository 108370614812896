import React, { useState } from 'react'

import classes from "./GroupCard.module.css";
import StatusIndicatorCard from '../../GroupCardExtraComponets/StatusIndicatorCard/StatusIndicatorCard'
import RoleIndicatorCard from '../../GroupCardExtraComponets/StatusIndicatorCard/RoleIndicatorCard'
import UserIcon from '../../GroupCardExtraComponets/UserIcon/UserIcon';
import ExtraUsersList from '../../GroupCardExtraComponets/ExtraUsersList';
import { Link } from "react-router-dom";


const GroupCard = (props) => {

    const { id, title, groupType, NumberOfUsers, GroupStatus, Roles, groupUserInitials } = props.cardData;

    console.log(props.groupCardData);

    const numberArray = Array.from({ length: NumberOfUsers || 4 }, (_, index) => index + 1);


    console.log(GroupStatus);

    return (
        <Link to={`${id}/sites`} className={classes.GroupCardContentLayout}>
            <div className={classes["top-wrapper"]}>
                <div className={classes["groupDetails-layout"]}>
                    <h3 className={classes.groupName}>{title || "placeholder"}</h3>
                    <h3>Group Type: {groupType || "placeHolder"}</h3>
                    <h3>{NumberOfUsers || "2"} Users</h3>
                </div>


                <StatusIndicatorCard status={GroupStatus} />
            </div>
            <div className={classes["middle-wrapper"]} >
                <ul style={{ display: "flex", gap: "5px" }} className={classes.ulist}>
                    {Array.isArray(Roles) &&
                        Roles.map((role, index) => {
                            return (
                                <li className={classes.list} key={index}>
                                    <RoleIndicatorCard role={Roles[index] || null} />
                                </li>
                            );
                        })}
                </ul>
            </div>

            <div className={classes["bottom-wrapper"]}>
                <div className={classes["bottomLeft-wrapper"]}>
                    <div className={classes["userIcons"]}>
                        <UserIcon className={classes.userIcon} userInitials={groupUserInitials[0] || "pH"} />
                        <UserIcon className={classes.userIcon} userInitials={groupUserInitials[1] || "pH"} />
                    </div>

                    {NumberOfUsers > 2 ? (
                        <div className={classes["ellipsis"]}>
                            ...
                        </div>
                    ) : null}


                    <div className={classes["userIcons"]}>
                        <ExtraUsersList array={numberArray} className={classes.userIcon} userInitials={""} />
                    </div>
                </div>

                <div className={classes.arrowButton}>
                    <div className={classes.arrowIcon} />
                </div>
            </div>
        </Link>
    )
}

export default GroupCard