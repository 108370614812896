import React, { Fragment, useMemo, useState, useEffect } from "react";

import classes from './SideNav.module.css'
import SideNavItem from "../sideNavItem/sidNavItemV2/SideNavV2-Item";

import SeachIcon from '../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Navigation Icons/__Search.svg'
import ActivityIcon from '../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Navigation Icons/__Activity - On.svg'
import AllSites from '../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Navigation Icons/__All Sites.svg'
import Users from '../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Navigation Icons/__User.svg'
import Logout from '../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Navigation Icons/__Logout.svg'

import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from "../../../../store/ui-slice";
import { useNavigate } from "react-router-dom";

const SideNav = ({ cssClass }) => {
    const companyName = useSelector(state => state.auth.companyName);
    const DefaultNavItems = [
        {
            name: 'Dashboard',
            route: '/Dashboard',
            imgSrc: AllSites,
            end: true
        },
        //  {
        //     name: 'Search View',
        //     route: 'SearchView',
        //     imgSrc: SeachIcon, // Path to your image
        //     end: true
        // },
        {
            name: 'Users',
            route: 'Users',
            imgSrc: Users,
        }
    ];

    // if (companyName === "EyeSpyFX" || companyName === "RE:SURE" || companyName === "KNOWIO") {
    //     DefaultNavItems.push({
    //         name: 'Users',
    //         route: 'Users',
    //         imgSrc: Users,
    //     },);
    // }

    if (companyName === "EyeSpyFX") {
        DefaultNavItems.push({
            name: 'Activity',
            route: 'Activity',
            imgSrc: ActivityIcon,
            end: true
        });
    }


    const navSettingsItems = useMemo(() => [
        // {
        //     name: 'Settings',
        //     route: 'Settings',
        //     imgSrc: SettingsIcon,
        // },
        {
            name: 'Logout',
            route: '/logout',
            imgSrc: Logout,
        },
    ], []);

    const dispatch = useDispatch();

    const handleHideSideNav = () => {
        sessionStorage.removeItem("SavedSearchQuery");
        sessionStorage.removeItem("SavedSearchFilters");
        sessionStorage.removeItem("SavedSearchSortOrder");
        sessionStorage.removeItem("isGetAllDevicesPressed");
        dispatch(uiActions.ShowSideNav(false));
    }

    return (
        <Fragment>
            <div className={classes['side-nav']} style={cssClass}>
                <div className={classes["nav-wrapper"]}>
                    <nav style={{ width: "100%" }}>
                        <ul className={classes["nav-listLinks"]}>
                            {DefaultNavItems.map((item, index) => (
                                <li style={{ width: "100%" }} key={index}>
                                    <SideNavItem route={item.route} image={item.imgSrc} text={item.name} onClick={handleHideSideNav} end={item.end} />
                                </li>
                            ))}
                        </ul>
                    </nav>

                    <nav>
                        <ul className={classes["nav-listLinks"]}>
                            {navSettingsItems.map((item, index) => (
                                <li key={index}>
                                    <SideNavItem route={item.route} image={item.imgSrc} text={item.name} />
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </div>
        </Fragment >
    );
}

export default SideNav;