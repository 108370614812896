import React from 'react'
import Button1 from '../../../Button/Button1'
import Button2 from '../../../Button/Button2';

const SelectInput = ({ body, setBody, listItems, outputList, nextPage }) => {


    const HandeOnClick = (item) => {
        const selectedPin = outputList.find(output => output.Pin === item.Pin);
        // if selectPin is an Output pin then navgiate to modal page SelectOutputs else move to select InputType
        if (selectedPin) {
            setBody(prevBody => ({ ...prevBody, input: item.Pin, inputType: "0", inputRule: "0" }));
            nextPage(2);
        } else {
            setBody(prevBody => ({ ...prevBody, input: item.Pin }));
            nextPage(1);
        }
    }

    return (
        <div>
            <h2>Which Switch do you want the automation on?</h2>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-Start", justifyContent: "center", gap: "10px" }}>
                <ul style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", gap: "10px" }}>
                    {listItems.map((item, index) => {
                        console.log(item);
                        return (
                            <Button2 style={{ minWidth: "130px" }} key={index} onClick={() => HandeOnClick(item)}>
                                {item.pinName}
                            </Button2>

                        )
                    })}
                </ul >
            </div>
        </div>
    )
}

export default SelectInput