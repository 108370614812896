import React, { useEffect, useState } from 'react'

import classes from "./AutomationsGrid.module.css";
import Card from './Card';
import { useSelector } from 'react-redux';
import IFTTTRuleLayout from '../UI/Cards/Automations/EditAutomations/IFTTTRuleLayout';
import CreateAutomation from '../UI/Cards/Automations/EditAutomations/CreateAutomation';
import ScheduleContentLayout from '../UI/Cards/Automations/EditAutomations/ScheduleContentLayout';
import HolidayModeCard from '../UI/Cards/Automations/HolidayMode/HolidayModeCardContentLayout';
import HolidayModeCardContentLayout from '../UI/Cards/Automations/HolidayMode/HolidayModeCardContentLayout';


const AutomationsGrid = ({ DeviceRules, siteSchedules, siteHolidayModes, OutPutsPinNames, InputsPinNames, allListOptionsArray, HandleSelectAutomation, fetchAutomations, numInput, smartLocks }) => {
    //-------------------------------------------- Filtering Auotmations -------------------------------------------------------------
    const DeviceRulesEntries = Object.entries(DeviceRules)

    // filter rules by outputs length. If string legnth is more than 3 that means a ouput has been assgined to the input
    const filteredArray = DeviceRulesEntries.filter(([key, value]) => {
        if (value && value.outputs) {
            const Outputs = value.outputs;
            const OutputsStringLength = Outputs.length;

            return OutputsStringLength >= 3;
        }
        return false; // Filter out entries where value or value.outputs is undefined
    });

    //move input pin into the object 5: { ... }  to  { Pin: 5, ...}
    const Rules = filteredArray.map(([Pin, value]) => ({ Pin: parseInt(Pin), ...value }));
    console.log(Rules);
    //--------------------------------------------- parsing Device Firmware number to work out inputType -------------------------------
    const singleDeviceHeaderData = useSelector(state => state.service.singleDeviceHeaderData);
    const firmware = singleDeviceHeaderData.firmware;
    const Firmware = firmware.split('.')
    const firmwareMajor = Firmware[0]
    const firmwareMinor = Firmware[1]
    //---------------------------------------------------------------------------------------------------------------------------------

    // get to get pinName
    const HandlePinName = (item) => {
        const matchedPin = OutPutsPinNames.find(output => output.Pin === item.Pin) || InputsPinNames.find(Input => Input.Pin === item.Pin);

        let pinName = [matchedPin]
        return pinName;
    }

    const Automations = [
        {
            title: "IFTTT Rule",
            rule: "When Front Door is Open or Closed - Front Door will match that state",
            active: true
        },
        {
            title: "Scheduled Action",
            rule: "Light will auto disarm at 11:30 on Thurs",
            active: true
        },
        {
            title: "Reminder",
            rule: "You will be reminded to arm Front Door at 06:30 on Fri",
            active: false
        },
    ]


    console.log(siteSchedules.data.length);
    console.log(Rules.length);
    const NumberOfItemsInAutomationsArray = siteSchedules.data.length + Rules.length;
    console.log(NumberOfItemsInAutomationsArray);

    const gridStyleConditional = NumberOfItemsInAutomationsArray < 4 && "flex"
    return (
        <ul className={classes["grid-ul"]} style={{ display: gridStyleConditional && "flex", gap: gridStyleConditional && "30px" }}>
            {/* map rules array to cards  */}
            {Rules.map((item, index) => {
                console.log(item);

                // get PinName
                const pinName = HandlePinName(item);



                //check if rule applied to input or output
                let isRuleAppliedToInput = InputsPinNames.find(Input => Input.Pin === item.Pin);
                if (isRuleAppliedToInput != undefined) {
                    isRuleAppliedToInput = true;
                } else {
                    isRuleAppliedToInput = false;
                }

                console.log(pinName);

                // get inputType
                let InputTypeText;
                console.log(item.inputType);
                switch (parseInt(item.inputType)) {
                    case 0:
                        InputTypeText = "Armed";
                        break;
                    case 1:
                        InputTypeText = "Armed or Disarmed";
                        break;
                    case 2:
                        InputTypeText = "Disarmed";
                        break;
                    case 3:
                        if (firmwareMajor == 1 && firmwareMinor >= 9) {
                            InputTypeText = "Pulse";
                        }
                        break;
                    case 4:
                        if (firmwareMajor == 1 && firmwareMinor >= 9) {
                            InputTypeText = "Reverse Pulse";
                        }
                        break;
                }


                console.log(item);
                // get outPutname from getRule object -- being item
                const arrayofOutputs = item.outputs.split(",");
                const arrayofOutputsInts = arrayofOutputs.map(output => parseInt(output));
                console.log(arrayofOutputsInts);

                let matchedOutput;

                if (arrayofOutputsInts.length > 0) {
                    matchedOutput = OutPutsPinNames.filter(output => {
                        console.log(`output.Pin: ${output.Pin}`);

                        // Check if any element in arrayofOutputsInts matches the current output.Pin
                        // and ensure output.Pin does not match pinName.Pin
                        return arrayofOutputsInts.some((outputInt) => output.Pin === outputInt) && output.Pin !== pinName[0].Pin
                    });
                }




                console.log(matchedOutput);

                let LinkOutPutsArray = matchedOutput && matchedOutput;

                console.log(LinkOutPutsArray);

                // get inputRule
                let InputRuleText;
                console.log(item.inputRule);
                switch (parseInt(item.inputRule)) {
                    case 0:
                        InputRuleText = "match the state";
                        break;
                    case 1:

                        InputRuleText = "oppose the State";
                        break;
                    case 2:
                        InputRuleText = "flip their own State";
                        break;
                }

                return (
                    <Card key={index} className={classes.card} index={index} color={null}>
                        <IFTTTRuleLayout listoptions={allListOptionsArray} OutPutOptions={OutPutsPinNames} title={Automations[0].title} pinName={pinName} item={item} InputTypeText={InputTypeText} InputType={item.inputType} LinkOutPutsArray={LinkOutPutsArray} InputRuleText={InputRuleText} OutPutsPinNames={OutPutsPinNames} InputsPinNames={InputsPinNames} isRuleAppliedToInput={isRuleAppliedToInput} fetchAutomations={fetchAutomations} />
                    </Card>
                )
            })}

            {/* map schedules to cards */}
            {siteSchedules.data.map((item, index) => {
                return (
                    <Card key={index} className={classes.card} index={index} color={null}>
                        <ScheduleContentLayout item={item} OutPutOptions={OutPutsPinNames} fetchAutomations={fetchAutomations} />
                    </Card>
                )
            })}

            {/* map holidayModes to cards */}
            {siteHolidayModes.map((holdiayMode, index) => {
                console.log(holdiayMode, index);
                return (
                    <Card key={holdiayMode.id} className={classes.card} index={index} color={null}>
                        <HolidayModeCardContentLayout holdiayMode={holdiayMode} OutPutsPinNames={OutPutsPinNames} smartLocks={smartLocks} />
                    </Card>
                )
            })}

            {/* render Create Automation card */}
            <Card >
                <CreateAutomation HandleSelectAutomation={HandleSelectAutomation} numInput={numInput} />
            </Card>
        </ul>
    )
}

export default AutomationsGrid