import React, { useState, useRef, useEffect } from 'react';
import CategoryFilter from './CategoryFilter';
import SearchBar from './SearchBar';

// import img from "../../assets/PortalIconnographySVGs/PortalAppRealted_Icons/Power Search/__Search.svg";

import classes from './PowerSearch.module.css'
import { useSelector } from 'react-redux';
import { PowerSearch } from '../../Api Methods/Api';

const FilterUsersDeviceSearch = ({ Devices, searchQuery, setSearchQuery, setIsLoading, placeholder, setFilteredResults, handleFilterLogic, categories }) => {

    console.log(Devices);
    const [categoryFilter, setCategoryFilter] = useState('all');

    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);

    const ApiData = {
        token,
        client
    }

    const formRef = useRef();


    useEffect(() => {
        const HandleUserSearch = async (ApiData, Devices, query) => {
            setIsLoading(true);
            console.log(Devices);
            handleFilterLogic(Devices, searchQuery, categoryFilter);
            if (Devices != null) {
                setIsLoading(false);
            }
        }

        const delayDebounceFn = setTimeout(() => {
            //   Perform fetch call with the input value
            if (searchQuery === "") {
                setFilteredResults(Devices);
            } else if (searchQuery !== "") {
                HandleUserSearch(ApiData, Devices, searchQuery)
            }
        }, 500);


        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, categoryFilter, Devices]);


    return (
        <form className={classes.form} ref={formRef}>
            <SearchBar
                text="text"
                placeholder={placeholder}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className={classes.filters}>
                {/* {categories && <CategoryFilter setCategoryFilter={setCategoryFilter} categories={categories} />} */}
            </div>
        </form>
    );
};

export default FilterUsersDeviceSearch;
