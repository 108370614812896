import React, { useState } from 'react'
import classes from "./PinsView.module.css";
import EditPinOutputList from '../PinOut/OutputV2/OutputList/EditPinOutputList';
import EditPinInputList from '../PinInput/InputPinV2/InputList/EditPinInputList';
import EditPin from './EditPin';
const EditPinsView = ({ singleDevicePins, HandleToggleEditPinView, showEditPinForm, setShowEditPinForm, HandleShowEditPinForm }) => {
    const { OutputPins, InputPins, singleDeviceSettingData } = singleDevicePins;
    console.log(singleDeviceSettingData.numInput);

    
    const [PinDetails, setPinDetails] = useState(null)

  

    return (
        <>
            {showEditPinForm ?
                <EditPin PinDetails={PinDetails} setShowEditPinForm={setShowEditPinForm} HandleToggleEditPinView={HandleToggleEditPinView} />
                :
                <div className={classes["PinView-container"]}>
                    <EditPinOutputList OutputPins={OutputPins} polarity={singleDeviceSettingData.polarity} HandleShowEditPinForm={HandleShowEditPinForm} setPinDetails={setPinDetails} />

                    {/* check if device has no inputs if not then skip*/}
                    {singleDeviceSettingData.numInput != 0 && <EditPinInputList InputPins={InputPins} polarity={singleDeviceSettingData.polarity} HandleShowEditPinForm={HandleShowEditPinForm} setPinDetails={setPinDetails} />}
                </div>
            }
        </>
    )
}

export default EditPinsView