import React from 'react'

import classes from './RoleIndicatorCard.module.css'

const RoleIndicatorCard = (props) => {

  console.log(props.role);

  function getCardSize(size) {
    switch (size) {
      case 1:
        return { backgroundColour: "#3a7cc8", title: "Manager" };
      case 2:
        return { backgroundColour: "#bababa", title: "User" };
      case 3:
        return { backgroundColour: "black", title: "Installer" };
      default:
        return { backgroundColour: "defaultBackground", title: "placeHolder" };
    }
  }

  const { backgroundColour, title } = getCardSize(props.role);

  return (
    <div style={{ background: backgroundColour }} className={classes.StatusIndicatorCard}>{title}</div>
  )
}

export default RoleIndicatorCard;