import UseDateFormat from "./UseDateFormat";

const UseGetDateEvents = (result, DataPointIndex, selectedMonth) => {
    console.log(result);
    console.log(DataPointIndex);
    console.log(selectedMonth);

    // get current date (in ISO 8601 format)
    const currentDate = new Date();
    // get current month (int)
    const currentMonth = currentDate.getUTCMonth();

    // revese array order
    const Events = result.data;
    console.log(Events);
    // const reversedEvents = Events.reverse();

    // Get the current year
    const currentYear = new Date().getUTCFullYear();

    // Get all Events for the current month and year
    const MonthEvents = Events.filter(event => {
        const date = new Date(event.eventDate);
        return date.getUTCMonth() === selectedMonth && date.getUTCFullYear() === currentYear;
    });

    // Current object properties
    /*
     {
      eventDate: '2024-03-22T15:54:54.000Z',
      type: 'userManagement',
      subType: 'left',
      user: 'Ryan {EyeSpyFX:Dev}',
      recipientName: 'Unknown',
      pin: 96,
      eventData: 'left'
    },
    */


    // Format event object to formate eventDate into 
    MonthEvents.forEach((event, index, array) => {

        // Extract eventDate and the remaining properties as rest
        const { eventDate, ...rest } = event;

        // Extracting time from the ISO 8601 formatted eventDate string
        const eventTime = new Date(eventDate).toLocaleTimeString('en-US', {
            hour12: true,
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });

        // format eventDate to get eventDate
        const date = new Date(eventDate);  // Create a new Date object 
        const eventShortDate = date.getUTCDate() // get the date only

        // format eventDate from (in ISO 8601 format) to (22nd March 2024)
        const formattedDate = UseDateFormat(eventDate);

        // Update the existing event object:
        array[index] = {
            eventTime,
            eventShortDate,
            eventDate: formattedDate,
            ...rest
        };
    });

    // New format for Event Object
    /*  
    {
      eventTime: '03:54:54 PM',         -- new
      eventShortDate: 22,               -- new
      eventDate: '22nd March 2024',     -- formatted
      type: 'userManagement',
      subType: 'left',
      user: 'Ryan {EyeSpyFX:Dev}',
      recipientName: 'Unknown',
      pin: 96,
      eventData: 'left'
    },
    */
    console.log(MonthEvents);
    //----------------------------------------------------------------------

    const groupEventsByEventDate = (events) => {
        const groupedByDate = {};
    
        // Group events by eventDate
        events.forEach(event => {
            const { eventDate, ...rest } = event;
    
            // checks if there is no array present within groupedByDate for the current instance of eventDate, if true then create a new empty array for that 'eventDate'
            if (!groupedByDate[eventDate]) {
                groupedByDate[eventDate] = [];
            }
    
            // adds the event including eventDate to the array for the current instance of eventDate
            groupedByDate[eventDate].push({ eventDate, ...rest });
        });
    
        // Convert the grouped object into an array
        const result = Object.keys(groupedByDate).map(eventDate => ({
            eventDate,
            eventData: groupedByDate[eventDate]
        }));
    
        return result;
    }

    const groupedEvents = groupEventsByEventDate(MonthEvents);

    //----------------------------------------------------------------------

    console.log(groupedEvents);

    const getEventsForSingleDate = (events, selectedDate) => {
        const event = events.find(event => {
            return parseInt(event.eventData[0].eventShortDate) == selectedDate;
        });
        return event;
    }

    console.log(groupedEvents);
    const SingleDateEvents = getEventsForSingleDate(groupedEvents, DataPointIndex);
    console.log(SingleDateEvents);
    return SingleDateEvents
};

export default UseGetDateEvents;

/* 

  const groupEventsByEventDate = (events) => {
        const groupedByDate = {};

        // Group events by eventDate
        events.forEach(event => {
            const { eventDate, ...rest } = event;

            console.log(eventDate);
            console.log(rest);

            // checks if there is no array present within groupedByDate for the current instance of eventDate, if true then create a new emtpy array for that 'eventDate'
            if (!groupedByDate[eventDate]) {
                groupedByDate[eventDate] = [];
            }

            // adds the remaining properties of the current instance of eventDate by pusing rest
            groupedByDate[eventDate].push(rest);
        });

        console.log(groupedByDate);

        // Convert the grouped object into an array
        const result = Object.keys(groupedByDate).map(eventDate => ({
            eventDate,
            eventData: groupedByDate[eventDate]
        }));

        console.log(result);

        return result;
    }

    const groupedEvents = groupEventsByEventDate(MonthEvents);

    */