import React from 'react'

import classes from './InputPinCircle.module.css'

const InputPinCircle = ({ inputs, InputPins }) => {
  console.log(InputPins);
  let InputPincolour = "";
  let cssClasses = ``;
  // return (
  //   Object.keys(inputs).map((input, index) => {

  //     if (inputs[input] === 0) {
  //       console.log("here");
  //       InputPincolour = classes.black;
  //     } else if (inputs[input] === 1) {
  //       console.log("here");
  //       InputPincolour = classes.blue;
  //     } else if (inputs[input] === 2) {
  //       console.log("here");
  //       InputPincolour = classes.grey;
  //     }
  //     cssClasses = `${classes.input} ${InputPincolour}`;
  //     return (
  //       <li key={index}>
  //         <div className={cssClasses} />
  //       </li>
  //     )
  //   })

  // )


  const defaultProperties = {
    pinState: 0,
    active: 0,
};

// Check if the length of the array is less than 8
while (InputPins.length < 8) {
    // Create a new object with the default properties
    const newObj = { ...defaultProperties };

    // Push the new object to the array
    InputPins.push(newObj);
}


  return (
    InputPins.map((InputPin, index) => (
      // const { pinName, pinStatusOff, pinStatusOn, pinState, icon, DeviceUUID, Pin, active } ;     
      <li key={index}>
        <div className={classes.input}
          style={{ background: InputPin.active ? InputPin.pinState ? "#575967" : "#4f719a" : "#e3e3e3" }}
        >
          <h4 className={classes.pinContent}>{InputPin.active ? InputPin.pinState ? "" : "" : "x"}</h4>
        </div>
      </li>
    )
    ))
}

export default InputPinCircle;