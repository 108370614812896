import React, { useState, useRef, useImperativeHandle, forwardRef, cloneElement, useEffect } from "react";
import classes from './Modal.module.css';

import { createPortal } from "react-dom";
import { useDispatch } from "react-redux";
import { serviceActions } from "../../store/service-Slice";

const Modal = forwardRef(function AddUser(props, ref) {
    // ------------------------ Modal Dialog open/close code --------------------------------------------------------------------
    const dispatch = useDispatch();
    const dialog = useRef();

    useImperativeHandle(ref, () => {
        return {
            open() {
                dialog.current.showModal();
            },
            close() {
                dialog.current.close();
            },
            closeOnEscape() {
                handlerCloseModalOnEscape();
            }
        }
    })

    // reset model when Escape key is pressed
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                // Handle the Escape key press here
                dialog.current.close();

            }
        };

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);

        // Clean up event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handlerCloseModalOnEscape = (event) => {
        if (event.key != "Escape") {
            return
        }
        event.preventDefault();
        dialog.current.close();
        // dispatch(serviceActions.UpdateShowAddUserModal(false));
    };

    const handlerCloseModal = (event) => {
        event.preventDefault();
        dialog.current.close();
        // dispatch(serviceActions.UpdateShowAddUserModal(false));
    };

    // Pass close function as a prop to children
    const childrenWithProps = React.Children.map(props.children, child => {
        return cloneElement(child /*, { closemodal: () => dialog.current.close() }*/);
    });


    //---------------------------------------------------------------------------------------------------------------------



    //--------------------  Active Tab Logic-------------------------------------------------------------------------

    const { HandleShowModal, HandleCloseModal, HandleNavigateToSiteUsers, ShowHeaderTabButtons, siteId, style } = props;
    console.log(HandleCloseModal);
    const [showHeaderTabButtons] = useState(ShowHeaderTabButtons || false);

    const tabButtons = [
        { buttonText: "Site View", HandleShowModal: HandleShowModal },
        { buttonText: "Site Users", HandleModalSwitch: () => HandleNavigateToSiteUsers(siteId) }
    ];

    // if device is a mac then position modal header button to the left side, otherwise to the right
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    
    //---------------------------------------------------------------------------------------------------------------------

    return createPortal(
        <dialog ref={dialog} className={classes.modalCard} onKeyDown={(event) => handlerCloseModalOnEscape(event)} style={style}>

            {/* --- Header --- */}
            <div className={isMac ? classes.MacHeader : classes.WindowsHeader}>
            {isMac && <button className={`${classes.closeButton} ${classes.MacCloseButton}`} onClick={HandleCloseModal || handlerCloseModal}> X </button>}
                <h2 className={classes.Title}>{props.title || "Main Modal Title"}</h2>
                <div className={classes.headerButtonsLayout}>
                    <ul className={classes.headerTabs}>
                        {showHeaderTabButtons &&
                            tabButtons.map((tabButton, index) => {
                                return (
                                    <li key={index}>
                                        <div className={`${classes.tabButton} ${tabButton.buttonText === props.modalName && classes.tabButtonActive}`} onClick={tabButton.HandleModalSwitch}>  {tabButton.buttonText} </div>
                                    </li>
                                );
                            })
                        }
                    </ul>
                    {!isMac && <button className={classes.closeButton} style={{marginRight: "15px"}}onClick={HandleCloseModal || handlerCloseModal}> X </button>}
                </div>
            </div>
            <div style={props.modalLayoutStyle || null} className={`${classes["modal-layout"]} ${props.className}`}>
                {childrenWithProps}
            </div>
        </dialog >,
        document.getElementById('modal')
    );

})

export default Modal;

