const  UseAddDateSuffix = (index) => {
    if (index >= 11 && index <= 13) {
        return index + 'th';
    }
    switch (index % 10) {
        case 1:
            return index + 'st';
        case 2:
            return index + 'nd';
        case 3:
            return index + 'rd';
        default:
            return index + 'th';
    }
    }

export default UseAddDateSuffix;