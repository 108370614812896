import React, { useState, useEffect } from 'react';
import classes from "./CategoryFilter.module.css";

import arrrowIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Filter/__Page - Forward.svg"
import Button1 from '../UI/Button/Button1';

const SortDevices = ({ setSortBy, categories, sortBy, setSortOrder, sortOrder }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const handleCategoryChange = (event) => {
        const selectedCategory = event.target.value;
        let updatedSelectedCategories = [...selectedCategories];
        console.log(event.target);

        // console.log(updatedSelectedCategories);

        // updatedSelectedCategories.unshift(sortOrder);
        // console.log(updatedSelectedCategories);
        // add sort to array, 
        if (event.target.checked) {
            updatedSelectedCategories = []
            updatedSelectedCategories.unshift(selectedCategory);
        } else {
            // remove sort from array
            updatedSelectedCategories = updatedSelectedCategories.filter(category => category !== selectedCategory);
        }

        setSelectedCategories(updatedSelectedCategories);
        setSortBy(updatedSelectedCategories);
    };

    useEffect(() => {
        if (sortBy != 'all') {
            setSelectedCategories(sortBy)
        }
    }, [sortBy])

    const toggleDropdown = (event) => {
        event.preventDefault();
        setShowDropdown(!showDropdown);
    };

    const HandleChangeSortOrder = () => {
        sortOrder == "Ascending" ? setSortOrder("Decending") : setSortOrder("Ascending")
    }

    return (
        <div className={classes.categoryFilter}>

            <Button1 style={{ height: "50px", fontSize: "16px", width: "300px", justifyContent: "space-between" }} className={` ${classes.button}`} img={arrrowIcon} onClick={toggleDropdown}>
                {selectedCategories.length === 0 ? "Sort Order" : "Sort Order: " + selectedCategories[0]}
            </Button1>

            {showDropdown && (
                <div className={`${classes.dropdown} ${"fade-in-from-top"}`}
                    style={{minWidth:"264px"}}
                >
                    {/* <h4 style={{ fontWeight: "400", margin: "0px", marginBottom: "5px", cursor: "pointer" }} onClick={HandleChangeSortOrder}>{sortOrder}</h4> */}
                    {categories.map((category, index) => (
                        <label
                            key={category}
                            className={`${classes.categoryLabel} ${category === "Favorited" || category === "Not Favorited" ? classes.disabled : ''}`}
                            htmlFor={`checkbox-${category}`}
                        >
                            <input
                                className={classes.filterItem}
                                id={`checkbox-${category}`}
                                type="checkbox"
                                value={category}
                                checked={selectedCategories.includes(category)}
                                onChange={handleCategoryChange}
                                disabled={category === "Favorited" || category === "Not Favorited"}
                            />
                            {category}
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SortDevices;
