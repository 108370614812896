import React, { useEffect, useReducer, useRef, useState } from 'react'
import Modal from '../../../../../Modal/Modal';



import { useDispatch, useSelector } from 'react-redux';

import classes from "./CamectModalForm.module.css"
import Input from '../../../../../CustomDropDown/Input/Input';
import Button1 from '../../../../Button/Button1';
import { addCamectAPI, updateCamectAPI } from '../../../../../../Api Methods/Api';
import { checkAuthLoader } from '../../../../../../auth';

const CamectIPReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.length > 0 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: null };
    }
    //otherwise 
    return { value: '', isValid: false };
}

const CamectPasswordReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.length > 0 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: null };
    }
    //otherwise 
    return { value: '', isValid: false };
}


const CamectModalForm = ({ setShowVirtualSource, title, subTitle, placeholders, refreshCamects, camect }) => {

    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const DeviceUUID = useSelector(state => state.auth.DeviceUUID);


    const apiData = {
        token,
        client
    }

    const [modalComponent, setModalComponent] = useState(null);
    const [modalPageIndex, setModalPageIndex] = useState(0);

    //------------------------------- Modal Logic  ----------------------------------------------

    const ModalDialog = useRef();

    // call ref to clearStates method from child component ScheduledAction.
    const ClearStatesChildComponetRef = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();

        HandelResetModal();
    }
    //-------------------------------------------------------------------------------------------------

    // reset model when Escape key is pressed
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                // Handle the Escape key press here
                HandelResetModal();
            }
        };

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);


        // Clean up event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const [formIsValid, setFormIsValid] = useState(false);

    console.log(camect);

    const [camectIPState, dispatchCamectIP] = useReducer(CamectIPReducer, {
        value: '',
        isValid: null
    });

    const [camectPasswordState, dispatchCamectPassword] = useReducer(CamectPasswordReducer, {
        value: '',
        isValid: null
    });


    const camectIPInputRef = useRef();
    const camectPasswordRef = useRef();

    const { isValid: camectIPIsValid } = camectIPState;
    const { isValid: camectPasswordIsValid } = camectPasswordState;


    useEffect(() => {
        const identifier = setTimeout(() => {
            setFormIsValid(camectIPIsValid && camectPasswordIsValid)
        }, 500);

        return () => {
            clearTimeout(identifier);
        };
    }, [camectIPIsValid, camectPasswordIsValid]);

    const camectIPChangeHandler = (event) => {
        dispatchCamectIP({ type: 'USER_INPUT', val: event.target.value });
    }

    const camectPasswordChangeHandler = (event) => {
        dispatchCamectPassword({ type: 'USER_INPUT', val: event.target.value });
    }

    const validateCamectIPHandler = () => {
        dispatchCamectIP({ type: 'INPUT_BLUR' });
    }

    const validateCamectPasswordHandler = () => {
        dispatchCamectPassword({ type: 'INPUT_BLUR' });
    }

    const HandelResetModal = () => {
        console.log("here");
        setModalPageIndex(null);
        setShowVirtualSource(null);
    }

    const onClearHandler = () => {
        dispatchCamectIP({ type: 'INPUT_CLEAR', val: "" })
        dispatchCamectPassword({ type: 'INPUT_CLEAR', val: "" })
    }

    const dispatch = useDispatch();
    //10.250.5.69
    //tech.resure

    const submitHandler = (event) => {
        event.preventDefault();
        if (title == "Add") {
            const CamectIP = camectIPState.value;
            const CamectPassword = camectPasswordState.value;

            console.log(CamectIP, CamectPassword);

            const camectDetails = {
                "uuid": DeviceUUID,
                "address": CamectIP,
                "username": "admin",
                "password": CamectPassword
            }

            const responseData = addCamectAPI(apiData, camectDetails, dispatch)
            if (true) {
                HandleCloseModal();
                onClearHandler();
                refreshCamects();
            }
            // onClearHandler();
            // } else if (!camectIPIsValid) {
            //     camectIPInputRef.current.focus();
        } else if (title == "Edit") {
            const CamectIP = camectIPState.value != "" ? camectIPState.value : camect.address;
            const CamectPassword = camectPasswordState.value != "" ? camectPasswordState.value : camect.password;

            console.log(CamectIP, CamectPassword);

            const camectDetails = {
                "uuid": DeviceUUID,
                "id": camect.id,
                "address": CamectIP,
                "username": "admin",
                "password": CamectPassword
            }

            const responseData = updateCamectAPI(apiData, camectDetails, dispatch)

            if (true) {
                HandleCloseModal();
                onClearHandler();
                refreshCamects();
            }
        }
    };

    useEffect(() => {
        switch (modalPageIndex) {
            case 0:
                setModalComponent(
                    <form onSubmit={submitHandler} className={classes["container"]} method="dialog">

                        <ul className={classes["inputs-list"]}>
                            <li className={classes["input-container"]}>
                                <label className={classes.subTitle}>{subTitle}</label>
                                <Input
                                    className={classes.input}
                                    placeholder={title == "Edit" ? camect.address : placeholders.placeholder1}
                                    ref={camectIPInputRef}
                                    id="CamectIP"
                                    type={"text"}
                                    isValid={camectIPState.isValid}
                                    value={camectIPState.value}
                                    onChange={camectIPChangeHandler}
                                    onBlur={validateCamectIPHandler}
                                />

                                <Input
                                    className={classes.input}
                                    placeholder={title == "Edit" ? "********" : placeholders.placeholder2}
                                    ref={camectPasswordRef}
                                    id="CamectPassword"
                                    type={"password"}
                                    isValid={camectPasswordState.isValid}
                                    value={camectPasswordState.value}
                                    onChange={camectPasswordChangeHandler}
                                    onBlur={validateCamectPasswordHandler}
                                />
                            </li>
                        </ul>

                        <Button1 propsclassName={classes.submitButton} type="submit">{title == "Edit" ? "Update" : "Save"}</Button1>
                    </form>
                )
                HandleShowModal();
                break;
        }
    }, [modalPageIndex, camectIPState, camectPasswordState]); // Empty dependency array ensures it runs only once after initial render



    const CreateSchedule = async (body) => {

        // const response = await createScheduleApi(apiData, body, dispatch);
        // if (response && response.success) {
        //     HandleCloseModal();
        //     fetchAutomations();
        // }

    }

    return (
        <>
            <Modal ref={ModalDialog} title={title + " Camect"} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} modalLayoutStyle={{ alignItems: "center" }}>
                {modalComponent}
            </Modal>
        </>
    )
}

export default CamectModalForm;