import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button1 from '../../components/UI/Button/Button1';
import { getDevices, PowerSearch } from '../../Api Methods/Api';

import classes from "./GetAllDevices.module.css";

const GetAllDevices = ({ searchQuery, setIsLoading, handleFilterLogic, setFilteredResults, categoryFilter, sortBy, setSearchQuery, setIsGetAllDevicesPressed, isGetAllDevicesPressed, setGetAllDevicesTimer, powerSearchTimer, sortOrder, filteredResultsLength, fetchDevices, setFetchDevices }) => {
    const [localCategoryFilters, setLocalCategoryFilters] = useState();
    const [localSortBy, setLocalSortBy] = useState();

    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const companyName = useSelector(state => state.auth.companyName);

    console.log("HERE")
    console.log(categoryFilter)
    console.log(sortBy)
    console.log(isGetAllDevicesPressed)
    console.log(fetchDevices)

    const ApiData = {
        token,
        client
    }

    const formRef = useRef();

    // create a ref to hold trigger time of search
    const GetAllDevicesTimer = useRef();

    // // // Set the ref when the component mounts
    // useEffect(() => {

    //     console.log(GetAllDevicesTimer.current);
    //     setGetAllDevicesTimer(GetAllDevicesTimer.current);
    // }, [GetAllDevicesTimer]);

    const GetAllDevices = async (GetAllDevicesTimer) => {
        console.log("ALL DEVICES HAS BEEN PRESSED")
        setIsLoading(true);
        setSearchQuery("");


        // Store the search trigger time in timer useRef
        GetAllDevicesTimer.current = Date.now();

        // store seach trigger time
        const triggerTime = GetAllDevicesTimer.current;

        const responseData = await getDevices(ApiData, companyName, dispatch);
        console.log(responseData);
        const Sites = responseData;

        //   GetAllDevicesTimer.current > powerSearchTimer.current
        if (GetAllDevicesTimer.current == triggerTime) {
            console.log("here");
            setIsLoading(false)
            //     // if trigger time is equal to current time.current
            console.log(Sites)
            console.log(companyName)
            console.log(categoryFilter)
            console.log(sortBy)
            handleFilterLogic(Sites, companyName, categoryFilter, sortBy);

            if (responseData != null) {
                setLocalCategoryFilters(categoryFilter);
                setLocalSortBy(sortBy);
                setIsLoading(false);
                setGetAllDevicesButtonText("All Sites");
                setFetchDevices(false);
            }
        }
    }

    useEffect(() => {
        console.log("here");
        if (isGetAllDevicesPressed && fetchDevices) {
            console.log("here");
            GetAllDevices(GetAllDevicesTimer)
        }
    }, [isGetAllDevicesPressed])


    const [getAllDevicesButtonText, setGetAllDevicesButtonText] = useState("All Sites");

    function arraysAreIdentical(arr1, arr2) {
        if (arr1.length !== arr2.length) return false;
        return arr1.every((value, index) => value === arr2[index]);
    }

    useEffect(() => {
        console.log("category filter changed")
        //check if any cetegory are selected, this means is categoryFilter or sortBy an array with a length of 1 or more.
        //only trigger if there is not search query 
        if ((Array.isArray(categoryFilter) && categoryFilter.length > 0 || Array.isArray(sortBy) && sortBy.length > 0) && (searchQuery == "")) {
            setGetAllDevicesButtonText("Apply Filters")
            // check if localCategoryFilters and localSortBy are arrays, if there are then this means user is beyond inital get devices fetch
            if (Array.isArray(localCategoryFilters) && Array.isArray(localSortBy)) {

                // compare both (categoryFilter && localCategoryFilters) and (sortBy && localSortBy) to check if the user has select different categories or a sort by
                const isCategoryFiltersSame = arraysAreIdentical(categoryFilter, localCategoryFilters);
                const isSortBySame = arraysAreIdentical(sortBy, localSortBy);

                // if isCategoryFiltersSame and isSortBySame are the same then the user has not select any different categories or sort bys, so keep button text "All Devices"
                if (isCategoryFiltersSame && isSortBySame) {
                    console.log("here");
                    setGetAllDevicesButtonText("All Sites")

                    // if either isCategoryFiltersSame or isSortBySame are false then the user has changed the categories or sort by so update button text to "Apply Filters"
                } else if (!isCategoryFiltersSame || !isSortBySame) {
                    setGetAllDevicesButtonText("Apply Filters")
                    // only apply Apply Filter when there is data, not while it loads
                    if (filteredResultsLength != 0) {
                        setGetAllDevicesButtonText("Apply Filters")
                    }
                }
            } else {
                if (isGetAllDevicesPressed && filteredResultsLength != 0) {
                    console.log("here");
                    //Initial Fetch
                    //setGetAllDevicesButtonText("Apply Filters")
                }
            }
        } else {
            setGetAllDevicesButtonText("All Sites")
        }
    }, [categoryFilter, sortBy, isGetAllDevicesPressed, filteredResultsLength, localCategoryFilters, localSortBy])

    const HandleSetGetAllDevices = () => {
        console.log("here");
        setIsGetAllDevicesPressed(true)
        setFetchDevices(true);
        GetAllDevices(GetAllDevicesTimer)
    }


    return (
        <Button1 style={{ height: "50px", fontSize: "16px", minWidth: "120px" }} type={"button"} className={classes.button} onClick={HandleSetGetAllDevices}>{getAllDevicesButtonText}</Button1>
    )
};

export default GetAllDevices;
