import React from 'react'
import { authActions } from '../../store/auth-Slice';
import { getRefreshToken } from '../Api';

const postRequest = async (uri, data, headers, dispatch) => {
    console.log("here");
    console.log(
        uri,
        data,
        headers
    );

    const response = await fetch(
        uri, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: headers.token,
            Client: headers.client,
        },
    });
    if (!response.ok) {
        if (response.status === 401) {
            console.log("here");
            // Handle 401 error: acquire new token and retry the request
            const newApiData = await getRefreshToken();

            console.log(newApiData);

            // if token expired and user navigates to new page - loaders cant use dispatch so reload as getRefreshToken as passed new token and refresh tokens to session storage.
            dispatch ? dispatch(authActions.UpdateToken(newApiData)) :
                window.location.reload();

            const newresponseData = await postRequest(uri, data, newApiData, dispatch);
            return newresponseData
        }
        throw new Error("Fetching failed: " + response.statusText);
    }

    const responseData = await response.json();
    console.log(responseData);

    if (responseData.message == 'sent') {
        return true;
    }

    console.log(responseData.success);
    // check that the response had authorization, if not go to catch.

    // if (responseData.success == false) {
    //     console.error(responseData.data.success);

    //     // if user trys to generate too many otp lock codes in a short period of time.
    //     if (responseData.msg == "All codes used for this time period.") {
    //         return responseData
    //     } 
    //     throw new Error("Auth failed ");
    // }
    return responseData;
};

export default postRequest