import { useRouteError } from 'react-router-dom'

import PageContent from './PageContent'

const ErrorPage = () => {
    const error = useRouteError();


    console.log(error);
    // let title = 'An error occurrend!';
    // let message = 'Something went wrong';

    let errorStatus = 'Unexpected Crash';
    let title = 'Crash Detected';
    let errorMessage = 'It seems something went wrong.';
    ;
    // if (error.message === 'response is not defined') {
    //     errorStatus = '400.';
    //     title = 'Bad Request';
    //     errorMessage = error.message;

    // } else if (error.status === undefined) {
    //     errorStatus = 'Unexpected Crash';
    //     title = '';
    //     errorMessage = error.message;
    // } else if (error.status === 404) {
    //     errorStatus = "404."
    //     title = 'Not found!';
    //     errorMessage = 'Could not find resource or page';
    // } else {
        // errorStatus = error.status;
        // title = "Thats an error!"
        // errorMessage = error.message;

        errorStatus = error.status + ".";
        title = 'That\'s an error!';
        errorMessage = error.message;
    // }


    return (
        <PageContent errorStatus={errorStatus} title={title} errorMessage={errorMessage} />
    )
}

export default ErrorPage