import React, { Children, useEffect, useState } from 'react'

import classes from "./GridList.module.css";

import Card from "./Card";
import GridPaginationControls from '../Table/Table/GridPaginationControls';
import SiteCardContentLayout from '../UI/DataLayouts/SiteCardContentLayout';
import useParseArrayDeviceData from '../hooks/useParseArrayDeviceData';
const DevicesGrid = ({ data, HandleSaveSearchQuery}) => {
    const resultCount = data.length;
    const [pageData, setPageData] = useState(data.slice(0, 20));
    const [pageSize, setPageSize] = useState(20);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageCount, setPageCount] = useState(Math.ceil(data.length / pageSize));

    const HandleNextPage = () => {
        setPageIndex(prevPageIndex => prevPageIndex + 1)
    }

    const HandlePreviousPage = () => {
        setPageIndex(prevPageIndex => prevPageIndex - 1)
    }

    const HandleSetPageIndex = (PageIndex) => {

        // if pageIndex is less than 1 and not "", set pageIndex to 1 to restrict user to correct page range
        if (PageIndex < 1 && PageIndex != "") {
            setPageIndex(1);
            // if pageIndex is greater than pagecount then limit PageIndex to pageCount (max number of pages)
        } else if (PageIndex > pageCount) {
            setPageIndex(pageCount);
        } else {
            setPageIndex(PageIndex)
        };
    }

    const HandlePageSize = (PageSize) => {

        const calculatedPageSize = Math.ceil(data.length / PageSize);
        setPageSize(PageSize);
        HandlePageCount(calculatedPageSize);
    }

    const HandlePageCount = (calculatedPageSize) => {
        setPageCount(calculatedPageSize);
        setPageIndex(1);
    }

    // If pageIndex updates, update the the current slice place on the data to the next range of data.
    // if pageSize updates, it set the number of site cards to either 15, 20, 25;
    // If data updates due to a filter being applied, update the pageData and pageCount 
    useEffect(() => {
        let PageSlice;
        if (pageIndex == "") {
            PageSlice = 1 * pageSize;
        } else {
            PageSlice = pageIndex * pageSize;
        }
        const PageIndex = PageSlice - pageSize;


        setPageData(data.slice(PageIndex, PageSlice))
        setPageCount(Math.ceil(data.length / pageSize));

    }, [pageIndex, pageSize, data])


    // If a filter is applied which updates the passed data, reset page index to 1;
    // useEffect(() => {
    //     setPageIndex(1);
    // }, [data])

    console.log(pageData);
    const parsedData = useParseArrayDeviceData(pageData);

    console.log(parsedData);

    return (
        <div className={classes["grid-container"]}>
            {/* Styling has been added to style cards when less than 10 */}
            <ul className={classes["grid-ul"]} style={{ display: parsedData.length < 6 && "flex", gap: parsedData.length < 6 && "54px" }}>
                {Array.isArray(parsedData) && parsedData.map((cardData, index) => (
                    <li className={classes["grid-li"]} key={index}>
                        {/* Key passed to act as index for each card, to make it so when a card is changed it replays the fade from top animation :: key={index + pageIndex * pageSize}  */}
                        <Card className={classes.card} index={index} color={cardData.type} isOnline={cardData.online}>
                            <SiteCardContentLayout cardData={cardData} HandleSaveSearchQuery={HandleSaveSearchQuery} />
                        </Card>
                    </li>
                ))}
            </ul>



            <GridPaginationControls pageIndex={pageIndex} nextPage={HandleNextPage} previousPage={HandlePreviousPage} setPageIndex={HandleSetPageIndex} pageCount={pageCount} setPageSize={HandlePageSize} PageSize={pageSize} resultCount={resultCount} />
        </div>
    )
}

export default DevicesGrid;
