import React, { useState, useEffect, Suspense } from 'react'

import classes from "./DeviceManager.module.css";

import FilterSearch from "../PowerSearch/PowerSearch";
import DeviceLoadingGrid from '../GridList/DeviceLoadingGrid';
import DevicesGrid from '../GridList/DevicesGrid';
import { Await } from 'react-router-dom';
import CategoryFilter from '../PowerSearch/CategoryFilter';
import SortDevices from '../PowerSearch/SortDevices';
import GetAllDevices from '../../Pages/Dashboard/GetAllDevices';
import ExportExcel from '../ExcelExport/ExcelExport';
import UseDateFormat from '../hooks/UseDateFormat';
import PowerSearch from '../PowerSearch/PowerSearch';
import GetPagedDevices from '../../Pages/Dashboard/GetPagedDevices';
import GetAllPagedDevices from '../../Pages/Dashboard/GetAllPagedDevices';
import { useSelector } from 'react-redux';

const DeviceManager = ({ client, token, companyName, aiQuery, getAllDevices }) => {
    let [filteredResults, setFilteredResults] = useState([]);

    const [totalPages, setTotalPages] = useState(null);
    let [maxPagedFetches, setMaxPagedFetches] = useState(2);
    const [pageFetchedIndex, setPageFetchedIndex] = useState(0)

    const groups = useSelector(state => state.auth.groups);
    const parsedGroups = JSON.parse(groups);
    console.log(parsedGroups)
    console.log(companyName)
    const groupBreadCrumb = parsedGroups.find((group) => group.name === companyName)
    console.log(groupBreadCrumb)
    const groupPath = groupBreadCrumb.group_path
    const GroupNamesArray = groupPath.split(">");

    //isGetAllDevicesPressed need to be true to both trigger the fetch and to display them through the Devices grid
    const [isGetAllDevicesPressed, setIsGetAllDevicesPressed] = useState(false);

    //fetchDevices needs to be true along with isGetAllDevicesPressed to trigger the Paged Devices fetch.
    const [fetchDevices, setFetchDevices] = useState(true);

    function compareTags(a, b) {
        if (a.tag < b.tag) return -1;
        if (a.tag > b.tag) return 1;
        return 0;
    }

    function compareTagsDec(a, b) {
        if (a.tag < b.tag) return 1;
        if (a.tag > b.tag) return -1;
        return 0;
    }

    function compareUserCountAsc(a, b) {
        if (a.userCount < b.userCount) return -1;
        if (a.userCount > b.userCount) return 1;
        return 0;
    }

    function compareUserCountDec(a, b) {
        if (a.userCount < b.userCount) return 1;
        if (a.userCount > b.userCount) return -1;
        return 0;
    }

    function compareFirmware(a, b) {
        const aParts = a.firmware.split('.').map(Number);
        const bParts = b.firmware.split('.').map(Number);
    
        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            const aVal = aParts[i] || 0;
            const bVal = bParts[i] || 0;
            if (aVal < bVal) return -1;
            if (aVal > bVal) return 1;
        }
        return 0;
    }

    function compareFirmwareDec(a, b) {
        const aParts = a.firmware.split('.').map(Number);
        const bParts = b.firmware.split('.').map(Number);
    
        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            const aVal = aParts[i] || 0;
            const bVal = bParts[i] || 0;
            if (aVal < bVal) return 1;
            if (aVal > bVal) return -1;
        }
        return 0;
    }

    const handleFilterLogic = async (data, searchQuery, selectedCategories, sortBy) => {

        console.log(data)
        console.log(searchQuery)
        console.log(selectedCategories)
        console.log(sortBy)

        function hasDevicesKey(data) {
            return Array.isArray(data) && data.every(entry => entry.hasOwnProperty('devices'));
        }

        let flattenedDataDevices;

        if (hasDevicesKey(data)){
            flattenedDataDevices = data.flatMap(entry => entry.devices);
            console.log("here")
        } else {
            flattenedDataDevices = data
            console.log("here")
        }

        let sortedData;
        switch (sortBy[0]) {
            case "Tag - Asc":
                console.log("TAG-ASC")
                sortedData = flattenedDataDevices.sort(compareTags)
                console.log(sortedData)
                break;
            case "Tag - Desc":
                sortedData = flattenedDataDevices.sort(compareTagsDec)
                break;

            case "Firmware - Asc":
                sortedData = flattenedDataDevices.sort(compareFirmware)
                console.log(sortedData)
                break;

            case "Firmware - Desc":
                sortedData = flattenedDataDevices.sort(compareFirmwareDec)
                console.log(sortedData)
                break;

            case "User Count - Asc":
                sortedData = flattenedDataDevices.sort(compareUserCountAsc)
                console.log(sortedData)
                break;

            case "User Count - Desc":
                sortedData = flattenedDataDevices.sort(compareUserCountDec)
                console.log(sortedData)
                break;
             default: 
                sortedData = flattenedDataDevices.sort(compareTags)
                console.log(sortedData)
                break;
            // // code block
        };

        let preFilteredData;
        console.log(sortedData)
        sortedData != undefined ? preFilteredData = sortedData : preFilteredData = data;

        let flattenedData;

        if (hasDevicesKey(preFilteredData)){
            flattenedData = preFilteredData.flatMap(entry => entry.devices);
            console.log("here")
        } else {
            flattenedData = preFilteredData
            console.log("here")
        }

        console.log(flattenedData)

        // Filter data based on search query and category 
        filteredResults = flattenedData.filter(result => {
            console.log(result)
            const DeviceDetailsParsed = JSON.parse(result.deviceDetails);

            const Pins = [21, 20, 16, 12, 4, 3, 2, 1, 5, 6, 13, 19];
            const isPinNotificationsActive = Pins.some(pin => {
                const pins = DeviceDetailsParsed[pin];
                return pins !== undefined && pins.notificationActive;
            });

            // Check if the result matches the search query

            const matchesSearchQuery = searchQuery != '' && searchQuery != null && searchQuery != companyName &&
                result.name && result.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                result.tag.toLowerCase().includes(searchQuery.toLowerCase()) ||
                result.key.toLowerCase().includes(searchQuery.toLowerCase()) ||
                result.subGroup && result.subGroup.toLowerCase().includes(searchQuery.toLowerCase()) ||
                result.deviceName && result.deviceName.toLowerCase().includes(searchQuery.toLowerCase()) 

            // // Check if the result matches the selected category
            let matchesCategory = true;
            if (selectedCategories.length > 0) {

                //----
                if (selectedCategories.includes('Favorited')) {
                    matchesCategory = result.favorited;
                }
                if (selectedCategories.includes('Not Favorited')) {
                    matchesCategory = !result.favorited;
                }
                if (selectedCategories.includes('Favorited') && selectedCategories.includes('Not Favorited')) {
                    matchesCategory = result.favorited || !result.favorited;
                }
                //----
                if (selectedCategories.includes('Notifications On')) {
                    matchesCategory = isPinNotificationsActive == true;
                }
                if (selectedCategories.includes('Notifications Off')) {
                    matchesCategory = isPinNotificationsActive == false;
                }
                if (selectedCategories.includes('Notifications On') && selectedCategories.includes('Notifications Off')) {
                    matchesCategory = isPinNotificationsActive == true || isPinNotificationsActive == false;
                }
                //----
                if (selectedCategories.includes('Online')) {
                    console.log("here");
                    matchesCategory = result.online === "YES";
                }
                if (selectedCategories.includes('Offline')) {
                    matchesCategory = result.online === "NO";
                }
                if (selectedCategories.includes('Online') && selectedCategories.includes('Offline')) {
                    matchesCategory = result.online === "YES" || result.online === "NO";
                }
                //--
                if (selectedCategories.includes('Favorited') && selectedCategories.includes('Notifications On')) {
                    matchesCategory = result.favorited && isPinNotificationsActive == true;
                }
                if (selectedCategories.includes('Not Favorited') && selectedCategories.includes('Notifications On')) {
                    matchesCategory = !result.favorited && isPinNotificationsActive == true;
                }
                if (selectedCategories.includes('Favorited') && selectedCategories.includes('Notifications Off')) {
                    matchesCategory = result.favorited && isPinNotificationsActive == false;
                }
                if (selectedCategories.includes('Not Favorited') && selectedCategories.includes('Notifications Off')) {
                    matchesCategory = !result.favorited && isPinNotificationsActive == false;
                }
                //--
                if (selectedCategories.includes('Online') && selectedCategories.includes('Favorited')) {
                    matchesCategory = result.online === "YES" && result.favorited;
                }
                if (selectedCategories.includes('Offline') && selectedCategories.includes('Favorited')) {
                    matchesCategory = result.online === "NO" && result.favorited;
                }
                if (selectedCategories.includes('Online') && selectedCategories.includes('Not Favorited')) {
                    matchesCategory = result.online === "YES" && !result.favorited;
                }
                if (selectedCategories.includes('Offline') && selectedCategories.includes('Not Favorited')) {
                    matchesCategory = result.online === "NO" && !result.favorited;
                }
                //--
                if (selectedCategories.includes('Online') && selectedCategories.includes('Notifications On')) {
                    matchesCategory = result.online === "YES" && isPinNotificationsActive == true;
                }
                if (selectedCategories.includes('Offline') && selectedCategories.includes('Notifications On')) {
                    matchesCategory = result.online === "NO" && isPinNotificationsActive == true;
                }
                if (selectedCategories.includes('Online') && selectedCategories.includes('Notifications Off')) {
                    matchesCategory = result.online === "YES" && isPinNotificationsActive == false;
                }
                if (selectedCategories.includes('Offline') && selectedCategories.includes('Notifications Off')) {
                    matchesCategory = result.online === "NO" && isPinNotificationsActive == false;
                }
                //--
                if (selectedCategories.includes('NoTag')) {
                    matchesCategory = result.tag === "";
                }
                if (selectedCategories.includes('NoTag') && selectedCategories.includes('Online')) {
                    matchesCategory = result.tag === "" && result.online === "YES"
                }
                if (selectedCategories.includes('NoTag') && selectedCategories.includes('Offline')) {
                    matchesCategory = result.tag === "" && result.online === "NO"
                }
                if (selectedCategories.includes('NoTag') && selectedCategories.includes('Notifications On')) {
                    matchesCategory = result.tag === "" && isPinNotificationsActive == true;
                }
                if (selectedCategories.includes('NoTag') && selectedCategories.includes('Notifications Off')) {
                    matchesCategory = result.tag === "" && isPinNotificationsActive == false;
                }
                if (selectedCategories.includes('NoTag') && selectedCategories.includes('Favorited')) {
                    matchesCategory = result.tag === "" && result.favorited;
                }
                if (selectedCategories.includes('NoTag') && selectedCategories.includes('Not Favorited')) {
                    matchesCategory = result.tag === "" && !result.favorited;
                }

            }

            if ((selectedCategories.length == 0) && searchQuery == companyName) {
                // no filter  - show all devices - DeviceList
                return data;
            } else if (selectedCategories.length > 0 && searchQuery == companyName) {
                // Only filtering by categories  - Devicelist
                return matchesCategory;
            } else if (selectedCategories.length > 0 && (searchQuery != null || searchQuery != "")) {
                // filtering the searchQuery and categories - PowerSearch
                return matchesSearchQuery && matchesCategory;
            } else if (selectedCategories.length == 0 && (searchQuery != null || searchQuery != "")) {
                return matchesSearchQuery;
            }

        });

        console.log(filteredResults)

        setFilteredResults(filteredResults);
    };
    //---------------------------------------------------------------------------------------------------------------------

    const [isloading, setIsLoading] = useState(false)

    const [searchQuery, setSearchQuery] = useState(" ");
    const [categoryFilter, setCategoryFilter] = useState('all');
    console.log(categoryFilter);
    const [sortBy, setSortBy] = useState('all')

    // useEffect(() => {
    //     if (searchQuery === "") {
    //         setFilteredResults([]);
    //     }
    // }, [searchQuery])

    //  ------------ used to populate the search if users uses breadcrumb to go back to Dashboard ------------------
    useEffect(() => {
        const SavedSearchQuery = sessionStorage.getItem("SavedSearchQuery");
        console.log(SavedSearchQuery)
        const SaveSearchFilters = JSON.parse(sessionStorage.getItem('SavedSearchFilters'));
        const SavedSortBy = JSON.parse(sessionStorage.getItem("SavedSearchSortOrder"))
        const loadAllDevices = sessionStorage.getItem('IsGetAllDevicesPressed')

        console.log(SavedSearchQuery);
        console.log(SaveSearchFilters);
        console.log(SavedSortBy);

        if (SavedSearchQuery != "" && SavedSearchQuery != " "){
            console.log("here")
            console.log(SavedSearchQuery)
            SavedSearchQuery != null && setSearchQuery(SavedSearchQuery);
            SaveSearchFilters != null && setCategoryFilter(SaveSearchFilters);
            SavedSortBy != null && setSortBy(SavedSortBy);
        } else {
            console.log("HERE")
            console.log(loadAllDevices)
            setIsGetAllDevicesPressed(loadAllDevices)
        }

        
    }, [aiQuery])

    const HandleSaveSearchQuery = () => {
        sessionStorage.setItem('SavedSearchFilters', JSON.stringify(categoryFilter));
        sessionStorage.setItem("SavedSearchSortOrder", JSON.stringify(sortBy))
        sessionStorage.setItem("SavedSearchQuery", searchQuery);
        sessionStorage.setItem("IsGetAllDevicesPressed", isGetAllDevicesPressed)
        setIsGetAllDevicesPressed(isGetAllDevicesPressed)
        sessionStorage.setItem("isGetAllDevicesPressed", isGetAllDevicesPressed)
    }

    useEffect(() => {
        const SavedSearchQuery = sessionStorage.getItem("SavedSearchQuery");
        const SaveSearchFilters = JSON.parse(sessionStorage.getItem('SavedSearchFilters'));
        const SavedSortBy = JSON.parse(sessionStorage.getItem("SavedSearchSortOrder"))

        SavedSearchQuery != null && setSearchQuery(SavedSearchQuery);
        SaveSearchFilters != null && setCategoryFilter(SaveSearchFilters);
        SavedSortBy != null && setSortBy(SavedSortBy);

        

        if (getAllDevices == 1) {
            
            if (SavedSearchQuery != null){
                setSearchQuery(SavedSearchQuery);
            } else {
                console.log("here")
                setIsGetAllDevicesPressed(true);
                setFetchDevices(true)
            }
        } else {
            console.log("here")
            if (SavedSearchQuery != null) {
                SavedSearchQuery != null && setSearchQuery(SavedSearchQuery);
            } else {
                setSearchQuery("")
                setFilteredResults([])
                setIsLoading(false)
            }
            
            //console.log(SavedSearchQuery)
            
            //setCategoryFilter(null)
            //setSortBy(null)
        }
    }, [getAllDevices])

    //------------------------------------------------------------------------------------------------------------------------


    //for excel export -------------------------------------------------------------------------------------------------------
    const exportData = filteredResults.map((site) => {
        console.log(site);

        return {
            "Tag": site.tag,
            "UIPIOID": site.key,
            "Serial Number": site.subGroup,
            "IP Address": site.ip,
            "Firmware": site.firmware,
            "Status": site.status,
            "Initial Activation": UseDateFormat(site.created),
            "Owned by": site.name,
        }
    })
    //---------------------------------------------


    // useEffect(() => {
    //     const currentPages = filteredResults.length / 20;
    //     console.log(currentPages);
    //     console.log(totalPages);

    //     if (currentPages < totalPages && pageFetchedIndex <= maxPagedFetches) {
    //         setFetchDevices(true);
    //     }
    // }, [totalPages, pageFetchedIndex, maxPagedFetches])



    console.log(filteredResults);


    return (
        <div className={classes.DeviceManagerPage}>
            <div className={classes.searchContainer}>
                {/* Search Bar */}
                <PowerSearch
                    placeholder={"Search Site..."}
                    searchQuery={searchQuery}
                    categoryFilter={categoryFilter}
                    setSearchQuery={setSearchQuery}
                    setIsLoading={setIsLoading}
                    setFilteredResults={setFilteredResults}
                    handleFilterLogic={handleFilterLogic}
                    categories={["Online", "Offline", "NoTag", "Favorited", "Not Favorited"]}
                    isGetAllDevicesPressed={isGetAllDevicesPressed}
                    setIsGetAllDevicesPressed={setIsGetAllDevicesPressed}
                    filteredResults={filteredResults}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    
                />

                {/* Excel Button */}
                {
                    filteredResults.length > 0 && < ExportExcel
                        excelData={exportData}
                        fileName={"Sites Filtered by:" + searchQuery}
                        title={"IPIO Filtered Sites: " + searchQuery}
                        colWidths={
                            [
                                { wch: 20 },
                                { wch: 20 },
                                { wch: 22 },
                                { wch: 13 },
                                { wch: 10 },
                                { wch: 10 },
                                { wch: 20 },
                                { wch: 20 },
                            ]
                        }
                        style={{ height: "50px", fontSize: "16px" }}
                    />
                }

                {/* Filters Button */}
                <div className={classes.filters}>
                    {categoryFilter && <CategoryFilter setCategoryFilter={setCategoryFilter} categories={["Online", "Offline", "NoTag", "Favorited", "Not Favorited"]} categoryFilter={categoryFilter} />}
                </div>

                {/* Sort By Button */}
                <SortDevices
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    categories={["Tag - Asc", "Tag - Desc", "Firmware - Asc", "Firmware - Desc", "User Count - Asc", "User Count - Desc"]}
                />

                {/* All Devices Button */}
                <GetAllDevices
                    searchQuery={searchQuery}
                    setIsLoading={setIsLoading}
                    handleFilterLogic={handleFilterLogic}
                    setFilteredResults={setFilteredResults}
                    categoryFilter={categoryFilter}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    setSearchQuery={setSearchQuery}
                    setIsGetAllDevicesPressed={setIsGetAllDevicesPressed}
                    isGetAllDevicesPressed={isGetAllDevicesPressed}
                    filteredResultsLength={filteredResults.length}
                    fetchDevices={fetchDevices}
                    setFetchDevices={setFetchDevices}
                />

                {/* <GetPagedDevices searchQuery={searchQuery}
                    setIsLoading={setIsLoading}
                    handleFilterLogic={handleFilterLogic}
                    categoryFilter={categoryFilter}
                    sortBy={sortBy}
                    setSearchQuery={setSearchQuery}
                    setIsGetAllDevicesPressed={setIsGetAllDevicesPressed}
                    isGetAllDevicesPressed={isGetAllDevicesPressed}
                    filteredResults={filteredResults}
                    setTotalPages={setTotalPages}
                    fetchDevices={fetchDevices}
                    setFetchDevices={setFetchDevices}
                    setFilteredResults={setFilteredResults}
                    setMaxPagedFetches={setMaxPagedFetches}
                    maxPagedFetches={maxPagedFetches}
                    pageFetchedIndex={pageFetchedIndex}
                    setPageFetchedIndex={setPageFetchedIndex}
                /> */}

                {/* <GetAllPagedDevices searchQuery={searchQuery}
                    setIsLoading={setIsLoading}
                    handleFilterLogic={handleFilterLogic}
                    categoryFilter={categoryFilter}
                    sortBy={sortBy}
                    setSearchQuery={setSearchQuery}
                    setIsGetAllDevicesPressed={setIsGetAllDevicesPressed}
                    isGetAllDevicesPressed={isGetAllDevicesPressed}
                    filteredResults={filteredResults}
                    setTotalPages={setTotalPages}
                    fetchDevices={fetchDevices}
                    setFetchDevices={setFetchDevices}
                    setFilteredResults={setFilteredResults}
                    setMaxPagedFetches={setMaxPagedFetches}
                    maxPagedFetches={maxPagedFetches}
                    pageFetchedIndex={pageFetchedIndex}
                    setPageFetchedIndex={setPageFetchedIndex}
                /> */}
            </div>

            <Suspense fallback={
                <div style={{ display: "flex", width: "100%" }}>
                    <DeviceLoadingGrid />
                </div>
            }>
                <Await resolve={filteredResults}>
                    {(loadedFilteredResults) =>
                        // <GridList2 data={loadedSearchResults} />
                        <div style={{ display: "flex", width: "100%" }}>
                            {isloading ?
                                <div style={{ display: "flex", width: "100%" }}>
                                    <DeviceLoadingGrid CardCount={searchQuery.length == 2 ? 10 : searchQuery.length >= 3 && 1} />
                                </div> :
                                
                                searchQuery != "" || searchQuery != " " || isGetAllDevicesPressed ?
                                    loadedFilteredResults && loadedFilteredResults.length !== 0 ?
                                        <DevicesGrid data={loadedFilteredResults} HandleSaveSearchQuery={HandleSaveSearchQuery} /> :
                                        "No sites found" :
                                    ""
                            }

                            {console.log(searchQuery)}
                            {console.log(isGetAllDevicesPressed)}
                            {console.log(loadedFilteredResults)}
                            {console.log(filteredResults)}
                            
                        </div >
                    }
                </Await>
            </Suspense>
        </div>
    )
}

export default DeviceManager