import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../../Modal/Modal';

import SelectInput from './SelectInput';
import { useDispatch, useSelector } from 'react-redux';
import SelectOutputs from './SelectOutputs';
import SelectRule from './SelectRule';
import SelectType from './SelectType';
import { setInputRuleApi } from '../../../../../Api Methods/Api';

const CreateRule = ({ allListOptionsArray, OutPutsPinNames, setShowAutomation, fetchAutomations }) => {


    console.log(allListOptionsArray);
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const DeviceUUID = useSelector(state => state.auth.DeviceUUID);
    console.log(DeviceUUID);

    const apiData = {
        token,
        client
    }

    const [modalComponent, setModalComponent] = useState(null);
    const [modalPageIndex, setModalPageIndex] = useState(0);
    //------------------------------- Modal Logic  ----------------------------------------------

    const ModalDialog = useRef();

    // call ref to clearStates method from child component ScheduledAction.
    const ClearStatesChildComponetRef = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();

        //reset states for scheduled Action
        if (ClearStatesChildComponetRef.current && ClearStatesChildComponetRef.current.HandleResetAllStates) {
            // Call the child method
            ClearStatesChildComponetRef.current.HandleResetAllStates();
        }

        HandelResetModal();
    }
    //-------------------------------------------------------------------------------------------------

    // reset model when Escape key is pressed
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                // Handle the Escape key press here
                HandelResetModal();
                setModalPageIndex(null);
            }
        };

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);


        // Clean up event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    const HandelResetModal = () => {
        setModalPageIndex(null);
        setShowAutomation(null);
    }


    const [body, setBody] = useState({
        uuid: DeviceUUID,
        input: 'null',
        inputType: "null",
        inputRule: "null",
        outputs: "null",
        active: 1 ? "1" : "0"
    })

    console.log(body);
    const [inputTypeText, setinputTypeText] = useState(null)

    useEffect(() => {
        switch (modalPageIndex) {
            case 0:
                setModalComponent(<SelectInput body={body} setBody={setBody} listItems={allListOptionsArray} outputList={OutPutsPinNames} nextPage={setModalPageIndex} />)
                HandleShowModal();
                break;
            case 1:
                setModalComponent(<SelectType body={body} setBody={setBody} setinputTypeText={setinputTypeText} listItems={allListOptionsArray} outputList={OutPutsPinNames} nextPage={setModalPageIndex} />)
                break;
            case 2:
                setModalComponent(<SelectOutputs body={body} setBody={setBody} listItems={allListOptionsArray} outputList={OutPutsPinNames} nextPage={setModalPageIndex} inputTypeText={inputTypeText} CreateRule={CreateRule} />)
                break;
            case 3:
                setModalComponent(<SelectRule body={body} setBody={setBody} listItems={allListOptionsArray} outputList={OutPutsPinNames} nextPage={setModalPageIndex} inputTypeText={inputTypeText} CreateRule={CreateRule} />)
                break;
        }
    }, [modalPageIndex]); // Empty dependency array ensures it runs only once after initial render

    const dispatch = useDispatch();

    const CreateRule = async (Body) => {
        const response = await setInputRuleApi(apiData, Body, dispatch)
        console.log(response);
        if (response.rule == 'sent') {
            HandleCloseModal();
            fetchAutomations();
        }
    }

    return (
        <>
            <Modal ref={ModalDialog} title={"Create Automation"} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} style={{ minWidth: "700px", minHeight: "420.5px" }} modalLayoutStyle={{ alignItems: "center" }}>
                {modalComponent}
            </Modal>
        </>
    )
}

export default CreateRule