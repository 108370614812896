import React, { Children, useEffect, useState, useRef } from 'react'

import classes from "./CreateVirtualSource.module.css";


import Modal from '../../../../Modal/Modal';

const CreateVirtualSource = ({ HandleSelectCamectModalForm, numInput, firmwareMajor, firmwareMinor}) => {

    //------------------------------- Modal Logic  ----------------------------------------------

    const ModalDialog = useRef();
    console.log(ModalDialog);

    console.log(firmwareMajor)

    // call ref to clearStates method from child component ScheduledAction.
    const ClearStatesChildComponetRef = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();

        //reset states for scheduled Action
        if (ClearStatesChildComponetRef.current && ClearStatesChildComponetRef.current.HandleResetAllStates) {
            // Call the child method
            ClearStatesChildComponetRef.current.HandleResetAllStates();
        }
    }
    //-------------------------------------------------------------------------------------------------

    const handleSelectCamectModalForm = (AutomationID) => {
        HandleCloseModal();
        HandleSelectCamectModalForm(AutomationID);
    }

    return (
        <>
            <Modal ref={ModalDialog} title={"Add Virtual Source"} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} style={{ minWidth: "700px", minHeight: "420.5px" }} modalLayoutStyle={{ alignItems: "center" }}>
                <div className={classes.container}>
                    <h3> Which type of source?</h3>
    
                    {/* Show Camect button only if firmware version is above or equal to 1.7 */}
                    {(firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 7)) && (
                        <button className={classes.listOption} onClick={() => handleSelectCamectModalForm(0)}>
                            <h4>Camect</h4>
                        </button>
                    )}
    
                    {/* Show Sigfox button only if firmware version is above or equal to a certain version */}
                    {numInput > 4 && (
                        <button className={classes.listOption} onClick={() => handleSelectCamectModalForm(1)}>
                            <h4>Sigfox</h4>
                        </button>
                    )}
    
                    {/* Show IOTA button only if firmware version is above or equal to a certain version */}
                    {numInput > 4 && (
                        <button className={classes.listOption} onClick={() => handleSelectCamectModalForm(4)}>
                            <h4>IOTA</h4>
                        </button>
                    )}
            
                    {/* <button className={classes.listOption} onClick={() => handleSelectCamectModalForm(2)}>
                        <h4>Smart Lock</h4>
                    </button> */}
                    
                </div>
            </Modal>
    
            <div className={classes.GroupCardContentLayout} onClick={() => HandleShowModal()}>
                <div className={`${classes.container} ${classes.rotate}`}>
                    <h2 className={classes.rotate}>+</h2>
                </div>
            </div>
        </>
    );
    
}

export default CreateVirtualSource;




