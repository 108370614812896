import { authActions } from "../../store/auth-Slice";
import { getRefreshToken } from "../Api";

const putRequest = async (uri, data, headers, dispatch) => {
    console.log("here");
    console.log(
        uri,
        data,
        headers
    );

    const response = await fetch(
        uri, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: headers.token,
            Client: headers.client,
        },
    });
    if (!response.ok) {
        console.log(response);
        // if (response.status === 401) {
        //     console.log("here");
        //     // Handle 401 error: acquire new token and retry the request
        //     const newApiData = await getRefreshToken();

        //     // if token expired and user navigates to new page - loaders cant use dispatch so reload as getRefreshToken as passed new token and refresh tokens to session storage.
        //     dispatch ? dispatch(authActions.UpdateToken(newApiData)) :
        //         window.location.reload();

        //     const newresponseData = await putRequest(uri, data, newApiData, dispatch);
        //     return newresponseData
        // }
        throw new Error('Fetching failed.');
    }

    const responseData = await response.json();
    console.log(responseData);

    // if (responseData.message == 'sent') {
    //     return true;
    // }

    if (responseData && responseData.success) {
        return responseData;
    }

    // // check that the response had authorization, if not go to catch.
    // if (responseData.success == false) {
    //     console.error(responseData.data.success);
    //     // resendOTP();
    //     throw new Error("Auth failed ");
    // }
    return responseData.data.success;
};

export default putRequest


