import React, { useState, useReducer, useRef, useEffect, useImperativeHandle, forwardRef } from "react";

import FormButton from "../../../UI/Button/FormButton/FormButton";
// src/components/UI/Button/FormButton/FormButton.js
import CustomDropDown from "../../../CustomDropDown/CustomDropDownWithIcon";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import classes from './EditPinForm.module.css';

// import { serviceActions } from "../../../store/service-Slice";
import Input from "../../../CustomDropDown/Input/Input";
import ToggleInput from "../../ToggleInput";
import Button1 from "../../Button/Button1";


import Camera from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Camera - Off.svg";
import Gate from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Gate - Closed.svg";
import Door from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Door - Closed Black.svg";
import alarm from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Alarm - Off.svg"
import Default from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/default - Off Black.svg"
import Light from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Lighting 2 - Off-90.svg"

import { clearDeviceLogs } from "../../../../Api Methods/Api";
import CustomDropDownWithIcon from "../../../CustomDropDown/CustomDropDownWithIcon";

const extraLinks = [
    {
        LinkTextBold: "Subscribe to get release emails",
    },
]

const PinNameReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.length > 0 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: null };
    }
    //otherwise 
    return { value: '', isValid: false };
}

const onStatusReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.length > 0 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: null };
    }
    //otherwise 
    return { value: '', isValid: false };
}

const offStatusReducer = (state, action) => {

    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.length > 0 };
    }

    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.length > 0 };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { value: action.val, isValid: null };
    }
    //otherwise 
    return { value: '', isValid: false };
}

// Reducer function
const switchIconReducer = (state, action) => {
    if (action.type === 'TOGGLE_DROPDOWN') {
        return { ...state, showDropDownOptions: !state.showDropDownOptions, isValid: null };
    }

    if (action.type === 'SELECT_OPTION') {
        return { ...state, selectedOption: action.payload.option, icon: action.payload.icon, showDropDownOptions: false, isValid: action.val != "SwitchIcon" };
    }

    if (action.type === 'BLUR_DROPDOWN') {
        return { ...state, showDropDownOptions: false, isValid: action.payload.option.val != "SwitchIcon" };
    }

    if (action.type === 'INPUT_CLEAR') {
        return { selectedOption: null, isValid: null };
    }
    //otherwise 
    return { state };
};

const PulseLengthReducer = (state, action) => {

    switch (action.type) {
        case 'USER_INPUT':
            const userInput = action.val;
            const userInputNumber = parseInt(userInput, 10);
            const isValidUserInput = userInput == '' || (!isNaN(userInputNumber) && userInputNumber <= 600) && userInput.length <= 3;
            return { value: isValidUserInput ? userInput : state.value, isValid: isValidUserInput };

        case 'INPUT_BLUR':
            const blurInput = state.value;
            const blurInputNumber = parseInt(blurInput, 10);
            const isValidBlurInput = blurInput == '' || (!isNaN(blurInputNumber) && blurInputNumber <= 600) && blurInput.length <= 3;
            return { value: blurInput, isValid: isValidBlurInput };

        case 'INPUT_CLEAR':
            return { value: '', isValid: null };

        default:
            return { value: '', isValid: false };
    }
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function convertSecondsToMinutesAndSeconds(seconds) {
    // if (typeof seconds !== 'number' || seconds < 0) {
    //     throw new Error('Input should be a non-negative number');
    // }
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    let minuteText;
    let SecondsText;

    if (minutes < 2) {
        minuteText = "minute";
    } else {
        minuteText = "minutes";
    }

    if (remainingSeconds < 2) {
        SecondsText = "second";
    } else {
        SecondsText = "seconds";
    }


    return `${minutes} ${minuteText} ${remainingSeconds != 0 ? "and" : ""} ${remainingSeconds != 0 ? remainingSeconds : ""} ${remainingSeconds != 0 ? SecondsText : ""}`;
}

const EditPinForm = ({ PinDetails, setNewDeviceDetails, setNewDeviceDetailsExtras }, props) => {

    const iconMap = {
        camera: Camera,
        gate: Gate,
        door: Door,
        alarm: alarm,
        default: Default,
    };

    let DeviceDetails

    //Input pins have a further InputPin proptery before being able to pull out device details, so if it's not present then the pin clicked most be a output pin.
    if (!PinDetails.InputPin) {
        DeviceDetails = PinDetails;
    } else {
        DeviceDetails = PinDetails.InputPin;
    }

    const { Pin, pinState, pinName, pinStatusOn, pinStatusOff, notificationActive, active, icon, type, length, notificationSound, lastSwitchTime, delay } = DeviceDetails;

    console.log(length);
    const [formIsValid, setFormIsValid] = useState(false);

    const [isNotificationsChecked, setIsNotificationsChecked] = useState(notificationActive);

    const [isPinActiveChecked, setIsPinActiveChecked] = useState(active);

    console.log(isPinActiveChecked);

    const [pinNameState, dispatchPinName] = useReducer(PinNameReducer, {
        value: '',
        isValid: null
    });

    const [onStatusState, dispatchOnStatus] = useReducer(onStatusReducer, {
        value: '',
        isValid: null
    });

    const [offStatusState, dispatchOffStatus] = useReducer(offStatusReducer, {
        value: '',
        isValid: null
    });

    const [switchIconState, dispatchSwitchIcon] = useReducer(switchIconReducer, {
        showDropDownOptions: false,
        selectedOption: capitalizeFirstLetter(icon.toString()), // Initial selectedOption state
        icon: iconMap[icon],
        isValid: null
    });

    const { showDropDownOptions: showSwitchIconDropDownOptions, selectedOption: selectedSwitchIconOption, icon: SwitchIcon } = switchIconState;

    const [switchType, setSwitchType] = useState(type);


    const [pulseLengthState, dispatchPulseLength] = useReducer(PulseLengthReducer, {
        value: '',
        isValid: null
    });

    const pinNameInputRef = useRef();
    const onStatusInputRef = useRef();
    const offStatusInputRef = useRef();
    const notificationsInputRef = useRef();
    const isPinActiveRef = useRef();
    const switchIconDropdownRef = useRef();
    const pulseLengthInputRef = useRef();

    const { isValid: pinNameIsValid } = pinNameState;
    const { isValid: onStatusIsValid } = onStatusState;
    const { isValid: offStatusIsValid } = offStatusState;
    const { isValid: switchIconIsValid } = switchIconState;
    const { isValid: pulseLengthIsValid } = pulseLengthState;

    useEffect(() => {
        const identifier = setTimeout(() => {
            // console.log('Checking form validity!');
            setFormIsValid(pinNameIsValid && onStatusIsValid && offStatusIsValid && switchIconIsValid)
        }, 500);

        return () => {
            // console.log('CLEANUP');
            clearTimeout(identifier);
        };
    }, [pinNameIsValid, onStatusIsValid, offStatusIsValid, switchIconIsValid]);

    const pinNameChangeHandler = (event) => {
        dispatchPinName({ type: 'USER_INPUT', val: event.target.value });
        // console.log(event.target.value)
    }

    const onStatusChangeHandler = (event) => {
        dispatchOnStatus({ type: 'USER_INPUT', val: event.target.value });
        // console.log(event.target.value)
    }

    const offStatusChangeHandler = (event) => {
        dispatchOffStatus({ type: 'USER_INPUT', val: event.target.value });
        // console.log(event.target.value)
    }

    const validatePulseLengthHandler = () => {
        dispatchPulseLength({ type: 'INPUT_BLUR' });
    }

    const validatePinNameHandler = () => {
        dispatchPinName({ type: 'INPUT_BLUR' });
    }

    const validateOnStatusHandler = () => {
        dispatchOnStatus({ type: 'INPUT_BLUR' });
    }

    const validateOffStatusHandler = () => {
        dispatchOffStatus({ type: 'INPUT_BLUR' });
    }

    const pulseLengthChangeHandler = (event) => {
        dispatchPulseLength({ type: 'USER_INPUT', val: event.target.value });
        // console.log(event.target.value)
    }

    // Drop down handlers ---------------

    const handleSwitchIconDropDownOnClick = () => {
        console.log("here");
        dispatchSwitchIcon({ type: 'TOGGLE_DROPDOWN' });
    };

    const handleSwitchIconOptionChange = (option, icon) => {
        dispatchSwitchIcon({ type: 'SELECT_OPTION', payload: { option, icon } });
    };
    const handleSwitchIconBlur = () => {
        console.log("here");
        dispatchSwitchIcon({ type: 'BLUR_DROPDOWN' });
    };

    const onClearHandler = () => {
        dispatchPinName({ type: 'INPUT_CLEAR', val: "" })
        dispatchOnStatus({ type: 'INPUT_CLEAR', val: "" })
        dispatchOffStatus({ type: 'INPUT_CLEAR', val: "" })
        dispatchPulseLength({ type: 'INPUT_CLEAR', val: "" })
        // dispatchSwitchIcon({ type: 'SELECT_OPTION', payload: { option: capitalizeFirstLetter(icon.toString()), icon: iconMap[icon], } })
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    console.log(icon);
    console.log(switchIconState.selectedOption);



    const submitHandler = (event) => {
        event.preventDefault();

        if (true) {

            const PinName = pinNameState.value || pinName;
            const onStatus = onStatusState.value || pinStatusOn;
            const offStatus = offStatusState.value || pinStatusOff;
            const notificationsChecked = isNotificationsChecked ? 1 : 0;
            const pinActive = isPinActiveChecked ? 1 : 0;
            const switchIcon = switchIconState.selectedOption || icon;
            const SwitchType = switchType || type;
            const PulseLength = (parseInt(pulseLengthState.value) || length);

            // console.log("PinName: " + PinName, "OnStatus: " + onStatus, "offStatus: " + offStatus, "NotificationsChecked: " + notificationsChecked, "PinActive: " + pinActive, "SwitchIcon: " + switchIcon, "SwitchType: " + SwitchType);
            const DeviceDetails = {
                [Pin]: {
                    "notificationActive": notificationsChecked, //
                    "pinState": pinState,
                    "delay": delay,
                    "lastSwitchTime": lastSwitchTime,
                    "notificationSound": notificationSound,
                    "length": PulseLength,
                    "active": pinActive, //
                    "pinName": PinName, //
                    "pinStatusOff": offStatus,//
                    "type": SwitchType, //
                    "pinStatusOn": onStatus  //
                }
            }

            const DeviceDetailsExtra = {
                [Pin]:
                {
                    "icon": switchIcon.toLowerCase() + ".png" //icon name .png
                },
            }
            console.log((pinNameState.value != "" && pinNameState.value != pinName));
            console.log((onStatusState.value != "" && onStatusState.value != pinStatusOn));
            console.log((offStatusState.value != "" && offStatusState != pinStatusOff));
            console.log((notificationsChecked != notificationActive));
            console.log((isPinActiveChecked != active));
            console.log(switchIconState.selectedOption != "" && switchIconState.selectedOption.toLowerCase() != icon);
            console.log(switchIconState.selectedOption.toLowerCase());
            console.log((switchType != type));
            console.log((pulseLengthState.value) != "");



            if ((pinNameState.value != "" && pinNameState.value != pinName) ||
                (onStatusState.value != "" && onStatusState.value != pinStatusOn) ||
                (offStatusState.value != "" && offStatusState != pinStatusOff) ||
                (notificationsChecked != notificationActive) ||
                (isPinActiveChecked != active) ||
                (switchType != type) ||
                (pulseLengthState.value) != "") {
                setNewDeviceDetails(DeviceDetails);
            }

            if (switchIconState.selectedOption != "" && switchIconState.selectedOption.toLowerCase() != icon) {
                setNewDeviceDetailsExtras(DeviceDetailsExtra);
            }

            // onClearHandler() ;#c8f8c8

            return;
        } else if (!pinNameIsValid) {
            pinNameInputRef.current.focus();
        } else if (!onStatusIsValid) {
            onStatusInputRef.current.focus(); // Corrected from !onStatusState
        } else if (!offStatusIsValid) {
            offStatusInputRef.current.focus();
        } else if (!switchIconIsValid) {
            switchIconDropdownRef.current.focus();
        } else if (!pulseLengthIsValid) {
            pulseLengthInputRef.current.focus();
        }
    };

    console.log(pulseLengthState.value);

    let [PulseLength, setPulseLength] = useState(length)
    useEffect(() => {
        console.log(pulseLengthState.value);
        if (pulseLengthState.value == "") {
            setPulseLength(length);
        } else {
            setPulseLength(pulseLengthState.value);
        }

    }, [pulseLengthState.value])

    // Example usage:
    const seconds = 61;

    if (PulseLength <= 60) {
        PulseLength = PulseLength + "s";
    } else if (PulseLength > 60) {
        PulseLength = PulseLength + "s" + " ( " + convertSecondsToMinutesAndSeconds(PulseLength) + ")";
    }

    return (
        <form onSubmit={submitHandler} className={classes["container"]} method="dialog" >
            <h2 className={classes.Title}>{"Edit: " + pinName || "Edit Pin"}</h2>
            <ul className={classes["inputs-list"]}>
                <li className={classes["input-container"]}>
                    <label className={classes.subTitle} >Name</label>
                    <Input
                        className={classes.input}
                        placeholder={pinName || "Enter Pin Name..."}
                        ref={pinNameInputRef}
                        id="PinName"
                        type={"text"}
                        isValid={pinNameState.isValid}
                        value={pinNameState.value}
                        onChange={pinNameChangeHandler}
                        onBlur={validatePinNameHandler}
                    />
                </li>

                <li className={classes["input-container"]}>
                    <label className={classes.subTitle}>On Status</label>
                    <Input
                        className={classes.input}
                        placeholder={pinStatusOn || "Enter On Status..."}
                        ref={onStatusInputRef}
                        id="OnStatus"
                        type={"text"}
                        isValid={onStatusState.isValid}
                        value={onStatusState.value}
                        onChange={onStatusChangeHandler}
                        onBlur={validateOnStatusHandler}
                    />
                </li>

                <li className={classes["input-container"]}>
                    <label className={classes.subTitle} >Off Status</label>
                    <Input
                        className={classes.input}
                        placeholder={pinStatusOff || "Enter Off Status..."}
                        ref={offStatusInputRef}
                        id="offStatus"
                        type={"offStatus"}
                        isValid={offStatusState.isValid}
                        value={offStatusState.value}
                        onChange={offStatusChangeHandler}
                        onBlur={validateOffStatusHandler}
                    />
                </li>
                
                <ToggleInput
                    label={"Notifications"}
                    ref={notificationsInputRef}
                    type="checkbox"
                    isChecked={isNotificationsChecked}
                    setIsChecked={setIsNotificationsChecked}
                />

                <ToggleInput
                    label={"Active"}
                    ref={isPinActiveRef}
                    type="checkbox"
                    isChecked={isPinActiveChecked}
                    setIsChecked={setIsPinActiveChecked}
                />

                <li className={classes["input-container"]}>
                    <label className={classes.subTitle}>Switch Icon</label>
                    <CustomDropDownWithIcon
                        style={{ marginTop: "30px" }}
                        placeholder={"Door"}
                        options={['Camera', 'Gate', 'Door', 'Alarm', 'Default']}
                        icons={iconMap}
                        selectedOption={selectedSwitchIconOption}
                        selectedIcon={SwitchIcon}
                        handleOptionChange={handleSwitchIconOptionChange}
                        handleBlur={handleSwitchIconBlur}
                        handleDropDownOnClick={handleSwitchIconDropDownOnClick}
                        showDropDownOptions={showSwitchIconDropDownOptions}
                        isValid={switchIconIsValid}
                        ref={switchIconDropdownRef}
                    />
                </li>

                {/* Only show Switch Type Section if pin click was a output */}
                {!PinDetails.InputPin && <li className={classes["input-container"]}>
                    <label className={classes.subTitle} >Switch Type</label>
                    <div style={{ display: "flex", gap: "20px", alignItems: "center" }} >
                        <Button1 type="button" style={{ backgroundColor: switchType == "LATCH" && "var(--Primary-Color)", color: switchType == "LATCH" && "white" }} onClick={() => setSwitchType("LATCH")} >Latch</Button1>
                        <Button1 type="button" style={{ backgroundColor: switchType == "PULSE" && "var(--Primary-Color)", color: switchType == "PULSE" && "white" }} onClick={() => setSwitchType("PULSE")}> Pulse</Button1>

                        {/* Pulse Length Input */}

                        {switchType == "PULSE" && <h3 style={{ fontWeight: "400", fontSize: "15px" }}>{PulseLength}</h3>}

                    </div>
                    {switchType == "PULSE" && <Input
                        className={classes.input}
                        placeholder={"Enter pulse length in seconds..."}
                        ref={pulseLengthInputRef}
                        id="pulseLengthInput"
                        type={"text"}
                        isValid={pulseLengthState.isValid}
                        value={pulseLengthState.value}
                        onChange={pulseLengthChangeHandler}
                        onBlur={validatePulseLengthHandler}
                    />}
                </li>}



                <button className={classes.submitButton} type="submit">Update Pin</button>
            </ul>

        </form>


    )


}

export default EditPinForm;

