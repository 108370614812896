import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL = 'https://ipio.m2mservices.mobi';

// By default Socket.IO client opens a connection to the server right away
// export const socket = io(URL);

export const socket = io(URL, {
    autoConnect: true,
});
