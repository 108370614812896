import React, { Suspense, useEffect, useState } from 'react'
import classes from "./LogsTableLayout.module.css";
import Card from "./Card";
import Table from './Table/Table';
import tableClasses from './Table/EventsTable.module.css';

import { Await, useLoaderData, useNavigate } from 'react-router-dom';
import { Oval, Bars, ThreeDots } from 'react-loading-icons';


import Modal from '../Modal/Modal';

import alarm from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Alarm - Off.svg"
import cameraOn from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Camera - On.svg"
import cameraOff from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Camera - Off.svg"
import gateOpen from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Gate - Closed.svg"
import gateClosed from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Gate - Open.svg"
import doorOpen from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Door - Open Black.svg"
import doorClosed from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Door - Closed Black.svg"
//import pulsed from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Switch - Pulse.svg"
import defaultOn from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/__Sensor - On.svg"
import defaultOff from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/__Sensor - Off.svg"

import armed from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/armed.png"
import disarmed from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/disarmed.png"
import pulsed from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/pulsed.png"
import locked from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/locked.png"
import unlocked from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/unlocked.png"
import otp from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/OTP.png"
import input_on from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/input_on.png"
import input_off from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/input_off.png"
import update from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/update.png"
import add from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/add.png"
import added from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/added.png"
import used_otp from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/used_otp.png"
import unlocked_unopened from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/unlocked_unopened.png"
import delete_pin from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/delete_pin.png"
import deleted from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/deleted.png"
import incorrect_pin from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/incorrect_pin.png"
import linked from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/linked.png"
import unlinked from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/unlink.png"
import invited from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/invited.png"
import offline from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/offline.png"
import online from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/online.png"
import not_received from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/schedule_failed.png"

import EventsTable from './Table/EventsTable';
import Export from '../UI/Cards/Logs Cards/Export/Export';
import BasicTable from './Table/Table';
import Button1 from '../UI/Button/Button1';
import SearchbarSmall from '../UI/Inputs/SearchbarSmall';
import { useSelector } from 'react-redux';
import ExportExcel from '../ExcelExport/ExcelExport';
import LinegraphPeaked from '../UI/Graphs/linegraphPeaked';


const LogsTableLayout = ({ data, isloading, Filtering }) => {
  // console.log("here");
  // console.log(data);


  // let DeviceDetailsExtras = useSelector(state => state.service.DeviceDetailsExtras);
  // console.log(DeviceDetailsExtras);
  //const OutPutPins = [5, 6, 13, 19];


  // const outputIcons = OutPutPins.reduce((acc, OutPutPin) => {
  //   console.log(OutPutPin);
  //   acc[OutPutPin] = DeviceDetailsExtras[OutPutPin].icon;
  //   return acc;
  // }, {});

  //console.log(outputIcons);


  // const FormattedArrayOfIcons = Object.keys(outputIcons).reduce((acc, key) => {
  //   let icon = DeviceDetailsExtras[key].icon;
  //   if (icon.includes('.png')) {
  //     icon = icon.slice(0, -4);
  //   }
  //   acc[key] = icon;
  //   return acc;
  // }, {});

  // const iconMapping = {
  //   alarm,
  //   cameraOn,
  //   cameraOff,
  //   gateOpen,
  //   gateClosed,
  //   doorOpen,
  //   doorClosed,
  //   pulsed,
  //   defaultOn,
  //   defaultOff
  // }

  const iconMapping = [
    armed,
    disarmed,
    pulsed,
    locked,
    unlocked,
    otp,
    input_on,
    input_off,
    update, 
    add, 
    added, 
    used_otp, 
    unlocked_unopened, 
    delete_pin, 
    deleted, 
    incorrect_pin, 
    linked, 
    unlinked, 
    invited, 
    offline, 
    online, 
    not_received
  ]


  let tableData;

  // debugger;
  if (data == undefined) {
    tableData = [];
  } else if (data && data.eventData) {
    tableData = data.eventData
  } else {
    tableData = data
  }

  //console.log(tableData);

  // console.log(EventData.reverse());
  // EventData = EventData.reverse();
  // const reversedArray = EventData.reverse();
  // console.log(reversedArray);
  // console.log(data[0].icon);

  const tablecolumns = [
    {
      header: "Event ID",
      isIndex: true,
      cell: (row) => {
        console.log(row.row.index);
        return row.row.index + 1

      }
    },
    {
      header: 'Time',
      accessorKey: 'eventTime',
      cell: (row) => {
        console.log(row.row.original);
        const type = row.row.original.type
        const subType = row.row.original.subType;
        const eventData = row.row.original.eventData;

        let iconPath;

        if (type === "Lock" || type === "IglooEvent"){
          if (subType === "Unlock"){
            iconPath = iconMapping[4]
          } else if (subType === "OPEN_CLOSE_LOCKED") {
            iconPath = iconMapping[3]
          } else if (subType === "OTP" || subType === "BLE_PIN_CREATED"){
            iconPath = iconMapping[5]
          } else if (subType === "OTP_UNLOCK" || subType === "PIN_UNLOCK" || subType === "FIRST_DURATION_UNLOCK" || subType === "FIRST_PERMANENT_UNLOCK"){
            iconPath = iconMapping[11]
          } else if (subType === "UNOPENED_LOCKED"){
            iconPath = iconMapping[12]
          } else if (subType === "DELETE_PIN"){
            iconPath = iconMapping[13]
          } else if (subType === "INCORRECT_PIN"){
            iconPath = iconMapping[15]
          } else if (subType === "LINK"){
            iconPath = iconMapping[16]
          } else if (subType === "UNLINK"){
            iconPath = iconMapping[17]
          }
        } else if (type === "Input") {
          if (subType === "Armed"){
            iconPath = iconMapping[6]
          } else {
            iconPath = iconMapping[7]
          }
        } else if (type === "output") {
          if (subType === "Armed"){
            iconPath = iconMapping[0]
          } else if (subType === "Disarmed"){
            iconPath = iconMapping[1]
          } else if (subType === "pulsed"){
            iconPath = iconMapping[2]
          }
        } else if (subType === "update" || subType === "Link"){
          iconPath = iconMapping[8]
        } else if (subType === "add" || subType === "Set" || subType === "Add" || subType === "Added"){
          iconPath = iconMapping[9]
        } else if (subType === "added"){
          iconPath = iconMapping[10]
        } else if (subType === "delete" || subType === "removed" || subType === "Delete"){
          iconPath = iconMapping[14]
        } else if (subType === "LINK"){
          iconPath = iconMapping[16]
        } else if (subType === "invited"){
          iconPath = iconMapping[18]
        } else if (subType === "offline"){
          iconPath = iconMapping[19]
        } else if (subType === "online"){
          iconPath = iconMapping[20]
        } else if (type === "ScheduledAction"){
          iconPath = iconMapping[21]
        }


        // const Pin = row.row.original.pin

        // let iconPath;
        // const status = subType == "Armed" ? "Open" : "Closed"
        // console.log(FormattedArrayOfIcons[Pin]);
        // if (FormattedArrayOfIcons[Pin] == undefined) {

        //   iconPath = subType == "Armed" ? defaultOn : defaultOff
        // } else if (FormattedArrayOfIcons[Pin] == 'camera') {
        //   if (subType == "pulsed") {

        //     iconPath = pulsed;
        //   } else {

        //     const status = subType == "Armed" ? "On" : "Off"
        //     iconPath = iconMapping[FormattedArrayOfIcons[Pin] + status]
        //   }
        // } else if (FormattedArrayOfIcons[Pin] == 'alarm') {

        //   iconPath = iconMapping[FormattedArrayOfIcons[Pin]]
        // } else if (FormattedArrayOfIcons[Pin] == 'gate') {

        //   const status = subType == "Armed" ? "Open" : "Closed"
        //   console.log(FormattedArrayOfIcons[Pin] + status);
        //   iconPath = iconMapping[FormattedArrayOfIcons[Pin] + status]
        // } else if (FormattedArrayOfIcons[Pin] == 'door') {

        //   // const status = subType == "Armed" ? "Open" : "Closed"
        //   const status = eventData.includes("Open") || eventData.includes("Unlocked") ? "Open" : "Closed"

        //   console.log(FormattedArrayOfIcons[Pin] + status);
        //   iconPath = iconMapping[FormattedArrayOfIcons[Pin] + status]
        // }


        // //get icon
        // iconText = FormattedArrayOfIcons[2];

        // // adds On || Off to icon text
        // fullIconText = iconText + (subType == "Armed" ? "On" : "Off")
        // //get path to icon
        // iconPath = iconMapping[fullIconText];


        //backgroundColor: subType == "Armed" ? "green" : "red", - taken from  <div style={{ width: "15px", height: "15px", borderRadius: "50%" }} />

        return (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div style={{ width: "15px", height: "15px", borderRadius: "50%" }} />
            <img className={classes.img} src={iconPath} />
            {row.row.original.eventTime}
          </div>
        )
      }
    },
    {
      header: 'Activity',
      accessorKey: 'eventData',
      cell: (row) => {
        console.log(row.row.original);

        const subType = row.row.original.subType;
        const eventData = row.row.original.eventData;
        const recipientName = row.row.original.recipientName;
        let rowText;
        switch (subType) {
          case 'removed':
            rowText = recipientName + " " + subType
            break;
          case 'added':
            rowText = recipientName + " " + subType
            break;
          case 'changeRole':
            rowText = recipientName + "'s " + eventData;
            break;
          case "OPEN_CLOSE_LOCKED":
            rowText = eventData + " relocked";
            break;
          case "OTP_UNLOCK":
            rowText = eventData + " unlocked with OTP";
            break;
          case "PIN_UNLOCK":
            rowText = eventData
            break;
          case "MASTER_PIN_UNLOCK":
            rowText = eventData + " unlocked with master PIN";
            break;
          case "UNOPENED_LOCKED":
            rowText = eventData + " unlocked but not opened";
            break;
          case "INCORRECT_PIN":
            rowText = "Incorrect PIN was attempted on " + eventData;
            break;
          case "Unlock":
            rowText = eventData + " unlocked";
            break;
          case "DELETE_PIN":
            rowText = "Pincode deleted from " + eventData;
            break;
          case "FIRST_DURATION_UNLOCK":
            rowText = "Unlocked using Duration PIN"
            break;
          case "BLE_PIN_CREATED":
            rowText = "BLE Pin created for " + eventData
            break;
          default:
            rowText = eventData;
        }


        return rowText
      }
    },
    {
      header: 'User',
      accessorKey: 'user',
    }
  ];


  const eventDate = {
    header: "Event Date",
    accessorKey: 'eventDate',
  }

  if (Filtering != "") {
    // Insert the new object at the specified index
    tablecolumns.splice(1, 0, eventDate);
  }
  // show data 


  // let tableData = EventData || [];

  const [filtering, setFiltering] = useState('');








  const singleDeviceHeaderData = useSelector(state => state.service.singleDeviceHeaderData);

  console.log(singleDeviceHeaderData.key);

  const [exportData, setExportData] = useState()

  console.log(Filtering);

  // useEffect(() => {
  //   if (tableData != [] && Filtering == "") {
  //     const tempExportData = tableData.map((event) => {
  //       const newObj = {
  //         Date: event.eventDate,
  //         Time: event.eventTime,
  //         Acivity: event.eventData,
  //         User: event.user
  //       }
  //       return newObj
  //     })
  //     setExportData(tempExportData);
  //   }
  // }, [data, Filtering])

  const exportFormatFunction = (event) => {
    return {
      Date: event.eventDate,
      Time: event.eventTime,
      Acivity: event.eventData,
      User: event.user
    };
  };

  console.log(filtering);
  console.log(exportData);


  const key = singleDeviceHeaderData.key;
  return (
    <div className={classes.table}>
      <div className={classes["tableCardLayoutUI-wrapper"]}>
        <div className={classes["top-wrapper"]}>
          <div className={classes["info-bar"]}>
            <>
              <h1>Events:</h1>

              {Filtering == "" ?
                isloading ? (
                  <div style={{ paddingLeft: "10px" }}><ThreeDots stroke="var(--Primary-Color)" height={50} width={40} speed={1.5} /></div>
                ) : (
                  (data && data.eventDate && data.eventData) ? (
                    data.eventDate + " | " + data.eventData.length
                  ) : (
                    "No events found"
                  )
                ) : "Searching Month Events"}


              {/* {isloading ? (
                  <ThreeDots stroke="var(--Primary-Color)" height={50} width={25} speed={1.5} />
                ) : (
                  (data && data.eventDate && data.eventData) ? (
                    data.eventDate + " | " + data.eventData.length
                  ) : (
                    "No events found"
                  )
                )} */}
            </>
          </div>
          <ExportExcel
            excelData={exportData}
            fileName={"Events For:" + key}
            title={"IPIO Portal-Events: " + key}
            subTitle={"Filtered by: " + Filtering}
            colWidths={
              [
                { wch: 15 },
                { wch: 15 },
                { wch: 40 },
                { wch: 30 },
              ]
            }
          />
          {/* <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <SearchbarSmall
              type="text"
              placeholder={"Search Events for June..."}
              value={filtering}
              onChange={(e) => setFiltering(e.target.value)}
              onClear={() => setFiltering("")}
            />
          </div> */}
        </div>

        {isloading ? <div className={classes["loadingSpinner-container"]}>  <ThreeDots stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} /> </div>
          :
          <BasicTable data={tableData} columns={tablecolumns} filtering={Filtering} rowClick={false} ShowPagination={true} PageSize={10} paginationOptions={[10, 20, 30]} tableClasses={tableClasses} setExportData={setExportData} exportFormatFunction={exportFormatFunction} />}
      </div>
    </div>



  )
}

export default LogsTableLayout;