import React, { Children, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChangeoutputPinState } from "../../../../../../store/service-actions"
import Card from "../../../../Cards/Card";

import classes from "./PinOutput.module.css";


import InactiveIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/Pin/default - Off.svg";
import HiddenIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/HiddenPin.svg";

import pulseIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/Pin/__Switch - Pulse.svg";

import LockIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Locked.svg"
import { switchLatch } from "../../../../../../Api Methods/Api";

import Battery from "../../../../../Battery/Battery";
import UseDateFormat from "../../../../../hooks/UseDateFormat";
import UseParseYearMonthDate from "../../../../../hooks/UseParseYearMonthDate";

const PinOutput = ({ OutPutPin, polarity, SmartLock }) => {
    // console.log("PinOutput");
    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const userID = useSelector(state => state.auth.userID);

    const { pinName, pinStatusOff, pinStatusOn, pinState, icon, DeviceUUID, Pin, active, type, DeviceType } = OutPutPin;
    console.log(SmartLock);
    console.log(pinState);

    console.log(icon);
    const lowerCaseStringIcon = icon.toLowerCase();
    const image = lowerCaseStringIcon + " - Off";
    const otherStateImage = icon + " - On";
    const staticPath = "/assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/";

    const imageFromPath = `${process.env.PUBLIC_URL}${staticPath}${image}.svg`
    let otherStateImageFromPath = `${process.env.PUBLIC_URL}${staticPath}${otherStateImage}.svg`;

    const initialPinState = polarity === 1 ? pinState : !pinState;

    const [PinState, setPinState] = useState();
    const [pinStateLoading, setPinStateLoading] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false)

    console.log("pinState: " + pinState);
    console.log("initialPinState: " + initialPinState);
    console.log("PinState: " + PinState);

    useEffect(() => {
        //if external source updates the state, update PinState to reflect that
        setPinState(initialPinState);
    }, [initialPinState]);

    const HandlePinClick = async () => {
        setPinStateLoading(true);
        setButtonClicked(true);
        console.log("Toggling Status");

        const apiData = {
            token,
            client,
        }

        // setPinState(!PinState);

        console.log(userID);

        const response = await switchLatch(apiData, userID, Pin, !PinState, DeviceUUID, type, DeviceType, dispatch)
        console.log(response);
        if (response) {
            setPinState(!PinState);
            setPinStateLoading(false);
            setButtonClicked(false);
        } else {
            setPinState(PinState);
            setButtonClicked(false);
        }

    }

    let LockReadDate;
    if (SmartLock != null) {
        const batteryReadAt = SmartLock && SmartLock.batteryReadAt
        LockReadDate = UseParseYearMonthDate(batteryReadAt);
        console.log(LockReadDate);
    }

    return (
        <button className={`${classes["pin-switch"]} ${!active && classes["disabled-hover"]}`} style={{ opacity: !active && "37%", backgroundColor: SmartLock != null && "var(--PinLockColor)" }} disabled={buttonClicked || SmartLock != null} onClick={HandlePinClick}>
            {!active &&
                <div className={classes["overlay"]}>
                    {/* <div className={classes.line} />
                    <div className={classes.line2} />
                    <img className={classes.HiddenIcon} src={HiddenIcon}></img> */}
                </div>}

            {/* {SmartLock != null && <div className={classes["smart-lockOverlay"]} />} */}

            <div className={classes.top}>
                <h3>{pinName}</h3>
                {SmartLock == null ? <h3 className={!pinStateLoading ? active ? PinState ? classes.On : classes.Off : classes.inactive : classes.loading}>
                    {active ? PinState ? pinStatusOff : pinStatusOn : "Inactive"}
                </h3> :
                    <h2 className={classes["battery-percentage"]}> {LockReadDate}</h2>
                }
            </div>

            {SmartLock != null ?
                <div className={classes.bottom}>
                    <img className={classes.LockIcon} src={LockIcon} />

                    <div className={classes.batteryIconContainer}>
                        <Battery BatteryPowerLevel={SmartLock.battery} isActive={active} />
                        <h2 className={classes["battery-percentage"]}> {SmartLock.battery + "%"}</h2>
                    </div>
                </div>
                :
                <div className={classes.bottom}>
                    <img className={classes.img} src={active ? PinState ? imageFromPath : otherStateImageFromPath : InactiveIcon} />
                </div>
            }


        </button>
    );
}

export default PinOutput;