import React, { useState } from 'react';
import classes from './DropdownSelect.module.css'; // Import CSS module


import image from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/__Drop Down menu.svg'

import icon from '../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Page - Forward.svg'


const DropdownSelect = ({ options, selectedOption, onSelect, placeholder }) => {
  console.log(selectedOption);
  console.log(options);


  //if the selected monthIndex is greater than 5 - subtract the reminder to bring the monthIndex within the bounds of the array.
  if (selectedOption > 5) {
    const subtrackNum = selectedOption % 5;
    selectedOption = selectedOption - subtrackNum
  }

  // State to track the selected value
  const [selectedValue, setSelectedValue] = useState(options[selectedOption] || '');

  // Function to handle change in select value
  const handleChange = (month) => {
    const MonthIndex = options.indexOf(month);
    console.log(MonthIndex);
    setSelectedValue(month);

    // Call the onSelect callback with the selected option

    //if 'January' is within the the first position within the the options array dont add one to the selected month index.
    if (options[0] === 'January') {
      onSelect(month, MonthIndex);
    } else {
      onSelect(month, MonthIndex + 1);
    }

    setRotation(rotation + -90); // Increment the rotation by 20 degrees
    setToggleDropDown(false);

  };

  const [toggleDropDown, setToggleDropDown] = useState(false);
  const [rotation, setRotation] = useState(0);

  const OnDropDownButtonClick = () => {
    setToggleDropDown(!toggleDropDown);

    if (toggleDropDown) {
      setRotation(rotation + -90); // Increment the rotation by 20 degrees
    } else {
      setRotation(rotation + 90); // Increment the rotation by 20 degrees
    }

  };


  return (
    <div className={classes.customDropdown}>
      <div className={classes.selectedOption} onClick={OnDropDownButtonClick}>
        <h3>{selectedValue || placeholder}</h3>
      </div>
      {toggleDropDown && (
        <ul className={classes.dropdownOptions}>
          {options.map((month, index) => {
            console.log(index);
            console.log(month);
            return (
              <li key={index} onClick={() => handleChange(month, index)}>
                {month}
              </li>
            );
          })}
        </ul>
      )
      }
      <img
        className={classes.dropdownArrowImage}
        alt="arrow"
        src={icon}
        id="rotateImage"
        style={{ transform: `rotate(${rotation}deg)`, transition: 'transform .3s' }}
        onClick={OnDropDownButtonClick}
      />
    </div >
  );
};

export default DropdownSelect;
