
const uri = "https://ipio.m2mservices.mobi";
// const uri = "https://betaipio.m2mservices.mobi";

export const ApiEndpoints = {

    // Login
    loginAPI: uri + "/api/v3.0/portal", // Done
    twoFactorAPI: uri + "/api/v3.0/verifyTwoFactor", // Done
    refreshTokenAPI: uri + "/api/v2.0/refreshToken", // Created but not pluged in, in postman returns   "token": "","refreshToken": ""  
    resendOTPAPI: uri + "/api/v2.0/resendOTP",  //  Created but not pluged in , the verification code is 0 and does not work
    resetPortalPasswordAPI: uri + "/api/v2.0/resetPassword", // Done (Login Reset Password Modal) not tested.

    getDevicesAPI: uri + "/api/v3.0/deviceList/", // Done
    getStatsAPI: uri + "/api/v3.0/stats/",  // Done
    getEventsAPI: uri + "/api/v1.2/events/",  // Getting for the Line Graph in SiteView  - graph needs styling
    getUsersAPI: uri + "/api/v1.2/deviceAllUsers/", // Getting in SiteView  - table needs update to table used in Dashboard with further styling for particualr cells
    getSingleDeviceAPI: uri + "/api/v4.0/portal/device/", // Done
    getSensorDataAPI: uri + "/api/v2.0/deviceEventList/", // Created but not plugged in, returns : data : [] in postman.
    getActivityLogAPI: uri + "/api/v2.0/portalLogins", //  ready to go.
    getInactiveDevicesAPI: uri + "/api/v2.0/portal/inactiveDevices", // ready to go.
    getCompanyUserListAPI: uri + "/api/v2.0/userList/", // ready to go.
    getUsersDevicesAPI: uri + "/api/v2.0/portal/devices/", //  ready to go.

    switchLatchAPI: uri + "/socketio/deviceState", // Done
    switchVirtualLatchAPI: uri + "/api/v2.0/virtual/deviceState",  // Done
    switchLatchExternalLinkAPI: uri + "/socketio/virtualDeviceState",  // Done

    switchPulseAPI: uri + "/socketio/pulse",  // Done
    switchVirtualPulseAPI: uri + "/socketio/virtualPulse",  // Done

    setTagAPI: uri + "/api/v1.1/tag/", // Done

    changeRoleAPI: uri + "/api/v2.0/changeRole", // Done
    removeUserAPI: uri + "/api/v1.2/removeUser/",  // Done but not tested. Uncomment it in API to test.

    suspendAPI: uri + "/api/v2.0/suspendDevice/", // Done but no immediate feedback.
    clearLogsAPI: uri + "/api/v1.2/clearLogs/",
    changeOwnerAPI: uri + "/api/v2.0/changeDeviceOwner", // Done
    copySettingsAPI: uri + "/api/v2.0/portal/copyDeviceSettings",
    changeCamectIntegrationAPI: uri + "/socketio/setCamectIntegrationType", // Done
    rebootIPIOAPI: uri + "/socketio/restartIPIO/",
    resetDeviceAPI: uri + "/api/v2.0/portal/factoryReset/",

    copyUsersAPI: uri + "/api/v2.0/duplicateRelationships",
    inviteUserAPI: uri + "/api/v1.2/inviteUser", // Done

    changeAccountPasswordAPI: uri + "/api/v2.0/changePassword",

    createPortalPasswordAPI: uri + "/api/v1.0/createPortalPassword", // Done not tested.
    createAppAccountAPI: uri + "/api/v1.0/acceptInvite", // create in api, not implemented.

    //Search
    powerSearch: uri + "/api/v3.0/portal/search/",
    userPowerSearch: uri + "/api/v3.0/portal/usersearch/",
    pagedDevices: uri + "/api/v3.0/portal/devices/paged",
    activateInstallerModeAPI: uri + "/api/v2.0/installerMode", /* ?  */
    deleteDeviceAPI: uri + "/api/v2.0/deleteDevice/",
    enableUserNotificationsAPI: uri + "/api/v2.0/userNotifications",  //Done

    // Automations


    // HolidayRule
    getHolidaysModes: uri + "/api/v3.0/holidays/", // + {uuid}   //Done
    createHolidayMode: uri + "/api/v3.0/holidays",
    deleteHolidayMode: uri + "/api/v3.0/holidays/",  // + {id}
    editHolidayMode: uri + "/api/v3.0/holidays",

    // Get Rules
    getInputRules: uri + "/socketio/getInputRules/", // + {deviceUUID}
    setInputRule: uri + "/socketio/setInputRule",

    //Get Schedules
    getSchedules: uri + "/api/v3.0/schedules/", // + {deviceUUID}

    //update Device Details
    updateDeviceDetails: uri + "/api/v1.0/device/", // + {deviceID}
    updateDeviceDetailsExtra: uri + "/api/v2.0/updateDeviceExtras/", // {deviceID}

    //Virtual Sources

    //Generate OTP for lock assigned to pin
    generateLockOTP: uri + "/api/v4.0/igloo/pincode",
    removeLock: uri + "/api/v3.0/igloo/lock/relationship/",  // + {deviceUUID}/{outputPin}

    //camect
    getCamects: uri + "/socketio/getCamectsAndRelationships/", // + {uuid}
    addCamect: uri + "/socketio/addCamect",
    deleteCamect: uri + "/socketio/deleteCamect/", // + {uuid}/{id}
    updateCamect: uri + "/socketio/updateCamect",
    updateCamectRelationship: uri + "/socketio/updateCamectRelationship/",
    getCamectCameras: uri + "/socketio/getCamectCameras/", //+{uuid}/{camectID}

    //SigFox - Sensors
    getSigFoxSensors: uri + "/api/v2.0/virtual/relationships/",  // + {uuid}
    addSigfox: uri + "/api/v2.0/addSigfoxRelationship/",
    unlinkSigfox: uri + "/api/v2.0/virtual/deleteRelationship/", // + {deviceUUID}/{pin}

    sendMessage: "http://localhost:3001/sendMessage",
    performAction: "http://localhost:3001/performAction"
}

/* 


export const ApiEndpoints = {
    loginAPI: uri + "/api/v1.0/portal",  // Done
    refreshTokenAPI: uri + "/api/v2.0/refreshToken",
    getDevicesAPI: uri + "/api/v1.2/deviceList/", // Done
    twoFactorAPI: uri + "/api/v1.0/verifyTwoFactor", // Done
    getStatsAPI: uri + "/api/v1.0/stats/",  // Done
    setTagAPI: uri + "/api/v1.1/tag/",
    getEventsAPI: uri + "/api/v1.2/events/",  // Getting for the Line Graph in SiteView  - graph needs styling
    getUsersAPI: uri + "/api/v1.2/deviceAllUsers/", // Getting in SiteView  - table needs update to table used in Dashboard with further styling for particualr cells
    suspendAPI: uri + "/api/v2.0/suspendDevice/",
    clearLogsAPI: uri + "/api/v1.2/clearLogs/",
    switchLatchAPI: uri + "/socketio/deviceState", // Done
    switchVirtualLatchAPI: uri + "/api/v2.0/virtual/deviceState", 
    switchLatchExternalLinkAPI: uri + "/socketio/virtualDeviceState", 
    getSingleDeviceAPI: uri + "/api/v2.0/portal/device/", // Done
    switchPulseAPI: uri + "/socketio/pulse", 
    switchVirtualPulseAPI: uri + "/socketio/virtualPulse", 
    removeUserAPI: uri + "/api/v1.2/removeUser/",  // Some implementation in SiteView, needs updated
    activateInstallerModeAPI: uri + "/api/v2.0/installerMode", 
    changeRoleAPI: uri + "/api/v2.0/changeRole", // Some implementation in SiteView, needs updated
    changeOwnerAPI: uri + "/api/v2.0/changeDeviceOwner", 
    deleteDeviceAPI: uri + "/api/v2.0/deleteDevice/",
    copyUsersAPI: uri + "/api/v2.0/duplicateRelationships",
    createPortalPasswordAPI: uri + "/api/v1.0/createPortalPassword",
    resetPortalPasswordAPI: uri + "/api/v2.0/resetPassword",
    changeAccountPasswordAPI: uri + "/api/v2.0/changePassword",
    createAppAccountAPI: uri + "/api/v1.0/acceptInvite",
    resendOTPAPI: uri + "/api/v2.0/resendOTP",
    getSensorDataAPI: uri + "/api/v2.0/deviceEventList/",
    getActivityLogAPI: uri + "/api/v2.0/portalLogins",
    changeCamectIntegrationAPI: uri + "/socketio/setCamectIntegrationType",
    getInactiveDevicesAPI: uri + "/api/v2.0/portal/inactiveDevices",
    rebootIPIOAPI: uri + "/socketio/restartIPIO/",
    copySettingsAPI: uri + "/api/v2.0/portal/copyDeviceSettings",
    inviteUserAPI: uri + "/api/v1.2/inviteUser",
    resetDeviceAPI: uri + "/api/v2.0/portal/factoryReset/",
    getCompanyUserListAPI: uri + "/api/v2.0/userList/",
    getUsersDevicesAPI: uri + "/api/v2.0/portal/devices/",
    enableUserNotificationsAPI: uri + "/api/v2.0/userNotifications",
}

*/

