import React from 'react'


import classes from "./Pill_Widget.module.css"

const Pill_Widget = ({ boolean, PillColor_IfTrue, PillColor_IfFalse, textColour_IfTrue, textColour_IfFalse, text_IfTrue, text_IfFalse, height }) => {
  console.log(height);
  console.log(boolean);
  return <div className={classes["Pill_Widget"]} style={{ backgroundColor: boolean ? PillColor_IfTrue : PillColor_IfFalse, color: boolean ? textColour_IfTrue : textColour_IfFalse, fontWeight: "600", height: height }} >{boolean ? text_IfTrue : text_IfFalse}</div>
}

export default Pill_Widget  