
import classes from "./linegraph.module.css";

import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Line } from "react-chartjs-2";

import monthData from "./monthData.json";
import monthLables from "./monthLables.json";
import { useEffect, useState } from "react";

defaults.maintainAspectRatio = false;
defaults.responsive = true;



// defaults.plugins.title.display = true;
// defaults.plugins.title.align = "center";
// defaults.plugins.title.font.size = 20;
// defaults.plugins.title.color = "black";
// defaults.plugins.title.margin = 0;
// defaults.plugins.title.padding = 5;


const LinegraphPeaked = ({ events, HandleDateClicked, setIsLoading, highestEvent, latestDataPoint, }) => {

  console.log(events);
  console.log(latestDataPoint);


  // console.log(events);
  // console.log(latestDataPoint);

  let reversed;
  // const minYAxis = (highestEvent < 50 ? -1 : -5) || (highestEvent < 10 ? -0.5 : -2) || (highestEvent < 5 ? -0.9 : -1);

  let minYAxis;

  if (highestEvent <= 5) {
    minYAxis = -0.3;
  } else if (highestEvent > 5 && highestEvent <= 10) {
    minYAxis = -0.5;
  } else if (highestEvent > 10 && highestEvent <= 50) {
    minYAxis = -1;
  } else if (highestEvent > 50 && highestEvent <= 100) {
    minYAxis = -6;
  } else {
    minYAxis = -3;
  }


  const [currentDataPoint, setCurrentDataPoint] = useState();
  console.log(currentDataPoint);
  useEffect(() => {
    setCurrentDataPoint(latestDataPoint);
  }, [latestDataPoint])




  console.log(currentDataPoint);
  console.log(parseInt(events[8].date));
  return (
    <div className={classes["lineGraph-container"]}>
      <Line
        data={{
          // labels: monthLables.map((data) => data.label) || [],  // X axis value
          labels: events.map((event) => event.date) || [],  // X axis value

          datasets: [
            {
              // stepped: true,
              // fill: true,
              label: "Events",
              data: (events || []).map((event) => event.events),
              backgroundColor: "rgb(26, 16, 55)",
              borderColor: "rgb(26, 16, 55)",
              borderWidth: 4, // Set the line thickness here
              pointBackgroundColor: 'rgba(0, 0, 0, 0)',
              pointBorderColor: 'rgba(0, 0, 0, 0)',

              pointHoverBackgroundColor: events.map((event) => parseInt(event.events) > 0 ? "rgb(26, 16, 55)" : "rgba(0, 0, 0, 0)"),
              pointHoverBorderColor: events.map((event) => parseInt(event.events) > 0 ? "rgb(26, 16, 55)" : "rgba(0, 0, 0, 0)"),
              pointBackgroundColor: events.map((event, index) => (parseInt(event.date) === currentDataPoint ? "rgb(26, 16, 55)" : "rgba(0, 0, 0, 0)")),
              pointBorderColor: events.map((event, index) => (parseInt(event.date) === currentDataPoint ? "red" : "rgba(0, 0, 0, 0)")),
              pointBorderWidth: events.map((event, index) => (parseInt(event.date) === currentDataPoint && 2)),

              hoverRadius: 14,
              pointRadius: 10,
            },
            //  {
            //   label: "Cost",
            //   data: monthData.map((data) => data.cost),
            //   backgroundColor: "#FF3030",
            //   borderColor: "#FF3030"
            //  },

          ]
        }}
        options={{
          onClick: (event, elements) => {
            if (!elements[0] || elements[0].index === undefined || elements[0].element["$context"].raw === 0) {
              console.log("here");
              return; // Return early if elements or index is undefined
            }


            console.log(elements[0].element["$context"].raw);
            console.log(elements[0].index);
            setCurrentDataPoint()

            let DataPointIndex = elements[0].index + 1;

            if (DataPointIndex != 1) {
              DataPointIndex = Math.ceil(DataPointIndex / 2)
            }
            if (DataPointIndex > 0) {
              setIsLoading(true);
              setTimeout(() => {
                setCurrentDataPoint(DataPointIndex);
                HandleDateClicked(DataPointIndex)
              }, 10);

            }
          },
          responsive: true,
          // interaction: {
          //   intersect: false,
          //   axis: 'x'
          // },
          elements: {
            line: {
              tension: 0.05,  // lower the tension the sharpen the line
            }
          },
          scales: {
            y: {
              min: minYAxis, // raise the line-graph's line up
              stepSize: 1,
              // max: 35,
              // max: 10,
              display: false, // hids the y axis lables
              grid: {
                display: false, // Hides y-axis gridlines
              },
            },
            x: {
              // 

              border: {
                color: 'rgb(151, 151, 151)',  // x - axis colour
                width: 2.5,  // x - axis thicknesss
              },
              grid: {
                display: false, // Hides x-axis gridlines

              },

              ticks: {
                autoSkip: true,
                // fixedStepSize: 10,
                beginAtZero: true,
                maxRotation: 0,
                minRotation: 0,
                align: "center",
                font: {
                  size: 15,  // Lable font size
                },
                // // Determines what lables to show.
                // callback: function (val, values, index) {
                //   console.log(val);
                //   console.log(values);
                //   console.log(index);
                //   const chartData = this.chart.data.labels; // Assuming data.labels is an array of objects
                //   console.log(chartData);
                //   const labelForValue = chartData[values]; // Replace 'date' with the actual property name
                //   console.log(labelForValue);


                //     console.log(this.getLabelForValue(labelForValue));

                //   // const allowedLabels = ["1st October 2023", "10th October 2023", "20th October 2023", "30th October 2023"];
                //   const allowedLabels = ["1 March 2024", "10 March 2024", "20 March 2024", "31 March 2024"];
                //   return allowedLabels;
                //   // return allowedLabels;
                // },
                color: 'grey',  // Lable color
              }
            }
          },

          plugins: {
            // Hides the title and legend from showing
            legend: {
              display: false,
              title: {
                display: false,
                text: 'Legend Title',
              }
            },
            tooltips: {
              backgroundColor: 'yellow', // Background color
              borderColor: '#000', // Border color
              borderWidth: 1, // Border width
              titleFont: {
                size: 16, // Title font size
                weight: 'bold', // Title font weight
              },
              bodyFont: {
                size: 14, // Body font size
              },
            },

            tooltip: {
              titleAlign: "left",
              bodyAlign: "left",

              borderColor: "white",
              borderWidth: 3,
              borderHeight: 3,
              usePointStyle: true,

              backgroundColor: "#101b37",
              padding: 12,

              // caret Arrow
              caretPadding: 25,
              caretSize: 0,

              //ColorBox (circle)
              boxWidth: 7,
              boxHeight: 7,
              boxPadding: 4,
              titleFont: {
                size: 16, // Title font size
                weight: 'bold', // Title font weight
              },
              bodyFont: {
                size: 15, // Body font size
              },
              filter: function (tooltipItem) {
                return events[tooltipItem.dataIndex].events !== 0;
              },
              callbacks: {

                label: function (context) {
                  return context.raw + " " + context.dataset.label
                },
                labelColor: function (context) {
                  return {
                    borderColor: 'red',
                    backgroundColor: 'red',
                  };
                },
                labelTextColor: function (context) {
                  return 'white';
                },

              }

            }
          }
        }
        }
      />
    </div>
  );
}

export default LinegraphPeaked;
