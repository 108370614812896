import UseAddDateSuffix from "../../hooks/useAddDateSuffix";

export const formatEvents = (MonthEvents, selectedMonthIndex) => {
    const arrayOfEvents = MonthEvents.data

    console.log(arrayOfEvents);

    const formatedMonthEvents = [];
    return formatedMonthEvents;
}

export const getLastSixMonths = (currentMonth) => {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    let lastSixMonthsArray = [];
    for (let i = 0; i < 6; i++) {
        // Handle wrap-around for months at the start of the year
        let monthIndex = (currentMonth - i + 12) % 12;
        lastSixMonthsArray.unshift(months[monthIndex]);
    }
    console.log(lastSixMonthsArray)
    return lastSixMonthsArray;
};

export const getLastDayOfMonth = (datesArray) => {
    let lastDate = datesArray[datesArray.length - 1].date;
    let day = parseInt(lastDate); // Extract day part
    return new Date(2024, 2 + 1, 0).getDate(); // March is represented by 2 (zero-based index), so add 1; 0 for last day of the previous month
};

export const fillMissingDates = (datesArray, selectedMonth) => {
    console.log(selectedMonth)
    console.log(datesArray)
    let result = [];
    let lastDayOfMonth = getLastDayOfMonth(datesArray);

    for (let day = 1; day <= lastDayOfMonth; day++) {
        let dateString = UseAddDateSuffix(day) + " " + selectedMonth + " 2024"; 
        console.log(dateString)
        let existingDate = datesArray.find(obj => obj.date === dateString);
        console.log(existingDate)
        if (existingDate) {
            result.push(existingDate); // Retain the existing date with its events
        } else {
            result.push({ date: dateString, events: 0 }); // Add missing dates with events initialized to 0
        }
    }

    return result;
};


export const AddGapObjects = (filledDates) => {
    // Adding the gap inbetween each true object to have the linegraph go back to the baseline before going to the next value
    for (let i = filledDates.length - 1; i >= 0; i--) {
        // Insert a new "gap" object after each object

        console.log(filledDates[12]);
        console.log(filledDates[13]);
        // if (filledDates[i] != filledDates[i - 1]) {
            filledDates.splice(i + 1, 0, { date: ``, events: 0 });
        // }




    }
    return filledDates
}

// export const RemoveYearFromLable = () => {
//     for (let i = 0; i < filledDates.length; i++) {
//         // Split the date string by spaces
//         const parts = filledDates[i].date.split(' ');

//         // Rejoin the first two parts (day and month) with a space
//         filledDates[i].date = parts.slice(0, 2).join(' ');
//     }
// }

