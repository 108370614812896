
import classes from "./Dashboard.module.css";

import { useLoaderData, useNavigate, json, defer, Await } from "react-router-dom";


import GridList from "../../components/GridList/GridList";
import SiteCardContentLayout from "../../components/UI/DataLayouts/SiteCardContentLayout";
// import GroupCardAddGroupLayout from "../../components/UI/DataLayouts/GroupCardContent/GroupCardAddGroupLayout";
import { useDispatch, useSelector } from "react-redux";
import { Suspense, useEffect, useState } from "react";
import FilterSearch from "../../components/PowerSearch/PowerSearch";
import Oval from "react-loading-icons/dist/esm/components/oval";
import { ApiEndpoints } from "../../store/type/API-Endpoints";
import GridList2 from "../../components/GridList/DevicesGrid";
import { getStats, PowerSearch } from "../../Api Methods/Api";
import StatsList from "../../components/UI/Stats/StatsList";
import DevicesGrid from "../../components/GridList/DevicesGrid";
import UsersGrid from "../../components/GridList/UsersGrid";
import GetAllDevices from "./GetAllDevices";
import DeviceLoadingGrid from "../../components/GridList/DeviceLoadingGrid";
import DeviceManager from "../../components/DeviceManager/DeviceManager";


const Dashboard = () => {
    const token = useSelector(state => state.auth.token);
    const client = useSelector(state => state.auth.client);
    const companyName = useSelector(state => state.auth.companyName);
    const getAllDevices = useSelector(state => state.auth.getAllDevices);


    return (
        <div className={classes.groupsPage}>
            <StatsList client={client} companyName={companyName} token={token} />
            <DeviceManager client={client} companyName={companyName} token={token} getAllDevices={getAllDevices} />
        </div>
    );
}

export default Dashboard;

export async function loader() {
    const token = sessionStorage.getItem('token');
    const client = sessionStorage.getItem('client');
    const companyName = sessionStorage.getItem('companyName');
    console.log(companyName);

    const apiData = {
        token,
        client
    }

    return defer({
        Stats: getStats(apiData, companyName),
    })
}
