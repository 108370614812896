import React, { useState } from 'react';

import classes from "./ToggleInput.module.css"

function ToggleInput(props) {
    return (

        <div className={classes.container}>
            <label>{props.label}</label>
            <label className={classes.switch}>

                <input
                    ref={props.checkboxInputRef}
                    id="toggleCheckbox"
                    type="checkbox"
                    checked={props.isChecked}
                    onChange={() => props.setIsChecked((prevCheckState) => prevCheckState == 1 ? 0 : 1)}
                />

                <span className={`${classes.slider} ${classes.round}`}></span>
            </label>
        </div>
    );
}

export default ToggleInput;
