import React from 'react'

import classes from './OutputPinCircle.module.css'

const OutputPinCircle = ({ OutputPins }) => {
    console.log(OutputPins);

    return (
        OutputPins.map((OutputPin, index) => {
            console.log(OutputPin.pinState);
            let OutputPinColor;
            if (OutputPin.pinState == 0) {
                console.log("here");
                OutputPinColor = "green"
            } else if (OutputPin.pinState == 1) {
                console.log("here");
                OutputPinColor = "red"
            } else if (OutputPin.pinState == 2) {
                console.log("here");
                OutputPinColor = "grey"
            }
            return (
                <li key={index}>
                    <div className={classes.output}
                        style={{ background: OutputPin.active ? OutputPinColor : "lightgrey" }}
                    />
                </li>
            )
        }
        ))
}

export default OutputPinCircle


/*
const OutputPinCircle = ({ outputs, OutputPins }) => {
// const { pinName, pinStatusOff, pinStatusOn, pinState, icon, DeviceUUID, Pin, active } ;     

    return (
        OutputPins.map((OutputPin, index) => (
            <li key={index}>
                <div className={classes.output}
                    style={{ background: OutputPin.active ? OutputPin.pinState ? "red" : "green" : "lightgrey" }}
                />
            </li>
        )
        ))
}

export default OutputPinCircle

*/