import { getRefreshToken } from "../Api";

const getRefreshTokenRequest = async (uri, headers) => {

    console.log(uri);
    console.log(headers);
    try {
        const response = await fetch(uri, {
            method: 'GET',
            headers: {
                Authorization: headers.refreshToken,
                Client: headers.client,
            },
        });

        if (!response.ok) {
            // console.log(response);
            throw new Error("Fetching failed: " + response.statusText);
        }

        const responseData = await response.json();

        // if response contains data
        if (responseData.token) {

            const token = responseData.token
            const refreshToken = responseData.refreshToken    
            return {token, refreshToken};
        }

        return responseData;
    } catch (error) {
        // console.error("Error in getRequest:", error);
        // throw error; // Re-throw the error to propagate it to the caller    
        getRefreshToken();
    }
};


export default getRefreshTokenRequest;
