import React, { useState, useEffect, useMemo } from 'react'
import { useFormAction } from 'react-router-dom';
import VirtualSourcesGrid from '../../GridList/VirtualSourcesGrid';
import UseParseDateMonthYearTime from '../../hooks/UseParseDateMonthYearTime';
import UseParseTime from '../../hooks/UseParseTime';
import UseParseYearMonthDate from '../../hooks/UseParseYearMonthDate';
import UseYearMonthDateShort from '../../hooks/UseYearMonthDateShort';

import classes from "./TimeDatePciker.module.css"


const addOneYear = (dateString) => {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Add one year to the Date object
    date.setUTCFullYear(date.getUTCFullYear() + 1);

    // Format the updated Date object back to 'YYYY-MM-DD'
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

const addOneDay = (dateString) => {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Add one year to the Date object
    // date.setUTCFullYear(date.getUTCFullYear() + 1);
    date.setUTCDate(date.getUTCDate() + 1);

    // Format the updated Date object back to 'YYYY-MM-DD'
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};

const getCurrentDate = (label) => {
    const newDate = new Date();
    console.log(newDate);
    let startDate = UseYearMonthDateShort(newDate);

    // console.log(label);
    // if (label === "End at:") {
    //     console.log(label);
    //     startDate = addOneDay(startDate);
    // }

    const maxDate = UseYearMonthDateShort(newDate.setUTCFullYear(newDate.getUTCFullYear() + 1));
    return { startDate, minDate: startDate, maxDate };
}

const TimeDatePicker = ({ handler, label, preSelectedTime }) => {

    // Initialize state with current time
    const [time, setTime] = useState();
    const [date, setDate] = useState();

    // Handler for time change
    const handleTimeChange = (event) => {
        const Time = event.target.value
        setTime(Time);

        if (Time != undefined || Time != null) {
            handler(Time, date)
        }
    };

    // Handler for time change
    const handleDateChange = (event) => {
        const Date = {
            startDate: event.target.value,
            minDate: date.minDate,
            maxDate: date.maxDate
        }

        setDate((Date))

        if (Date != undefined || Date != null) {
            handler(time, Date)
        }
    };

    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        if (preSelectedTime != undefined || preSelectedTime != null) {

            const Time = UseParseTime(preSelectedTime)

            const newDate = new Date();
            const minDate = UseYearMonthDateShort(newDate);
            const passedDate = UseYearMonthDateShort(preSelectedTime)
            const maxDate = addOneYear(passedDate);

            const dateObject = {
                startDate: passedDate,
                minDate,
                maxDate
            }
            setDate(dateObject)
            setTime(Time)

            console.log(dateObject);
            console.log(Time);

            if (initialLoad) {
                console.log("here");
                handler(Time, dateObject)
                setInitialLoad(false);
            }

        } else {
            const Time = getCurrentTime();
            const Date = getCurrentDate();

            console.log(Time);
            console.log(Date);

            setTime(Time);
            setDate(Date)
            if (initialLoad) {
                console.log("here");
                handler(Time, Date)
                setInitialLoad(false);
            }

        }
    }, []);

    return (
        <div className={classes["container"]}>

            <label htmlFor="appt">{label || "Select Date and Time on:"}</label>

            <input type="time" id="timePicker" name="holiday-timePicker" value={time} onChange={handleTimeChange} className={classes["time-input"]} />
            <label htmlFor="appt">{"on the"}</label>

            <input
                type="date"
                id="start"
                className={classes["time-input"]}
                name="holiday-start"
                value={date && date.startDate ? date.startDate : null}
                onChange={handleDateChange}
                min={date && date.minDate ? date.minDate : null}
                max={date && date.maxDate ? date.maxDate : null}
            />
        </div>
    )
}

export default TimeDatePicker