import React from 'react'
import { resendOTP } from '../Api';

const postRequestNoAuth = async (uri, data, headers) => {
    console.log("here");
    console.log(
        uri, 
        data, 
        headers
    );

    const response = await fetch(
        uri, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
        },
    });

    if (!response.ok) {
        console.error(response);
        throw new Error('Fetching failed.');
    }

    const responseData = await response.json();
    console.log(responseData);

    // if (responseData.message == 'sent') {
    //     return true;
    // }
    
    // check that the response had authorization, if not go to catch.
    // if (responseData.success == false) {
    //     console.error(responseData.data.success);
    //     // resendOTP();
    //     throw new Error("Auth failed ");
    // }
    return responseData;
};

export default postRequestNoAuth
