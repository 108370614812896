import React, { useRef, useState, useEffect } from 'react'

import classes from "./CreateHolidayMode.module.css"

import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../Modal/Modal';
import Button1 from '../../../Button/Button1';
import UseParseYearMonthDate from '../../../../hooks/UseParseYearMonthDate';
import UseYearMonthDateShort from '../../../../hooks/UseYearMonthDateShort';
import TimeDatePicker from '../../../TimeDatePicker/TimeDatePicker';

import holidayModeIcon from "../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/__Edit-93.svg"
import StatePicker from './StatePicker';
import UseParseDateMonthYearTime from '../../../../hooks/UseParseDateMonthYearTime';
import { createHolidayModeAPI } from '../../../../../Api Methods/Api';

const CreateHolidayMode = ({ OutPutsPinNames, setShowAutomation, smartLocks }) => {
    console.log("here");
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const DeviceUUID = useSelector(state => state.auth.DeviceUUID);


    const apiData = {
        token,
        client
    }
    //------------------------------- Modal Logic  ----------------------------------------------

    const ModalDialog = useRef();

    // call ref to clearStates method from child component ScheduledAction.
    const ClearStatesChildComponetRef = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();

        //reset states for scheduled Action
        if (ClearStatesChildComponetRef.current && ClearStatesChildComponetRef.current.HandleResetAllStates) {
            // Call the child method
            ClearStatesChildComponetRef.current.HandleResetAllStates();
        }

        //reset model screens
        HandelResetModal();
    }

    const [modalTitle, setModalTitle] = useState("Holiday Mode -")
    const [modalComponent, setModalComponent] = useState()
    //--------------------------------------------------------------------------------------------
    const [modalPageIndex, setModalPageIndex] = useState(0);

    // Initialize state with current time
    const [holidayModeDetails, setHolidayModeDetails] = useState(
        {
            "deviceUUID": DeviceUUID,
            "start": "",
            "end": "",
            "name": "",
            "active": 1,
            "states": { "5": "latch", "6": "latch", "13": "latch", "19": "latch" }
        }
    );

    console.log(holidayModeDetails);
    // Handler for time change
    const HandleStartOn = (time, date) => {

        console.log(time);
        console.log(date);
        const formattedDateTime = `${date.startDate} ${time}:00`;
        console.log(formattedDateTime);
        setHolidayModeDetails(prevState => ({
            ...prevState, // Spread the previous state to keep other properties unchanged
            start: formattedDateTime // Update only the start property
        }));
    };

    const HandleEndOn = (time, date) => {
        console.log(time);
        console.log(date);
        const formattedDateTime = `${date.startDate} ${time}:00`;
        console.log(formattedDateTime);
        setHolidayModeDetails(prevState => ({
            ...prevState,
            end: formattedDateTime
        }));
    };

    const HandleOutputState = (pin, state) => {
        const states = holidayModeDetails.states
        states[pin] = state;

        setHolidayModeDetails(prevState => ({
            ...prevState, // Spread the previous state to keep other properties unchanged
            "states": states // Update only the start property
        }));
    }
    const dispatch = useDispatch();

    const HandleCreateHolidayMode = async () => {
        console.log("here");

        if (holidayModeDetails.start != holidayModeDetails.end) {
            const response = await createHolidayModeAPI(apiData, holidayModeDetails, dispatch);
            if (response && response.success) {
                HandleCloseModal();
            }
        }
    }

    console.log(holidayModeDetails.states);


    // re-render modal to show new modal componet. 
    useEffect(() => {
        switch (modalPageIndex) {
            case 0:

                setModalComponent(
                    <div className={classes["timeDatePicker-Container"]}>

                        <img className={classes.icon} src={holidayModeIcon} />
                        <h3>Holiday mode </h3>
                        <TimeDatePicker handler={HandleStartOn} label={"Start at:"} />
                        <TimeDatePicker handler={HandleEndOn} label={"End at:"} />

                        <Button1 className={classes.nextButton} onClick={() => setModalPageIndex(1)}>Next</Button1>
                    </div>
                )

                break;
            case 1:

                // Filter for only active pins && pins with type of latch 
                const availablePins = OutPutsPinNames.filter(outPut => outPut.active == '1' && outPut.type == 'LATCH');

                //remove all outputs that have a smartLock assinged to it.
                let LatchPins = availablePins;
                if (smartLocks.length != 0) {
                    LatchPins = availablePins.filter(outPut =>
                        smartLocks.some(smartLock => outPut.Pin != smartLock.outputPin)
                    );
                }

                // set all pins that are of type latch to arm - since the statePicker will default the selected state to arm on modal page load
                if (LatchPins.length != 0) {
                    LatchPins.map((latchOutput) => {
                        HandleOutputState(latchOutput.Pin, "arm");
                    })
                }

                // get all pulse pins
                const pulsePins = OutPutsPinNames.filter(outPut => outPut.type == 'PULSE');

                // set the pins in holidayDetails.states object to "pulse", if the are of type pulse
                if (pulsePins.length != 0) {
                    pulsePins.map((pulseOutput) => {
                        HandleOutputState(pulseOutput.Pin, "pulse");
                    })
                }

                // get all pins that have smartLock assinged to it
                let smartLockPins = availablePins.filter(outPut =>
                    smartLocks.some(smartLock => outPut.Pin == smartLock.outputPin)
                );

                // for all pins that have a smart lock assinged to them, set the states of thoese pins to "lock" within holidaymode details.
                if (smartLockPins.length != 0) {
                    smartLockPins.map((smartLockOutput) => {
                        HandleOutputState(smartLockOutput.Pin, "lock");
                    })
                }

                setModalComponent(
                    <div className={classes["statePicker-Container"]}>

                        <img className={classes.icon} src={holidayModeIcon} />

                        <h3 style={{ fontWeight: "400", fontSize: "16px" }}> {UseParseDateMonthYearTime(holidayModeDetails.start)} - {UseParseDateMonthYearTime(holidayModeDetails.end)}</h3>

                        <h3>What should the switches do during holiday mode?</h3>

                        {LatchPins.map((outPut) => {
                            console.log(outPut);
                            return (
                                <StatePicker pinText={outPut.pinName} outputPin={outPut.Pin} HandleOutputState={HandleOutputState} states={holidayModeDetails.states[outPut.Pin]} />
                            )
                        })}

                        <div className={classes.buttonContainer}>
                            <Button1 className={classes.nextButton} onClick={() => setModalPageIndex(0)}>Back</Button1>
                            <Button1 className={classes.nextButton} onClick={HandleCreateHolidayMode}>Save</Button1>
                        </div>
                    </div>
                );
                break;
        }
        HandleShowModal()
    }, [modalPageIndex])


    // reset model when Escape key is pressed
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                // Handle the Escape key press here
                HandelResetModal();
                setModalPageIndex(null);
            }
        };

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);


        // Clean up event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const HandelResetModal = () => {
        setModalPageIndex(0);
        setShowAutomation(null);
    }


    return (

        <Modal ref={ModalDialog} title={modalTitle} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} style={{ minWidth: "700px", minHeight: "420.5px" }} modalLayoutStyle={{ alignItems: "center" }}>
            {modalComponent}
        </Modal>
    )
}

export default CreateHolidayMode