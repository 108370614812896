import React, { Children, useEffect, useState, useRef, useMemo } from 'react'

import classes from "./CametContentLayout.module.css";
import Modal from './CamectCameraModal.js';
import { useDispatch, useSelector } from 'react-redux';
import OTPIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Access.svg";
import RemoveCamectIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Delete - Remove.svg"
import GenerateLockOnTimePassCodeForm from '../../EditAutomations/GenerateLockOnTimePassCodeForm';
import { deleteCamectAPI, GenerateLockOTP, GenerateLockOTPAPI, getCamectCameras, getCamectsAPI, removeLockAPI, updateCamectRelationshipAPI } from '../../../../../../Api Methods/Api';

import LinkIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Camect/__Link.svg"
import EditIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Camect/EditView Black.svg"
import RefreshIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Camect/__Refresh.svg"
import Button2 from '../../../../Button/Button2';

import CamectModalForm from './CamectModalForm';
import Button1 from '../../../../Button/Button1';

const CametContentLayout = ({ camect, LinkedPins, setRefreshVirtualSources, HandleSelectCamectModalForm, Outputs, refreshCamects }) => {
    let { id: camectID, username, address, password } = camect;
    const PinName = LinkedPins[0] ? LinkedPins[0].pinName : "Not Linked";
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const DeviceUUID = useSelector(state => state.auth.DeviceUUID);
    const [loading, setLoading] = useState(false); // Load

    const apiData = {
        token,
        client
    }

    const HandleShowModal = () => {
        setShowModal(true);
    }

    const ShowSave = () => {
        setShowSave(true);
    }

    const HandleCloseModal = () => {
        setShowModal(false);
        setShowSave(false);
        HandelResetModal();
        setIsHightlighted(false)
        setSelectedCameras([])
        setNeedsHighlighted(true)
        setShowError(false)
    }

    const HandleSaveCameras = async () => {
        console.log("SAVED")

        let cameras = ""
        for (const camera of selectedCameras){
            if (!cameras.includes(camera)){
                cameras += camera + ";"
            }
        }
        //Add camectID and remove last semicolon
        let id = camectID + ":" + cameras.slice(0, -1);

        //Unlink pin
        if (selectedCameras.length == 0){
            id = ""
        }

        const camectDetails = {
            "uuid": DeviceUUID,
            "id": id,       //The ID of the camect (set to "" if removing)
            "pin": camectCameraPin
        }

        const responseData = await updateCamectRelationshipAPI(apiData, camectDetails, dispatch)
        if (responseData) {
            HandleCloseModal();
            refreshCamects();
        }
    }

    const [modalTitle, setModalTitle] = useState("SmartLock")
    const [modalComponent, setModalComponent] = useState(null);
    const [modalPageIndex, setModalPageIndex] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const [chosenPinName, setChosenPinName] = useState()

    const [showAddCamect, setShowAddCamect] = useState(false);
    const [camectCameras, setCamectCameras] = useState();
    const [camectCameraPin, setCamectCameraPin] = useState();
    const [selectedCameras, setSelectedCameras] = useState([]);
    const memoizedSelectedCameras = useMemo(() => selectedCameras, [selectedCameras]);
    const [linkedPinNames, setLinkedPinNames] = useState([])
    const [isHighlighted, setIsHightlighted] = useState(false)
    const [needsHighlighted, setNeedsHighlighted] = useState(true)
    const [currentPinIsOutput, setCurrentPinIsOutput] = useState(null)
    const [showError, setShowError] = useState(false)

    const outputs = ['5', '6', '13', '19']
    const inputs = ['21', '20', '16', '12', '4', '3', '2', '1']

    const handleCameraSelect = (camera) => {
        let canLink = true;
        let pinNumberArray = [];

        let linkedPins = camera.linkedPin ?? [];

        for (const linkedPin of linkedPins){
            pinNumberArray = [...pinNumberArray, getPinNumber(linkedPin)]
        }

        for (const pin of pinNumberArray){
            if ((currentPinIsOutput && outputs.includes(pin)) || (!currentPinIsOutput && inputs.includes(pin))){
                canLink = camectCameraPin === pin ? true : false
            }
        }

        if (canLink){
            console.log("HERE")
            const newArray = prevSelectedCameras => {
                //console.log(prevSelectedCameras)
                if (prevSelectedCameras.includes(camera.id)) {
                    linkedPins = linkedPins.filter(pin => pin !== chosenPinName)
                    camera.linkedPin = linkedPins
                    setShowError(false)
                    return prevSelectedCameras.filter(id => id !== camera.id);
                    
                } else {
                    linkedPins = [...linkedPins, chosenPinName]
                    camera.linkedPin = linkedPins
                    setShowError(false)
                    return [...prevSelectedCameras, camera.id];
                }
            }
            
            console.log(newArray)
            setSelectedCameras(newArray)
        } else {
            console.log("ERROR, cant link")
            setShowError(true)
        }
    };

    useEffect(() => {
        console.log("Selected Cameras Updated:", selectedCameras);
    }, [selectedCameras]);
    

    const preHighlightCameras = (cameraId) => {
        if (!isHighlighted){
            const newArray = prevSelectedCameras => {
                console.log("HERE")
                return [...prevSelectedCameras, cameraId];    
            }
            setSelectedCameras(newArray)
            setIsHightlighted(true)
        }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                HandelResetModal();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const HandelResetModal = () => {
        setShowAddCamect(null);
        setModalPageIndex(null);
        setSelectedCameras([]);
        setIsHightlighted(false)
        setLoading(false)
        setShowError(false)
    }

    const dispatch = useDispatch();
    const DeviceDetails = useSelector(state => state.service.DeviceDetails)

    const handleShowAddCamectForm = () => {
        HandleCloseModal();
        HandleSelectCamectModalForm(3, camect)
    }

    useEffect(() => {
        switch (modalPageIndex) {
            case 1:
                setModalTitle("Edit")
                setModalComponent(
                    <ul className={classes.EditList}>
                        <li>
                            <Button2 onClick={handleShowAddCamectForm}>Edit Camect</Button2>
                        </li>
                        <li>
                            <Button2 onClick={HandleDeleteCamect}>Delete Camect</Button2>
                        </li>
                    </ul>
                )
                HandleShowModal();
                break;
            case 2:
                setModalTitle("Link Switch/Input")
                setModalComponent(
                        <div>
                        {loading && (
                            <div className={classes.loadingOverlay}>
                            <div className={classes.loadingSpinner}></div></div>
                        )}
                        <ul className={classes.PinsList}>
                            {Outputs.map((output) => (
                                <li>
                                    <Button2 onClick={() => LinkCamect(output.Pin, output.pinName)}>
                                        {output.pinName}
                                    </Button2>
                                </li>
                            ))}
                        </ul>
                    </div>
                )
                HandleShowModal();
                break;
            case 3:
                setModalComponent(<>3</>)
                HandleShowModal();
                break;
            case 4:
                setModalTitle("Select Cameras");
                setLoading(false)
                setModalComponent(
                    <div>
                         {showError && <div className={classes.errorMessage}>You already have a link to a switch of the same type. Please unlink and try again</div>}
                    <ul className={classes.PinsList_new}>
                        {camectCameras.map((camera) => {
                            if (camera.linkedPin != "" && camera.linkedPin != null) {
                                
                                //Pre-highlights the already selected cameras
                                console.log(needsHighlighted)
                                if (needsHighlighted){
                                    setNeedsHighlighted(false)
                                    if (camera.linkedPin.includes(chosenPinName)){
                                        preHighlightCameras(camera.id)
                                    }
                                }   

                                let nameToDisplay = ""
                                const linkedPinNamesToShow = camera.linkedPin ?? []
                                for (const pinName of linkedPinNamesToShow){
                                    if (nameToDisplay != ""){
                                        nameToDisplay += `, ${pinName}`
                                    } else {
                                        nameToDisplay = `${pinName}`
                                    }
                                    
                                }

                                setLoading(false)

                                return (
                                    <li 
                                        key={camera.id} 
                                        className={`${classes.cameraItem} ${memoizedSelectedCameras.includes(camera.id) ? classes.selected : ''}`}
                                        onClick={() => handleCameraSelect(camera)}
                                    >
                                        
                                    <div className={classes.cameraName}>
                                        <span>{camera.name}</span>
                                        <div className={classes.pinNames_new}>
                                            {<div className={classes.upperRight}>{nameToDisplay}</div>}
                                        </div>
                                    </div>
                                </li>
                                );
                            } else {
                                return (
                                    <li 
                                    key={camera.id}
                                    className={`${classes.cameraItem} ${memoizedSelectedCameras.includes(camera.id) ? classes.selected : ''}`}
                                    onClick={() => handleCameraSelect(camera)}>

                                    <div className={classes.cameraName}>
                                        {camera.name}
                                    </div>
                                </li>
                                )
                            }
                        })}
                    </ul>
                    </div>
                );
                ShowSave();
                HandleShowModal();
                break;
            }

        }, [modalPageIndex, memoizedSelectedCameras, loading, showError])

    const HandleDeleteCamect = async () => {
        if (LinkedPins.length != 0) {
            setModalTitle("Important!");
            setModalComponent(
                <div style={{ display: "flex", flexDirection: "column", alignItems: "content", justifyContent: "center", width: "100%", gap: "20px" }}>
                    <div style={{ display: "flex", alignItems: "content", justifyContent: "center", width: "100%", gap: "3px" }}>
                        <h4 style={{ margin: "0px" }}>{"Please unlink this camect from all switches/inputs before deletion"}</h4>
                    </div>
                    <div style={{ display: "flex", alignItems: "content", justifyContent: "center", width: "100%", gap: "10px" }}>
                        <Button1 propsclassName={classes.CancelButton} onClick={HandleCloseModal}>Cancel</Button1>
                    </div>
                </div>
            )
        }
        else {
            const responseData = await deleteCamectAPI(apiData, DeviceUUID, camectID, dispatch);
            if (responseData.message == "sent") {
                HandleCloseModal();
                refreshCamects();
            }
        }
    }

    const LinkCamect = async (Pin, pinName) => {
        
        const pin = Pin.toString();
        const camectDetails = {
            "uuid": DeviceUUID,
            "id": camectID,       //The ID of the camect (set to "" if removing)
            "pin": pin
        }

        if (DeviceDetails.camectIntegrationType == 1) {
            const responseData = await updateCamectRelationshipAPI(apiData, camectDetails, dispatch);
            if (responseData) {
                HandleCloseModal();
                refreshCamects();
            }
        } else {
            setLoading(true)
            //get cameras
            console.log(pin)
            console.log(outputs.includes(pin))
            outputs.includes(pin) ? setCurrentPinIsOutput(true) : setCurrentPinIsOutput(false) //Check if we are in an output for camera restrictions
            setCamectCameraPin(pin); //Number 
            setChosenPinName(pinName); //Name

            const responseData = await getCamectCameras(apiData, DeviceUUID, camectID)
            console.log(responseData)
            if (responseData){
                const camectsAndRelationships = await getCamectRelationships()
                const relationships = camectsAndRelationships.relationships;

                Object.keys(relationships).forEach(key => {
                    const value = relationships[key];
                    if (value.includes(':')){
                        const relationshipSplit = value.split(':')
                        const linkedCams = relationshipSplit[1]
                        console.log(linkedCams)
                        //More than one pin on same camera
                        if (linkedCams.includes(';')){
                            const linkedCamsSplit = linkedCams.split(';')
                            for (const linkedCam of linkedCamsSplit){
                                for (const camera of responseData.cameras){
                                    
                                    if (linkedCam === camera.id){
                                        if (camera.linkedPin != null && camera.linkedPin != ""){
                                            const pinName = getPinName(key)
                                            camera.linkedPin = [...camera.linkedPin, pinName]
                                        } else {
                                            camera.linkedPin = [getPinName(key)]
                                        }
                                        
                                    } 
                                }
                            }
                        } else {
                            //more than one camera on same pin
                            console.log(linkedCams)
                            for (const camera of responseData.cameras){
                                if (linkedCams === camera.id){
                                    if (camera.linkedPin != null && camera.linkedPin != ""){
                                        const pinName = getPinName(key)
                                        camera.linkedPin = [...camera.linkedPin, pinName]
                                    } else {
                                        camera.linkedPin = [getPinName(key)]
                                    }
                                }
                            }
                        }
                    } else {
                        console.log("NO Cameras")
                    }
                })
                
                setCamectCameras(responseData.cameras)
                setModalPageIndex(4)
            } else {
                //Error no cams found
                
                setLoading(false)
            }
        }
    }

    const getPinName = (pinNumber) => {
        console.log(pinNumber)
        const pinNumberInt = parseInt(pinNumber, 10);
        const output = Outputs.find(output => output.Pin === pinNumberInt);
        return output ? output.pinName : null;
    };

    const getPinNumber = (pinName) => {
        console.log(pinName)
        const pin = Outputs.find(output => output.pinName === pinName);
        return pin ? String(pin.Pin) : null
    }

    const getCamectRelationships = async () => {
        const camectsAndRelationships = await getCamectsAPI(apiData, DeviceUUID, dispatch)
        return camectsAndRelationships
    }

    const removeLinkedPin = async (Pin) => {
        const pin = Pin.toString();
        const camectDetails = {
            "uuid": DeviceUUID,
            "id": '',       //The ID of the camect (set to "" if removing)
            "pin": pin
        }

        const responseData = await updateCamectRelationshipAPI(apiData, camectDetails, dispatch)
        if (responseData) {
            HandleCloseModal();
            refreshCamects();
        }
    }

    const HandleShowLinkedPins = () => {
        console.log("here");
        setModalTitle("Unlink Switches/Inputs")
        setModalComponent(
            <ul className={classes.PinsList}>
                {LinkedPins.map((LinkedPin) => {
                    console.log(LinkedPin);
                    return (
                        <li>
                            <Button2 onClick={() => removeLinkedPin(LinkedPin.Pin)}
                                img={RemoveCamectIcon}>
                                {LinkedPin.pinName}
                            </Button2>
                        </li>
                    )
                })}
            </ul>
        )
        HandleShowModal();
    }

    return (
        <>
            {/* Other content here */}
            <div>
                <Modal show={showModal} onClose={HandleCloseModal} title={modalTitle} save={showSave} onSave={HandleSaveCameras}>
                    {modalComponent}
                </Modal>
            </div>
            
            {/* <Modal ref={ModalDialog} title={modalTitle} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} style={{ minWidth: "600px", minHeight: "270px"}} modalLayoutStyle={{ alignItems: "center" }}>
                {modalComponent}
            </Modal> */}

            <div className={classes.CamectContentLayout}>
                <div className={classes["left-wrapper"]}>
                    <h3 style={{ margin: "0px" }}>{"Camect"}</h3>
                    <h3 className={classes.subtitle}>{address}</h3>
                </div>

                <div className={classes["right-wrapper"]}>

                    <div className={classes["text-container"]} onClick={HandleShowLinkedPins} style={{maxWidth: "400px"}}>
                        <h3 className={classes.pinName}>{PinName}</h3>
                        {LinkedPins.length > 1 && <h4 className={classes.subPinText}> + {LinkedPins.length - 1} more</h4>}
                    </div>

                    <div className={classes["Icons-Container"]}>
                        <img className={classes.Icon} src={EditIcon} onClick={() => setModalPageIndex(1)} />
                        <img className={classes.Icon} style={{ width: "30px", height: "30px", margin: "5px" }} src={LinkIcon} onClick={() => setModalPageIndex(2)} />
                        {/* <img className={classes.Icon} style={{ width: "30px", height: "30px", margin: "5px" }} src={RefreshIcon} onClick={() => setModalPageIndex(3)} /> */}
                        {/* <img className={classes.RemoveLockIcon} src={RemoveLockIcon} onClick={removeLock} /> */}
                    </div>
                </div>
            </div >
        </>
    )
}

export default CametContentLayout;




