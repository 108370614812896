import React, { useState } from 'react';
import classes from './CustomDropDown.module.css';
import image from '../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/__Drop Down menu.svg'


const CustomDropDownWithIcon = React.forwardRef(({ showDropDownOptions, placeholder, options, selectedOption, selectedIcon, handleOptionChange, handleBlur, handleDropDownOnClick, isValid, style, icons }, ref) => {


    return (
        <div className={`${classes.customDropdown} ${handleBlur ? "" : classes.invalid}`} ref={ref} >
            <div className={classes.selectedOption} onClick={handleDropDownOnClick}>
                {<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <img className={classes.img} src={selectedIcon}></img>
                    <h3>{selectedOption}</h3>
                </div> ||
                    <h3>{placeholder}</h3>}
            </div>
            {showDropDownOptions && (
                
                <ul className={classes.dropdownOptions} style={style}>
                    {options.map((option, index) => {
                        const normalizedOption = option.toLowerCase();
                        return (
                            <li key={index} onClick={() => handleOptionChange(option, icons[normalizedOption])}>
                                {icons[normalizedOption] && (
                                    <img className={classes.img} src={icons[normalizedOption]} alt={`${option} icon`} />
                                )}
                                {option}
                            </li>
                        );
                    })}
                </ul>
            )
            }
            <img
                className={classes.dropdownArrowImage}
                alt="arrow"
                src={image}
                id="rotateImage"
                onClick={handleDropDownOnClick}
            />
        </div >
    );
});


export default CustomDropDownWithIcon;
