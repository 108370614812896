import React, { useState, useEffect } from "react";
import classes from "./EditPinInput.module.css";
import SettingCogIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/__Admin - Settings.svg"


const EditPinInput = ({ InputPin, polarity, HandleShowEditPinForm, setPinDetails }) => {
    const { pinName, pinStatusOff, pinStatusOn, pinState, icon, active, notificationActive, type } = InputPin
    console.log(InputPin);

    const image = icon + " - Off";
    const otherStateImage = icon + " - On";
    const staticPath = "/assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/";

    const imageFromPath = `${process.env.PUBLIC_URL}${staticPath}${image}.svg`
    let otherStateImageFromPath = `${process.env.PUBLIC_URL}${staticPath}${otherStateImage}.svg`;

    const initialPinState = polarity === 1 ? !pinState : pinState;
    const [PinState, setPinState] = useState(initialPinState);

    useEffect(() => {
        setPinState(pinState);
    }, [pinState]);

    return (
        <div className={classes["layout"]}>
            <div className={classes["pin-output"]} onClick={() => {HandleShowEditPinForm(); setPinDetails({ InputPin }) }}>

                {/* <img className={classes.img} src={!active ? PinState ? imageFromPath : otherStateImageFromPath : InactiveIcon} /> */}

                <img className={classes.img} src={SettingCogIcon} />

                <div className={classes["text-wrapper"]}>
                    <h3 className={classes.pinName}>{pinName}</h3>
                    <h2 className={`${classes["pinLable"]} ${active ? classes["active"] : classes["Inactive"]}`}>
                        {active ? "Active" : "Inactive"}
                    </h2>
                </div>

            </div>
            <div className={active ? classes["pin-active"] : classes["pin-Inactive"]} />
        </div>
    )
}

export default EditPinInput;