import React, { Suspense, useEffect, useMemo, useState } from 'react'

import classes from "./Logs.module.css";

import TotalEvents from "../../UI/Cards/Logs Cards/TotalEvents/TotalEvents";
import Dropdown from '../../UI/Cards/Logs Cards/Dropdown/Dropdown';
import LinegraphPeaked from '../../UI/Graphs/linegraphPeaked';
import Export from '../../UI/Cards/Logs Cards/Export/Export';
import { Await, useLoaderData } from 'react-router-dom';
import useGetEventsMonth from '../../hooks/UseGetEventsMonth';
import LogsTableLayout from '../../Table/LogsTableLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceEventLogs } from '../../../Api Methods/Api';
import UseGetDateEvents from '../../hooks/UseGetDateEvents';
import UseAddDateSuffix from '../../hooks/useAddDateSuffix';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import ComponentCard from '../../UI/Cards/ComponentCard/ComponentCard';

import CustomDropDown from "../../CustomDropDown/CustomDropDownWithIcon";
import DropdownSelect from '../../CustomDropDown/Input/DropdownSelect';
import SearchbarSmall from '../../UI/Inputs/SearchbarSmall';
import Button1 from '../../UI/Button/Button1';
import UseGetEventsForMonth from '../../hooks/UseGetEventsForMonth';

import { fillMissingDates, formatEvents, getLastSixMonths, FormateEventArrayWithGapObjects, AddGapObjects } from './LogsFunctions';

const Logs = ({ SiteEventLogs, apiData }) => {

  const [EventsData, setEventsData] = useState(SiteEventLogs);
    const dispatch = useDispatch();
    
  useEffect(() => {
    const HandleDeviceEventsList = async () => {
      console.log("here");
      const data = await getDeviceEventLogs(apiData, DeviceUUID, dispatch);

      
      console.log(data)
      setEventsData(data);
    }

    // function appendLogs(events) {
    //   if (Array.isArray(events.data)) {
    //     events.data.forEach(log => {
    //         console.log(log);
    //         if (log.type === 'Lock') {
    //             switch (log.subType) {
    //                 case "OPEN_CLOSE_LOCKED":
    //                     log.eventData = log.eventData + " relocked";
    //                     break;
    //                 case "OTP_UNLOCK":
    //                     log.eventData = log.eventData + " unlocked with OTP";
    //                     break;
    //                 case "PIN_UNLOCK":
    //                     break;
    //                 case "MASTER_PIN_UNLOCK":
    //                     log.eventData = log.eventData + " unlocked with master PIN";
    //                     break;
    //                 case "UNOPENED_LOCKED":
    //                     log.eventData = log.eventData + " unlocked but not opened";
    //                     break;
    //                 case "INCORRECT_PIN":
    //                     log.eventData = "Incorrect PIN was attempted on " + log.eventData;
    //                     break;
    //                 case "Unlock":
    //                     log.eventData = log.eventData + " unlocked";
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         }
    //     });
    // } else {
    //     console.error("events.data is not an array");
    // }
    

    //   return events
    // }

    // Set up a timer to fetch data every 20 sec
    const timer1 = setInterval(() => {
      HandleDeviceEventsList();
    }, 60000);

    return () => clearInterval(timer1);
  }, []);

  // const [EventsData, setEventsData] = useState({
  //   data: [
  //     {
  //       "eventDate": "2024-06-14T11:39:32.000Z",
  //       "type": "output",
  //       "subType": "Armed",
  //       "user": "Adrian Gormley",
  //       "recipientName": "Unknown",
  //       "pin": 13,
  //       "eventData": "Output 3 Armed"
  //     },
  //     {
  //       "eventDate": "2024-06-14T11:39:32.000Z",
  //       "type": "output",
  //       "subType": "Armed",
  //       "user": "Adrian Gormley",
  //       "recipientName": "Unknown",
  //       "pin": 13,
  //       "eventData": "Output 3 Armed"
  //     },
  //     {
  //       "eventDate": "2024-06-14T11:39:32.000Z",
  //       "type": "output",
  //       "subType": "Armed",
  //       "user": "Adrian Gormley",
  //       "recipientName": "Unknown",
  //       "pin": 13,
  //       "eventData": "Output 3 Armed"
  //     },
  //     {
  //       "eventDate": "2024-06-15T11:39:32.000Z",
  //       "type": "output",
  //       "subType": "Armed",
  //       "user": "Adrian Gormley",
  //       "recipientName": "Unknown",
  //       "pin": 13,
  //       "eventData": "Output 3 Armed"
  //     },
  //     {
  //       "eventDate": "2024-06-15T11:39:32.000Z",
  //       "type": "output",
  //       "subType": "Armed",
  //       "user": "Adrian Gormley",
  //       "recipientName": "Unknown",
  //       "pin": 13,
  //       "eventData": "Output 3 Armed"
  //     },
  //     {
  //       "eventDate": "2024-06-15T11:39:32.000Z",
  //       "type": "output",
  //       "subType": "Armed",
  //       "user": "Adrian Gormley",
  //       "recipientName": "Unknown",
  //       "pin": 13,
  //       "eventData": "Output 3 Armed"
  //     }
  //   ]
  // });


  // const addEvent = () => {
  //   // Create a new event object
  //   const newEvent = {
  //     "eventDate": "2024-06-05T11:39:32.000Z",
  //     "type": "output",
  //     "subType": "Armed",
  //     "user": "Adrian Gormley",
  //     "recipientName": "Unknown",
  //     "pin": 13,
  //     "eventData": "Output 3 Armed"
  //   };

  // Update the state by adding the new event to the data array
  //   setEventsData(prevState => ({
  //     ...prevState,
  //     data: [...prevState.data, newEvent]
  //   }));
  // };

  // const removeEvent = () => {
  //   setEventsData(prevState => {
  //     // Remove the last event from the data array
  //     const newData = prevState.data.slice(0, -1);
  //     return {
  //       ...prevState,
  //       data: newData
  //     };
  //   });
  // }



  console.count("Logs");

  const [allEvents, setAllEvents] = useState(EventsData)

  useEffect(() => {
    setAllEvents(EventsData);
  }, [EventsData])

  // get current date (in ISO 8601 format)
  const currentDate = new Date();
  console.log(currentDate)
  // get current month (int)
  const currentMonth = currentDate.getMonth();
  console.log(currentMonth)


  //---------------------------------------------------------------
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  //creates array of last six months
  const lastSixMonths = useMemo(() => {
    console.count("re-render: getLastSixMonths");
    return getLastSixMonths(currentMonth);
  }, []);


  const [selectedMonthIndex, setSelectedMonthIndex] = useState(currentMonth);
  const [selectedMonth, setSelectedMonth] = useState(months[currentMonth]);



  //---------------------------------------------------------------

  // will be the new useGetEventsMonth Function once logic is moved over.
  const ddata = formatEvents(EventsData, selectedMonthIndex);


  console.log(allEvents)
  console.log(selectedMonthIndex)
  let MonthEvents = useGetEventsMonth(allEvents, selectedMonthIndex);

  console.log(MonthEvents);

  //incase the month has no events then add in one object.
  //---------------------------------------------------------------
  if (MonthEvents.length === 0) {
    MonthEvents = [
      {
        date: "1th " + selectedMonth,
        events: 1
      }
    ]
  }
  //---------------------------------------------------------------

  // Call the function to fill missing dates
  const filledDates = useMemo(() => {
    console.count("re-render: fillMissingDates + Adding Gap Objects");
    console.log(selectedMonth)
    const tempfilledDates = fillMissingDates(MonthEvents, selectedMonth);

    return AddGapObjects(tempfilledDates);
  }, [MonthEvents, selectedMonth]);

  console.log(filledDates);

  //-------- Remove the year from each lable  --------------------------------------------------------------
  // Loop through the array
  for (let i = 0; i < filledDates.length; i++) {
    // Split the date string by spaces
    const parts = filledDates[i].date.split(' ');

    // Rejoin the first two parts (day and month) with a space
    filledDates[i].date = parts.slice(0, 2).join(' ');
  }

  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  // Function to calculate total events for all dates
  function calculateTotalEvents(datesArray) {
    console.log(datesArray)
    let totalEvents = 0;
    for (let dateObj of datesArray) {
      console.log(dateObj)
      totalEvents += dateObj.events;
    }
    console.log(totalEvents)
    return totalEvents;
  }

  // Function to find the object with the highest count (events)
  function findHighestEventCount(datesArray) {
    let highestEventObject = null;
    let highestEventCount = -1;

    for (let dateObj of datesArray) {
      if (dateObj.events > highestEventCount) {
        highestEventObject = dateObj;
        highestEventCount = dateObj.events;
      }
    }

    return highestEventObject.events;
  }

  const highestEvent = findHighestEventCount(filledDates)

  // Call the function to calculate total events
  let totalEvents = calculateTotalEvents(filledDates);


  const token = sessionStorage.getItem('token');
  const client = sessionStorage.getItem('client');
  const DeviceUUID = sessionStorage.getItem('DeviceUUID');
  sessionStorage.setItem('DeviceUUID', DeviceUUID);


  const [latestDataPoint, setLatestDataPoint] = useState();
  const [prevLatestDataPoint, setPrevLatestDataPoint] = useState();
  const [currentDataPoint, setcurrentDataPoint] = useState();
  const [prevSelectedMonth, setPrevSelectedMonth] = useState()

  const [prevDataPoint, setPrevDataPoint] = useState();
  const [prevSelectedMonthIndex, setPrevSelectedMonthIndex] = useState()
  const [monthEventsfiltering, setMonthEventsFiltering] = useState('');

  const [tableData, setTableData] = useState();
  const [isloading, setIsLoading] = useState(true);

  // Callback function to handle Month change
  const handleSelectChange = (Month, MonthIndex) => {
    console.log(MonthIndex);
    setPrevSelectedMonthIndex(selectedMonthIndex);
    setSelectedMonthIndex(MonthIndex +1);
    setSelectedMonth(Month);
    setLatestDataPoint(latestDataPoint);
    setcurrentDataPoint(latestDataPoint);
  };


  // Finds and shows the most recent date of the month's event in the event table
  //------------------------------------------------------------------
  useEffect(() => {
    if (latestDataPoint != undefined) {
      setPrevLatestDataPoint(latestDataPoint);
    }
    if (prevLatestDataPoint != latestDataPoint) {
      setcurrentDataPoint(parseInt(MonthEvents[0].date))
    } else {
      setLatestDataPoint(parseInt(MonthEvents[0].date));
    }
  }, [selectedMonthIndex, filledDates]);
  //------------------------------------------------------------------

  useEffect(() => {
    // when the user switches months, update the table || for checking if user is viewing the most recent data point - if so then update table with new events
    if ((currentDataPoint == latestDataPoint) && (monthEventsfiltering == "")) {
      let lastIndex = -1; // Initialize with -1 to indicate no element found
      for (let i = 0; i < filledDates.length; i++) {
        if (filledDates[i].events > 0 && filledDates[i].date !== '') {
          lastIndex = i; // Update lastIndex when condition met
        }
      }

      HandleDateClicked((lastIndex / 2) + 1);
    } else if ((currentDataPoint != latestDataPoint) && (monthEventsfiltering == "")) {
      HandleDateClicked(currentDataPoint);
    }
  }, [selectedMonthIndex, EventsData, currentDataPoint, latestDataPoint, prevSelectedMonth, monthEventsfiltering])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      //   Perform fetch call with the input value
      if (monthEventsfiltering != "") {
        setTableData(UseGetEventsForMonth(EventsData, selectedMonthIndex));
      } else {
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [monthEventsfiltering, selectedMonthIndex])

  const HandleDateClicked = async (DataPointIndex) => {

    const DateEvents = UseGetDateEvents(EventsData, DataPointIndex, selectedMonthIndex);
    setTableData(DateEvents);
    setPrevDataPoint(currentDataPoint);
    setcurrentDataPoint(DataPointIndex);
    setIsLoading(false);
  }


  return (
    <ComponentCard className={classes["table-container"]}>
      <div className={classes["logs-container"]} >
        <div className={classes.top} >
          <TotalEvents totalEvents={totalEvents} />
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <DropdownSelect
              options={lastSixMonths}
              selectedOption={selectedMonthIndex}
              onSelect={handleSelectChange}
              placeholder={"Select Month"}
            />

            <SearchbarSmall
              type="text"
              placeholder={"Search events for " + months[selectedMonthIndex] + "..."}
              value={monthEventsfiltering}
              onChange={(e) => setMonthEventsFiltering(e.target.value)}
              onClear={() => setMonthEventsFiltering("")}
            />
          </div>
        </div>
        <LinegraphPeaked events={filledDates} HandleDateClicked={HandleDateClicked} setIsLoading={setIsLoading} highestEvent={highestEvent} latestDataPoint={latestDataPoint} />
        {/* <Button1 onClick={addEvent}>Add Event</Button1>
         <Button1 onClick={removeEvent}>Remove Event</Button1>  */}
      </div>

      < Suspense fallback={
        < div className={classes["loadingSpinner-container"]} >
          <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
        </div >
      }>
        <Await resolve={tableData}>
          {(loadedTableData) =>
            <LogsTableLayout data={loadedTableData} isloading={isloading} Filtering={monthEventsfiltering} />
          }
        </Await>
      </Suspense >
    </ComponentCard>
  )
}

export default Logs;
